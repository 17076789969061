import { Amplify } from 'aws-amplify';

export function clearSessionAuth() {
  // clear cookies
  const cookies = document.cookie.split('; ');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie =
      name +
      '=;' +
      'expires=Thu, 01-Jan-1970 00:00:01 GMT;' +
      'path=' +
      '/;' +
      'domain=' +
      window.location.host +
      ';' +
      'secure=;';
  }

  //clearing local storage and cache
  localStorage.clear();
  window.localStorage.clear();
  Amplify.Cache.clear();
}
