import { useEffect } from 'react';
import * as KatalMetrics from '@amzn/katal-metrics';

import { i18nWhenReady } from '../i18n';
import { useMetricsAndLoggerContext } from '../logging/MetricsProvider';

enum InitializationStatus {
  Failure = 0,
  Success = 1,
}

interface PropsType {
  children: JSX.Element;
}

/**
 * Simple class to manage the application initialization metric.
 *
 * Note this is meant as a demonstration of how to use the initialization metric, and
 * is almost certainly inadequate for a more complex application.
 *
 * It waits for itself to be mounted (if it is the outermost component that means all of
 * its children are rendered and mounted by React, though are not necessarily fully rendered
 * in the browser), then waits for the translations to be ready.
 *
 * In your actual application, you are likely not fully initialized until you have completed
 * your initial REST calls.  You will need to either integrate that logic here, or else move
 * the initialization metric elsewhere.
 *
 * For applications using a state manager (e.g. Redux) it may make more sense to determine if
 * your application is initialized from that layer instead.
 */
function AppInitWrapper(props: PropsType) {
  const { metricsPublisher } = useMetricsAndLoggerContext();

  useEffect(() => {
    // Our application is initialized, with the possible exception of i18n
    // Use this callback to log our final initialization time and status
    i18nWhenReady((err) => {
      const status = err ? InitializationStatus.Failure : InitializationStatus.Success;
      const initializationMetric = new KatalMetrics.Metric.Initialization().withMonitor();
      initializationMetric.setFailure(status !== InitializationStatus.Success);
      metricsPublisher.getInitializationMetricsPublisher().publish(initializationMetric);
    });
  }, []);

  return props.children;
}

export default AppInitWrapper;
