import getTPSPortalUrl from './redirectionUtils';
import { RoleType } from '../data/UserMetadata';
import { SnowUrlType } from '../constants/urlConstants';
import { LinkType } from '../constants/linkConstants';
import { ImpersonationIntentType } from '../context/UserProvider';

export function getProfileItemUrl({
  tpsRecordSystemId,
  link,
  userRole,
  impersonationIntent,
}: {
  tpsRecordSystemId?: string;
  link: LinkType;
  userRole: RoleType;
  impersonationIntent: ImpersonationIntentType;
}): string {
  switch (link.label) {
    case 'manage_team':
      return getTPSPortalUrl({ linkType: SnowUrlType.VENDOR_TEAM_MANAGEMENT, userRole, impersonationIntent });
    case 'my_profile':
      return getTPSPortalUrl({
        id: tpsRecordSystemId,
        linkType: SnowUrlType.MY_PROFILE,
        userRole,
        impersonationIntent,
      });
    default:
      return link.href;
  }
}
