import React, { useEffect, useState, useReducer, ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import MuiArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MuiBadge from '@mui/material/Badge';
import MuiBox from '@mui/material/Box';
import MuiLink from '@mui/material/Link';
import MuiList from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import MuiListItemButton from '@mui/material/ListItemButton';
import MuiStarBorderIcon from '@mui/icons-material/StarBorder';
import MuiTypography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import { useDataContext } from '../../context/DataProvider';
import {
  issueDataIsLoaded,
  isIssueActionable,
  surveyDataIsLoaded,
  isSurveyActionable,
  consultDataIsLoaded,
  isConsultActionable,
  assessmentDataIsLoaded,
  taskDataIsLoaded,
  isTaskActionable,
} from '../../utils/dataUtils';
import { ImpersonationIntentType, useImpersonation, useUserContext } from '../../context/UserProvider';
import { useGetFavoritesByUserQuery } from '../../apis/FavoritesAPI';
import ErrorRetry from '../LoadingComponents/ErrorRetry';
import FavoritesStar from '../FavoritesStar/FavoritesStar';
import LoadingSpinner from '../LoadingComponents/LoadingSpinner';
import Assessment from '../../data/RecordMetadata';
import { RoleType } from '../../data/UserMetadata';
import { CatalogSvg } from './CatalogSvg';
import { ISSUES_PAGE, CONSULTS_PAGE, TASKS_PAGE, SURVEYS_PAGE } from '../../constants/linkConstants';
import { SIDEBAR_WIDTH_PX, BREAKPOINT, SIDEBAR_WIDTH_PX_SM } from '../../constants/constants';
import { TPS_CATALOG_QUERY_PARAMETER, SnowUrlType } from '../../constants/urlConstants';
import getTPSPortalUrl from '../../utils/redirectionUtils';
import { sidebarReducer, SideBarItem, getSidebarInitValues } from './SideBarReducer';

/* --------------- CSS-in-JS --------------- */
const SIDEBAR_FONT_SIZE_PX = '20px';
const SIDEBAR_FONT_SIZE_PX_SM = '16px';

const SidebarContainerStyled = styled(MuiBox)(({ theme }) => ({
  position: 'fixed',
  width: SIDEBAR_WIDTH_PX,
  height: '100vh',
  backgroundColor: theme.palette.primary.primary,
  zIndex: '1000',
  color: theme.palette.primary.light,
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    width: SIDEBAR_WIDTH_PX_SM,
  },
}));

const SidebarList = styled(MuiList)(({ theme }) => ({
  paddingTop: '35px',
  paddingLeft: '20px',
  height: '100%',
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    paddingLeft: '10px',
  },
}));

const MenuItemStyled = styled(MuiListItem)(({ theme }) => ({
  padding: '3px 0',
  color: theme.palette.primary.light,
}));

const MenuButton = styled(MuiListItemButton)(({ theme }) => ({
  color: theme.palette.primary.light,
  '&:hover': {
    color: theme.palette.primary.contrastText,
  },
}));

const MenuLink = styled(MuiLink)(({ theme }) => ({
  color: theme.palette.primary.light,
  textDecoration: 'none',
  '&:hover': {
    color: theme.palette.primary.contrastText,
    fill: theme.palette.primary.contrastText,
  },
}));

const MenuItemText = styled(MuiTypography)(({ theme }) => ({
  fontSize: SIDEBAR_FONT_SIZE_PX,
  '&:hover': {
    color: theme.palette.primary.contrastText,
  },
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: SIDEBAR_FONT_SIZE_PX_SM,
  },
}));

const MenuItemButtonStyled = styled(MenuButton)(({ theme }) => ({
  borderRadius: '10px',
  fontSize: SIDEBAR_FONT_SIZE_PX,
  transition: 'none',
  height: '55px',
  marginRight: '65px',
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    marginRight: '65px',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      marginRight: '65px',
    },
    '.MuiListItemText-primary': {
      color: theme.palette.primary.contrastText,
      borderRadius: '10px',
    },
  },
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: SIDEBAR_FONT_SIZE_PX_SM,
    '&.Mui-selected': {
      marginRight: '38px',
      '&:hover': {
        marginRight: '38px',
      },
    },
  },
}));

const FavoritesMenuButton = styled(MenuButton)({
  display: 'flex',
  alignSelf: 'flex-start',
  alignItems: 'center',
  justifyContent: 'flex-start',
  paddingTop: '65px',
});

const FavoritesContainer = styled(MuiList)(({ theme }) => ({
  display: 'block',
  padding: 0,
  margin: 0,
  maxHeight: '200px',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    width: '5px',
    height: '5px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.primary.primary,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.light,
  },
}));

const FavoritesLineItem = styled(MuiListItem)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: 3,
});

const VendorIdLink = styled(MenuLink)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '3px 0',
  paddingLeft: '10px',
});

const FavoritesText = styled(MenuItemText)({
  paddingLeft: '5px',
});

interface DropdownProps {
  open: boolean;
}
const DropdownArrow = styled(MuiArrowDropDownIcon)((props: DropdownProps) => ({
  transform: props.open ? 'rotate(-180deg)' : 'rotate(0)',
  transition: '0.1s',
  fontSize: '2rem',
}));

const CatalogLink = styled(MenuLink)(({ theme }) => ({
  fontSize: SIDEBAR_FONT_SIZE_PX,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  paddingTop: '15px',
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: SIDEBAR_FONT_SIZE_PX_SM,
  },
}));

const CatalogText = styled(MenuItemText)({
  paddingLeft: '5px',
});

/* --------------- Helper Components --------------- */

/**
 * To avoid needing to add the disable padding for each
 * menu item.
 *
 * @param children
 * @returns ReactElement
 */
interface MenuItemProps {
  children: React.ReactNode;
}
const MenuItem: React.FC<MenuItemProps> = ({ children }) => {
  return <MenuItemStyled disablePadding>{children}</MenuItemStyled>;
};

/**
 * Simple functional component that encapsulates the MUI
 * List Item and Mui List Item Button.
 *
 * @param menuItem - The item being displayed.
 * @returns ReactElement
 */
interface NavMenuItemProps {
  menuItem: SideBarItem;
  children: React.ReactNode;
}
const NavMenuItem: React.FC<NavMenuItemProps> = ({ children, menuItem }) => {
  const theme = useTheme();

  return (
    <MenuItem>
      <MenuItemButtonStyled {...{ component: Link, to: menuItem.href }} selected={menuItem.isFocus} disableTouchRipple>
        <MenuItemText>{children}</MenuItemText>
      </MenuItemButtonStyled>
      <MuiBadge
        color="primary"
        sx={{
          '& .MuiBadge-colorPrimary': {
            backgroundColor: theme.palette.error.main,
            marginRight: '40px',
            [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
              marginRight: '20px',
            },
          },
        }}
        badgeContent={menuItem.numActionableItems}
      />
    </MenuItem>
  );
};

/**
 * This functional component returns a favorited item that
 * is populated on the sidebar dropdown menu under the
 * favorites section.
 *
 * @param assessment - The favorited assessment to be shown
 * @param role - User's role to create the link properly.
 * @returns
 */
interface FavoriteItemProps {
  assessment: Assessment;
  role: RoleType;
  impersonationIntent: ImpersonationIntentType;
}

function FavoriteItem({ assessment, role, impersonationIntent }: FavoriteItemProps): ReactElement {
  return (
    <FavoritesLineItem>
      <FavoritesStar assessment={assessment} />
      <VendorIdLink
        {...{
          component: Link,
          to: getTPSPortalUrl({
            id: assessment.tpsRecordSystemId,
            linkType: SnowUrlType.ASSESSMENT,
            userRole: role,
            impersonationIntent,
          }),
        }}
      >
        <MenuItemText>{assessment.vendor}</MenuItemText>
        <MenuItemText>{assessment.tpsRecordId}</MenuItemText>
      </VendorIdLink>
    </FavoritesLineItem>
  );
}

/* --------------- Main --------------- */

function SideBar({ bundle }: WithBundleProps) {
  const user = useUserContext();
  const { impersonationIntent } = useImpersonation();
  const [dataState] = useDataContext();
  const location = useLocation();
  const [sidebar, dispatch] = useReducer(sidebarReducer, getSidebarInitValues(location.pathname, user.role));
  const favQuery = useGetFavoritesByUserQuery();
  const [showFavorites, setShowFavorites] = useState(true);
  const favorites = dataState.assessments.filter((assessment) => assessment.isFavorited);

  const changeFocus = () => dispatch({ type: 'changeFocus', payload: { href: location.pathname } });
  const updateActionableNumber = (label: string, count: number) => {
    dispatch({
      type: 'updateActionableItems',
      payload: {
        label: label,
        data: count,
      },
    });
  };

  useEffect(changeFocus, [location.pathname]);

  useEffect(() => {
    if (!issueDataIsLoaded(dataState) && !assessmentDataIsLoaded(dataState)) return;

    let issueCount = 0;
    dataState.assessments.forEach((assessment) => {
      assessment.issues!.forEach((issue) => isIssueActionable(issue, user) && issueCount++);
    });
    updateActionableNumber(ISSUES_PAGE.label, issueCount);
  }, [dataState.loadStore.issues, dataState.loadStore.assessments]);

  useEffect(() => {
    if (!taskDataIsLoaded(dataState) && !assessmentDataIsLoaded(dataState)) return;

    let taskCount = 0;
    dataState.assessments.forEach((assessment) => {
      assessment.tasks!.forEach((task) => isTaskActionable(task, user) && taskCount++);
    });
    updateActionableNumber(TASKS_PAGE.label, taskCount);
  }, [dataState.loadStore.tasks, dataState.loadStore.assessments]);

  useEffect(() => {
    if (!surveyDataIsLoaded(dataState)) return;

    updateActionableNumber(SURVEYS_PAGE.label, dataState.surveys.filter((survey) => isSurveyActionable(survey)).length);
  }, [dataState.loadStore.surveys]);

  useEffect(() => {
    if (!consultDataIsLoaded(dataState)) return;
    updateActionableNumber(
      CONSULTS_PAGE.label,
      dataState.consults.filter((consult) => isConsultActionable(consult, user)).length,
    );
  }, [dataState.loadStore.consults]);

  useEffect(() => {
    dispatch({
      type: 'updateRole',
      payload: {
        href: location.pathname,
        role: user.role,
      },
    });
  }, [user.role]);

  return (
    <SidebarContainerStyled>
      <SidebarList>
        {/* Nav items */}
        {sidebar.map((item) => {
          return (
            <NavMenuItem key={item.label} menuItem={item}>
              {bundle.getMessage(item.label)}
            </NavMenuItem>
          );
        })}

        {/* Favorites Button */}
        <FavoritesMenuButton
          onClick={() => setShowFavorites(!showFavorites)}
          sx={{ marginLeft: '-20px' }}
          disableTouchRipple
        >
          <MuiStarBorderIcon sx={{ color: 'inherit' }} />
          <FavoritesText>{bundle.getMessage('favorites')}</FavoritesText>
          <DropdownArrow open={showFavorites} />
        </FavoritesMenuButton>

        {/* Favorites List */}
        <FavoritesContainer>
          {showFavorites && (
            <React.Fragment>
              {favQuery.isError && <ErrorRetry query={favQuery} />}
              {favQuery.isLoading && <LoadingSpinner />}
              {favQuery.isSuccess &&
                favorites.map((fav) => (
                  <FavoriteItem
                    key={fav.tpsRecordId}
                    assessment={fav}
                    role={user.role}
                    impersonationIntent={impersonationIntent}
                  />
                ))}
            </React.Fragment>
          )}
        </FavoritesContainer>

        {/* Catalog Link */}
        {user.role !== RoleType.VENDOR && (
          <CatalogLink
            {...{
              component: Link,
              to: getTPSPortalUrl({
                id: TPS_CATALOG_QUERY_PARAMETER,
                linkType: SnowUrlType.TPS_CATALOG,
                userRole: user.role,
                impersonationIntent,
              }),
            }}
          >
            <CatalogSvg />
            <CatalogText>{bundle.getMessage('tps_catalog')}</CatalogText>
          </CatalogLink>
        )}
      </SidebarList>
    </SidebarContainerStyled>
  );
}

export default withBundle('components.SideBar')(SideBar);
