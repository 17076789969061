import { MessageBundle } from '@amzn/arb-tools';
import Assessment, { SwimlaneType, Questionnaire, State, Tier } from '../data/RecordMetadata';
import { outcomeStrToEnum, stateStrToEnum, tierStrToNum, tierToEnum } from '../utils/enumUtils';
import { FilterLabel } from '../components/FilterBar/FilterReducer';
import {
  areIssueFiltersApplied,
  areNonIssueTaskSearchFiltersApplied,
  getVisibleIssueTpsRecordIds,
  isIncludedInFilter,
  isSearchApplied,
  isSearchResult,
  isTptaSearchApplied,
} from './filterUtils';
import { questionnaireType } from '../utils/enumUtils';
import { RoleType } from '../data/UserMetadata';
import { isClosedCompleteNa } from './dataUtils';

function filterBarFilter(
  bundle: MessageBundle,
  filtersLoading: boolean,
  labelToFilters?: Map<FilterLabel, Array<string>>,
  swimlaneType?: SwimlaneType,
  userRole?: RoleType,
) {
  if (filtersLoading) return (assessment: Assessment) => true;
  return (assessment: Assessment) => {
    let visibleIssueTpsRecordIds: Set<string>;
    const questionnaireFieldsSearch: string[] = [];

    if (swimlaneType === SwimlaneType.INTAKE) {
      const startDateMessage = assessment.startDate
        ? bundle.formatMessage('intake_search_text', {
            expirationDate: new Date(0).setUTCSeconds(assessment.startDate + 604800),
          })
        : bundle.getMessage('missing_expiration_date');
      questionnaireFieldsSearch.push(startDateMessage);
    } else {
      assessment.questionnaires &&
        assessment.questionnaires.forEach((questionnaire: Questionnaire) => {
          const stateEnum: State = stateStrToEnum(questionnaire.state);
          if (stateEnum === State.COMPLETE || stateEnum === State.CANCELLED) {
            questionnaireFieldsSearch.push(
              bundle.formatMessage('ddq_type_state', {
                type: questionnaireType(questionnaire.type),
                state: questionnaire.state,
                ddqType: questionnaire.type,
              }),
            );
          } else {
            questionnaireFieldsSearch.push(
              bundle.formatMessage('ddq_type', {
                type: questionnaireType(questionnaire.type),
                percent: questionnaire.progressPercentage,
                state: bundle.formatMessage('record_state', {
                  state: questionnaire.state,
                }),
                ddqType: questionnaire.type,
              }),
            );
          }
        });
    }

    let tempProjectName = assessment.name;

    userRole === RoleType.VENDOR && (tempProjectName = ''); //Vendor's will not have any project name on TPTA so replacing with empty string

    const filterQuery = {
      vendor: assessment.vendor,
      tier: bundle.formatMessage('tier_number', { tier: tierStrToNum(assessment.tier) }),
      state: bundle.formatMessage('record_state', { state: stateStrToEnum(assessment.state) }),
      search: [
        assessment.tpsRecordId,
        tempProjectName,
        bundle.formatMessage('outcome_status', { outcome: outcomeStrToEnum(assessment.assessmentOutcome) }),
        ...questionnaireFieldsSearch,
      ],
    };

    if (labelToFilters!.size > 0) {
      if (areNonIssueTaskSearchFiltersApplied(labelToFilters) && !isIncludedInFilter(filterQuery, labelToFilters!)) {
        return false; // assessment was filtered out
      }
      // assessment survived filtering, get all issues
      visibleIssueTpsRecordIds = !assessment.issues
        ? new Set()
        : getVisibleIssueTpsRecordIds(labelToFilters!, assessment.issues!, bundle);
    } else {
      // no filter is applied thus all issues are visible
      visibleIssueTpsRecordIds = !assessment.issues
        ? new Set()
        : new Set(assessment.issues!.map((issue) => issue.tpsRecordId));
    }

    if (isSearchApplied(labelToFilters)) {
      const isAssessmentSearchResult = isSearchResult(filterQuery, labelToFilters!);
      if (!(isAssessmentSearchResult || visibleIssueTpsRecordIds.size > 0)) {
        // search is active and neither assessment nor issues appeared
        return false;
      }
      const passesIssueFilter = areIssueFiltersApplied(labelToFilters) ? visibleIssueTpsRecordIds.size > 0 : true;
      if (isTptaSearchApplied(labelToFilters)) return isAssessmentSearchResult && passesIssueFilter; // TPTA is searched, assessment must match
      // search is active and assessment or issue appeared
      return passesIssueFilter;
    }
    return (
      (!areIssueFiltersApplied(labelToFilters) && (assessment.issues?.length ?? 0) === 0) ||
      visibleIssueTpsRecordIds.size > 0
    );
  };
}

/**
 * Business requirements https://issues.amazon.com/issues/TPSE-2115
 *
 * Intake: tier field is null (intake in progress)
 * DDQ: tier field is not null & an open DDQ exists
 * Issues Management: tier field is not null & no open DDQs exist & at-least 1 open issue with state
 *   Pending TPS Review, Pending for Third Party remediation, or misc
 *
 * @param assessments
 * @returns
 */
function createSwimlanes(assessments: Assessment[]): Map<SwimlaneType, Assessment[]> {
  const columnMap = new Map<SwimlaneType, Assessment[]>([
    [SwimlaneType.INTAKE, []],
    [SwimlaneType.DDQ, []],
    [SwimlaneType.ISSUE, []],
  ]);

  assessments.forEach((assessment) => {
    if (stateStrToEnum(assessment.state) === State.CANCELLED) return;

    if (tierToEnum(assessment.tier) === Tier.NONE) {
      columnMap.get(SwimlaneType.INTAKE)!.push(assessment);
      return;
    }

    if (assessment.questionnaires?.find((questionnaire) => !isClosedCompleteNa(stateStrToEnum(questionnaire.state)))) {
      columnMap.get(SwimlaneType.DDQ)!.push(assessment);
      return;
    }

    if (assessment.issueCount! >= 1) {
      columnMap.get(SwimlaneType.ISSUE)!.push(assessment);
      return;
    }

    if (!isClosedCompleteNa(stateStrToEnum(assessment.state))) columnMap.get(SwimlaneType.DDQ)!.push(assessment);
  });

  return columnMap;
}

export { createSwimlanes, filterBarFilter };
