import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import { MessageBundle } from '@amzn/arb-tools';
import MuiBox from '@mui/material/Box';
import MuiLink from '@mui/material/Link';
import MuiCollapse from '@mui/material/Collapse';
import MuiTypography from '@mui/material/Typography';
import { styled } from '@mui/material';
import Button from '../../components/Button/Button';
import FilterBar from '../../components/FilterBar/FilterBar';
import LoadingSpinner from '../../components/LoadingComponents/LoadingSpinner';
import { useImpersonation, useUserContext } from '../../context/UserProvider';
import AccordionDownArrowIcon from '../../images/accordionDownArrow.svg';
import getTPSPortalUrl from '../../utils/redirectionUtils';
import filterReducer from '../../components/FilterBar/FilterReducer';
import { isVisible } from '../../utils/filterUtils';
import { State, Consult, SortResult, TPSRecordType } from '../../../src/data/RecordMetadata';
import { BREAKPOINT, FILTER_LABELS, HOVER_EFFECT, PAGE_TITLE, RED } from '../../constants/constants';
import { NEW_CONSULT } from '../../constants/linkConstants';
import { SnowUrlType } from '../../constants/urlConstants';
import { stateStrToEnum } from '../../utils/enumUtils';
import { consultDataIsLoaded, getConsultLabelColor, getLoadState, isConsultActionable } from '../../utils/dataUtils';
import { LoadState, useDataContext } from '../../context/DataProvider';
import { UserMetadata } from '../../data/UserMetadata';
import './ConsultsPage.css';

const ConsultAccordion = styled('button')({
  border: 'none',
  backgroundColor: 'white',
  '&:hover': {
    backgroundColor: 'unset',
    color: 'unset',
    cursor: 'pointer',
  },
});

const ConsultAccordionText = styled(MuiTypography)(({ theme }) => ({
  fontSize: '20px',
  paddingLeft: '6px',
  color: theme.palette.primary.backDrop,
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '13px',
    paddingLeft: '4px',
  },
}));

const TableHeaderText = styled(MuiTypography)(({ theme }) => ({
  fontSize: '20px',
  paddingLeft: '6px',
  color: theme.palette.primary.backDrop,
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '13px',
    paddingLeft: '4px',
  },
}));

const CellText = styled(MuiTypography)(({ theme }) => ({
  padding: '6px',
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '11px',
    paddingLeft: '4px',
  },
}));

const StyledLink = styled(MuiLink)({
  color: 'inherit',
  textDecoration: 'none',
  '&:hover': HOVER_EFFECT,
});

const StateLabel = styled(MuiTypography)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '11px',
  borderRadius: '20px',
  width: '200px',
  height: '25px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '7px',
    height: '15px',
    width: '130px',
  },
}));

enum ConsultStatus {
  OPEN = 'open',
  CLOSED = 'closed',
}

interface ConsultTableProps {
  consults: Consult[];
  bundle: MessageBundle;
  expanded: boolean;
  type: ConsultStatus;
}

const CLOSED_STATES = [State.CLOSED, State.COMPLETE, State.CANCELLED];

//algorithm to sort consults
//1) Open Consults will be sorted by: (1) Actionable (2) creation date
//2) Closed Consults will be sorted by: (1) Closure date
function consultSort(consultA: Consult, consultB: Consult, status: string, user: UserMetadata): SortResult {
  if (status === ConsultStatus.CLOSED)
    return consultA.dateUpdated < consultB.dateUpdated ? SortResult.SECOND_RECORD_WINS : SortResult.FIRST_RECORD_WINS;

  const aActionable: boolean = isConsultActionable(consultA, user);
  const bActionable: boolean = isConsultActionable(consultB, user);

  if (aActionable && !bActionable) return SortResult.FIRST_RECORD_WINS;

  if (!aActionable && bActionable) return SortResult.SECOND_RECORD_WINS;

  //if both are actionable or both are not actionable sorting by consult create date
  //adding null checks here as BE had missing fields issue
  if (!consultA.dateCreated && !consultB.dateCreated) return SortResult.NO_RECORD_WINS;

  if (consultA.dateCreated && !consultB.dateCreated) return SortResult.FIRST_RECORD_WINS;

  if (!consultA.dateCreated && consultB.dateCreated) return SortResult.SECOND_RECORD_WINS;

  if (consultA.dateCreated < consultB.dateCreated) return SortResult.SECOND_RECORD_WINS;

  if (consultA.dateCreated > consultB.dateCreated) return SortResult.FIRST_RECORD_WINS;

  return SortResult.NO_RECORD_WINS;
}

function ConsultTable({ consults, bundle, expanded, type }: ConsultTableProps) {
  const user = useUserContext();
  const { impersonationIntent } = useImpersonation();

  return (
    <MuiCollapse in={expanded} timeout="auto" unmountOnExit>
      <MuiBox role="table" className="consults-table">
        <MuiBox role="row" className="consults-table-row">
          <MuiBox role="columnheader">
            <TableHeaderText>{bundle.getMessage('record_id_header')}</TableHeaderText>
          </MuiBox>
          <MuiBox role="columnheader">
            <TableHeaderText>{bundle.getMessage('record_description_header')}</TableHeaderText>
          </MuiBox>
          <MuiBox role="columnheader">
            <TableHeaderText>{bundle.getMessage('date_created_header')}</TableHeaderText>
          </MuiBox>
          {type === ConsultStatus.CLOSED && (
            <MuiBox role="columnheader">
              <TableHeaderText>{bundle.getMessage('date_closed_header')}</TableHeaderText>
            </MuiBox>
          )}
          <MuiBox role="columnheader">
            <TableHeaderText>{bundle.getMessage('state_header')}</TableHeaderText>
          </MuiBox>
        </MuiBox>
        {consults.length > 0 &&
          consults.map((consult: Consult) => {
            return (
              <MuiBox key={consult.tpsRecordSystemId} className="consults-table-row">
                <StyledLink
                  {...{
                    component: Link,
                    to: getTPSPortalUrl({
                      id: consult.tpsRecordSystemId,
                      linkType: SnowUrlType.CONSULT,
                      userRole: user.role,
                      impersonationIntent,
                    }),
                  }}
                  role="cell"
                >
                  <CellText>
                    {bundle.formatMessage('consult_record_id', {
                      tpsRecordId: consult.tpsRecordId,
                    })}
                  </CellText>
                </StyledLink>
                <StyledLink
                  {...{
                    component: Link,
                    to: getTPSPortalUrl({
                      id: consult.tpsRecordSystemId,
                      linkType: SnowUrlType.CONSULT,
                      userRole: user.role,
                      impersonationIntent,
                    }),
                  }}
                  role="cell"
                >
                  <CellText
                    sx={{
                      color: isConsultActionable(consult, user) ? RED : 'inherit',
                      '&:hover': HOVER_EFFECT,
                    }}
                  >
                    {bundle.formatMessage('consult_name_description', {
                      name: consult.name,
                      description: consult.description,
                    })}
                  </CellText>
                </StyledLink>
                <MuiBox role="cell">
                  <CellText>
                    {bundle.formatMessage('date_cell', {
                      date: new Date(0).setUTCSeconds(Number(consult.dateCreated)),
                    })}
                  </CellText>
                </MuiBox>
                {type === ConsultStatus.CLOSED && (
                  <MuiBox role="cell">
                    <CellText>
                      {bundle.formatMessage('date_cell', {
                        date: new Date(0).setUTCSeconds(Number(consult.dateUpdated)),
                      })}
                    </CellText>
                  </MuiBox>
                )}
                <MuiBox role="cell" className="consults-state-label-cell">
                  <StateLabel sx={getConsultLabelColor(consult, user)}>
                    {bundle.formatMessage('record_state', { state: consult.state })}
                  </StateLabel>
                </MuiBox>
              </MuiBox>
            );
          })}
      </MuiBox>
    </MuiCollapse>
  );
}

function ConsultsPage({ bundle }: WithBundleProps) {
  const [state, dispatch] = React.useReducer(filterReducer, { filtersLoading: true });
  const [dataState] = useDataContext();
  const [openExpanded, setOpenExpanded] = React.useState(true);
  const [closedExpanded, setClosedExpanded] = React.useState(false);
  const user = useUserContext();
  const { impersonationIntent } = useImpersonation();

  const filterConsultsFn = (type: ConsultStatus) =>
    type === ConsultStatus.OPEN
      ? (consult: Consult) => !CLOSED_STATES.includes(consult.state)
      : (consult: Consult) => CLOSED_STATES.includes(consult.state);

  const [openConsults, closedConsults] = useMemo(() => {
    if (!consultDataIsLoaded(dataState)) return [[], []];
    const allConsults = dataState.consults.filter(
      (consult: Consult) =>
        state.filtersLoading ||
        (state.filterFields?.labelToFilters.size ?? 0) === 0 ||
        isVisible(
          {
            state: bundle.formatMessage('record_state', {
              state: stateStrToEnum(consult.state),
            }),
            search: [
              consult.name,
              consult.description,
              consult.tpsRecordId,
              bundle.formatMessage('date_cell', {
                date: new Date(0).setUTCSeconds(Number(consult.dateCreated)),
              }),
            ],
          },
          state.filterFields!.labelToFilters,
        ),
    );

    return [
      allConsults
        .filter(filterConsultsFn(ConsultStatus.OPEN))
        .sort((consultA, consultB) => consultSort(consultA, consultB, ConsultStatus.OPEN, user)),
      allConsults
        .filter(filterConsultsFn(ConsultStatus.CLOSED))
        .sort((consultA, consultB) => consultSort(consultA, consultB, ConsultStatus.CLOSED, user)),
    ];
  }, [dataState, state.filtersLoading, state.filterFields]);

  const handleOpenExpand = () => setOpenExpanded(!openExpanded);
  const handleClosedExpand = () => setClosedExpanded(!closedExpanded);

  //useEffect for initial state to contain filtermap, label sets at component level
  useEffect(() => {
    if (!consultDataIsLoaded(dataState)) {
      if (!state.filtersLoading) {
        dispatch({ type: 'reset' });
      }
      return;
    }

    dataState.consults.map((consult) => (consult.state = stateStrToEnum(consult.state)));
    dispatch({
      type: 'initialSetup',
      payload: {
        labels: [FILTER_LABELS.STATE],
        bundle,
        componentData: dataState.consults,
      },
    });
  }, [state.filtersLoading, dataState]);

  useEffect(() => {
    if (openConsults.length > 0) setOpenExpanded(true);
    else setOpenExpanded(false);
  }, [openConsults]);

  useEffect(() => {
    if ((state.filterFields?.labelToFilters?.size ?? 0) > 0 && closedConsults.length > 0) setClosedExpanded(true);
    else setClosedExpanded(false);
  }, [closedConsults]);

  return (
    <MuiBox className="consults-page-container">
      <React.Fragment>
        {state.filtersLoading ? (
          <LoadingSpinner />
        ) : (
          <FilterBar
            filterFields={state.filterFields!}
            filterLabels={state.labels!}
            dispatch={dispatch}
            title={PAGE_TITLE.CONSULTS_PAGE}
          />
        )}
        <MuiLink
          {...{
            component: Link,
            to: getTPSPortalUrl({ linkType: SnowUrlType.NEW_CONSULT, userRole: user.role, impersonationIntent }),
          }}
          underline="none"
          color="inherit"
          sx={{ cursor: 'pointer' }}
        >
          <Button type="dark" style={{ padding: '5px 7px', cursor: 'pointer' }}>
            {bundle.getMessage(NEW_CONSULT.label)}
          </Button>
        </MuiLink>

        {getLoadState(dataState, TPSRecordType.CONSULT) === LoadState.LOADING && <LoadingSpinner />}
        {getLoadState(dataState, TPSRecordType.CONSULT) === LoadState.SUCCESS && (
          <React.Fragment>
            <MuiBox aria-hidden className="open-consults-container">
              <ConsultAccordion role="rowgroup" className="consults-table-accordion" onClick={handleOpenExpand}>
                <img
                  src={AccordionDownArrowIcon}
                  style={{
                    transform: !openExpanded ? 'rotate(-90deg)' : 'rotate(0deg)',
                    transitionDuration: '500ms',
                    padding: 0,
                  }}
                  aria-expanded={openExpanded}
                  aria-label="show more"
                />
                <ConsultAccordionText>{bundle.getMessage('open_consults')}</ConsultAccordionText>
              </ConsultAccordion>
              <ConsultTable consults={openConsults} bundle={bundle} expanded={openExpanded} type={ConsultStatus.OPEN} />
            </MuiBox>

            <MuiBox aria-hidden className="closed-consults-container">
              <ConsultAccordion role="rowgroup" className="consults-table-accordion" onClick={handleClosedExpand}>
                <img
                  src={AccordionDownArrowIcon}
                  style={{
                    transform: !closedExpanded ? 'rotate(-90deg)' : 'rotate(0deg)',
                    transitionDuration: '500ms',
                  }}
                  aria-expanded={closedExpanded}
                  aria-label="show more"
                />
                <ConsultAccordionText>{bundle.getMessage('closed_consults')}</ConsultAccordionText>
              </ConsultAccordion>
              <ConsultTable
                consults={closedConsults}
                bundle={bundle}
                expanded={closedExpanded}
                type={ConsultStatus.CLOSED}
              />
            </MuiBox>
          </React.Fragment>
        )}
      </React.Fragment>
    </MuiBox>
  );
}

export default withBundle('pages.ConsultsPage')(ConsultsPage);
