import * as KatalMetrics from '@amzn/katal-metrics';
import KatalMetricsDriverConsoleLogJson from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverConsoleLogJson';
import KatalMetricsDriverArrayCollector from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverArrayCollector';
import { KatalMonitoringDriver } from '@amzn/katal-monitoring-aws-driver';

const ALL_METHODS = 'ALL';
const FATAL_COUNT = 'FATAL.Count';
const ERROR_COUNT = 'ERROR.Count';
const TIME = 'Time';

export default class MetricsPublisher {
  private metricsPublisher: KatalMetrics.Publisher;
  public constructor(authToken: string) {
    this.metricsPublisher = this.makePublisher(authToken);
  }

  private makePublisher = (authToken: string): KatalMetrics.Publisher => {
    const metricsDriver = this.makeMetricsDriver(authToken);
    const metricsConsoleErrorHandler = (err: Error) => console.error(err);
    const initialMetricsContext = new KatalMetrics.Context.Builder()
      .withSite('TPSMyAssessmentPortalWebsite')
      .withServiceName('TPSMyAssessments')
      .build();
    return new KatalMetrics.Publisher(metricsDriver, metricsConsoleErrorHandler, initialMetricsContext);
  };

  private makeMetricsDriver = (authToken: string): KatalMetrics.MetricsDriver => {
    if (process.env.NODE_ENV === 'test') {
      const metricsDriver = new KatalMetricsDriverArrayCollector();
      //  Attach to global window object so tests can see it
      (window as any).metricsDriver = metricsDriver; // eslint-disable-line
      return metricsDriver;
    } else if (process.env.NODE_ENV !== 'production') {
      return new KatalMetricsDriverConsoleLogJson();
    } else {
      return new KatalMonitoringDriver({
        url: 'https://83nd54ykce.execute-api.us-west-2.amazonaws.com/prod/v1/monitoring',
        logToConsole: false,
        headers: {
          Authorization: authToken,
        },
      });
    }
  };

  public getInitializationMetricsPublisher = (): KatalMetrics.Publisher =>
    this.metricsPublisher.newChildActionPublisherForInitialization();

  public getPublisher = (methodName: string): KatalMetrics.Publisher =>
    this.metricsPublisher.newChildActionPublisherForMethod(methodName);

  public publishCounter = (methodName: string, counterName: string, value = 1) =>
    this.getPublisher(methodName).publishCounterMonitor(counterName, value);

  public publishLatency = (methodName: string, duration: number) =>
    this.getPublisher(methodName).publishTimerMonitor(TIME, duration);

  public publishMetric = (methodName: string, counterName: string, value?: number) => {
    this.publishCounter(methodName, counterName, value);
    this.publishCounter(ALL_METHODS, counterName, value);
  };

  public publishFatal = (methodName: string) => this.publishMetric(methodName, FATAL_COUNT);

  public publishZeroCountFatal = (methodName: string) => this.publishMetric(methodName, FATAL_COUNT, 0);

  public publishError = (methodName: string) => this.publishMetric(methodName, ERROR_COUNT);

  public publishZeroCountError = (methodName: string) => this.publishMetric(methodName, ERROR_COUNT, 0);

  public publishMetricForApiCallFailure = (error: any, methodName: string) => {
    const responseStatus = error?.response?.status;
    if (responseStatus >= 400 && responseStatus < 500) {
      this.publishError(methodName);
      this.publishZeroCountFatal(methodName);
    } else {
      this.publishFatal(methodName);
      this.publishZeroCountError(methodName);
    }
  };

  public publishSuccessMetrics = (metricName: string, elapsedTime: number) => {
    this.publishLatency(metricName, elapsedTime),
      this.publishZeroCountError(metricName),
      this.publishZeroCountFatal(metricName);
  };

  public publishFailureMetrics = (error: any, metricName: string, elapsedTime: number) => {
    this.publishLatency(metricName, elapsedTime), this.publishMetricForApiCallFailure(error, metricName);
  };

  /**
   * Creates metric object that tracks the duration of an operation.
   * TimerStopwatch metric will record the start time when it is constructed.
   * When metric is published it will record the stop time and
   * publish the elapsed time in milliseconds.
   */
  public createTimerStopwatch = (metricName: string) => {
    const timerStopwatch = new KatalMetrics.Metric.TimerStopwatch(metricName);
    return {
      withMonitor: () => timerStopwatch.withMonitor(),
      start: () => timerStopwatch.start(),
      stop: () => timerStopwatch.stop(),
    };
  };
}
