import React from 'react';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import MuiBox from '@mui/material/Box';
import MuiModal from '@mui/material/Modal';
import MuiTypography from '@mui/material/Typography';
import MuiButton from '@mui/material/Button';
import MuiLink from '@mui/material/Link';
import { LOGOUT } from '../../constants/linkConstants';
import { FAQ_URL } from '../../constants/urlConstants';
import { styled } from '@mui/material';
import { AMAZON_ORANGE, RED, WHITE } from '../../constants/constants';

const ModalBoxStyled = styled(MuiBox)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '33vw',
  backgroundColor: WHITE,
  border: '2px solid #000',
  boxShadow: '2vw',
  padding: '3vw',
});

interface ChangePasswordProps extends WithBundleProps {
  open: boolean;
  openModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function ChangePassword(props: ChangePasswordProps): React.ReactElement {
  const handleClose = (event: React.MouseEvent, reason?: string) => {
    if (reason !== 'backdropClick') {
      props.openModal(false);
    }
  };

  return (
    <React.Fragment>
      <MuiModal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown
      >
        <ModalBoxStyled>
          <MuiTypography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              fontWeight: 'bold',
              fontFamily: 'Amazon Ember',
              textAlign: 'center',
            }}
          >
            {props.bundle.getMessage('change_password_heading')}
          </MuiTypography>
          <MuiBox sx={{ mt: '2vh', fontFamily: 'Amazon Ember' }}>
            {props.bundle.getMessage('change_password_instructions_paragraph1')}
            <ol>
              <li>{props.bundle.getMessage('change_password_instructions_step1')}</li>
              <li>{props.bundle.getMessage('change_password_instructions_step2')}</li>
              <li>{props.bundle.getMessage('change_password_instructions_step3')}</li>
              <li>{props.bundle.getMessage('change_password_instructions_step4')}</li>
            </ol>
            {props.bundle.formatMessage('change_password_instructions_notes', {
              link: (children: string) => (
                <MuiLink
                  href={FAQ_URL}
                  underline="none"
                  key={'change_password_instructions_notes'}
                  target="_blank"
                  onClick={(event) => {
                    event.stopPropagation(); //preventing event propagation here as this link component is wrapped insider another link component. To prevent parent link functionality on click preventing it's effect.
                  }}
                >
                  {children}
                </MuiLink>
              ),
            })}
          </MuiBox>
          <MuiBox sx={{ display: 'flex', mt: '3vh', justifyContent: 'space-between' }}>
            <MuiButton
              variant="outlined"
              onClick={handleClose}
              sx={{
                color: RED,
                borderColor: RED,
                fontFamily: 'Amazon Ember',
                '&:hover': {
                  color: RED,
                  borderColor: RED,
                },
              }}
            >
              {props.bundle.getMessage('cancel')}
            </MuiButton>
            <MuiButton
              variant="contained"
              onClick={() => (window.location.href = LOGOUT.href)}
              sx={{
                backgroundColor: AMAZON_ORANGE,
                fontFamily: 'Amazon Ember',
                '&:hover': {
                  backgroundColor: AMAZON_ORANGE,
                },
              }}
            >
              {props.bundle.getMessage('logout')}
            </MuiButton>
          </MuiBox>
        </ModalBoxStyled>
      </MuiModal>
    </React.Fragment>
  );
}

export default withBundle('components.ChangePassword')(ChangePassword);
