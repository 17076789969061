import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { get } from './ApiGatewayInvoker';
import { ActivityPayload } from '../data/RecordMetadata';
import { retryConfig, cacheConfig, getCommonParams } from './ApiUtils';
import { useImpersonation } from '../context/UserProvider';

const ACTIVITIES_API_PATH = '/api/v1/activities';
// This is a sitewide requirement coming from the BRD
const DAYS = 90;

export const useGetActivitiesByUserQuery = (): UseQueryResult<ActivityPayload> => {
  const { impersonationTarget } = useImpersonation();
  const commonParams = getCommonParams();

  return useQuery<ActivityPayload>({
    queryKey: ['getActivitiesKey', impersonationTarget],
    queryFn: async () => {
      return await get<ActivityPayload>({
        apiPath: `${ACTIVITIES_API_PATH}/?days=${DAYS}`,
        name: 'Activity',
        user: impersonationTarget,
        emptyResponseConfig: {
          404: { activities: [] },
        },
        ...commonParams,
      });
    },
    ...retryConfig,
    ...cacheConfig,
  });
};
