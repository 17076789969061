import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import FederatedSignIn from '../components/Login/FederatedSignIn';
import { UserProvider } from './UserProvider';
import { MetricsProvider } from '../logging/MetricsProvider';

type CognitoUserAuthData = {
  name: string;
  email: string;
  is1p: boolean;
  cognitoUser: CognitoUser;
  authToken: string;
};
export type UserAuthData = Omit<CognitoUserAuthData, 'cognitoUser'>;

export function MetricsAndUserProvider({ children }: { children: ReactNode }): JSX.Element {
  const [cognitoUserAuthData, setCognitoUserAuthData] = useState<CognitoUserAuthData | null>(null);
  const retried = useRef<boolean>(false);

  const extractAndSetData = (user: CognitoUser | null) => {
    if (!user) {
      setCognitoUserAuthData(null);
      return;
    }
    const attributes = user.getSignInUserSession()?.getIdToken()?.decodePayload();
    const authToken = user.getSignInUserSession()?.getAccessToken().getJwtToken();
    // See https://tiny.amazon.com/16v8wi6jv/codeamazpackTPSMblobf021lib for attributes
    setCognitoUserAuthData({
      name: `${attributes!.given_name} ${attributes!.family_name}`,
      email: attributes!.email,
      is1p: attributes!['custom:authMethod'] === 'MIDWAY',
      cognitoUser: user,
      authToken: authToken!,
    });
  };

  // Check if there is a currently active session
  // when the provider is mounted for the first time.
  //
  // If there is an error, it means there is no session.
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((authenticatedUser) => {
        extractAndSetData(authenticatedUser);
      })
      .catch((error) => {
        // The very first render may fail as the user will not initially be logged in
        if (!retried.current) {
          retried.current = true;
        } else {
          if (process.env.NODE_ENV === 'development') console.error(error);
        }
      });
  }, []);

  if (!cognitoUserAuthData || !cognitoUserAuthData.cognitoUser?.getSignInUserSession()) return <FederatedSignIn />;
  return (
    <MetricsProvider userAuthData={cognitoUserAuthData}>
      <UserProvider userAuthData={cognitoUserAuthData}>{children}</UserProvider>
    </MetricsProvider>
  );
}
