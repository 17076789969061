import React, { createContext, ReactElement, ReactNode, useContext, useEffect, useMemo, useReducer } from 'react';
import { UserAuthData } from './MetricsAndUserProvider';
import { useUserDetailsQuery } from '../apis/UserDetailsAPI';
import { RoleType, UserMetadata } from '../data/UserMetadata';
import { THIRD_PARTY_FLAG, THIRD_PARTY_TARGET, TRUE } from '../constants/constants';
import LoadingSpinner from '../components/LoadingComponents/LoadingSpinner';

export type ImpersonationIntentType = 'disabled' | 'dualRole' | 'impersonationRole';

type StateType = {
  performedInit: boolean;
  impersonationIntent: ImpersonationIntentType;
  impersonationTarget: string;
  userData: UserMetadata;
};

type UserDetails = Omit<UserMetadata, 'authToken'>;

type ActionType =
  | { type: 'storageInit' }
  | { type: 'queryInit'; userData: UserDetails }
  | { type: 'queryUpdate'; userData: UserDetails }
  | {
      type: 'setImpersonationIntent';
      impersonationIntent: ImpersonationIntentType;
      userAuthData: UserAuthData;
    };

const reducer = (prevState: StateType, action: ActionType): StateType => {
  switch (action.type) {
    case 'storageInit':
      return {
        performedInit: true,
        impersonationIntent: 'dualRole',
        // dual users will have email matching impersonation target
        impersonationTarget: window.localStorage.getItem(THIRD_PARTY_TARGET)!,
        userData: {
          ...prevState.userData,
          primaryContact: false,
          role: RoleType.VENDOR,
        },
      };
    case 'queryInit':
      let impersonationIntent: ImpersonationIntentType = 'disabled';
      let impersonationTarget = '';
      if (action.userData.hasImpersonated) {
        impersonationIntent = 'impersonationRole';
        impersonationTarget = action.userData.impersonatedUser;
      }
      return {
        performedInit: true,
        impersonationIntent,
        impersonationTarget,
        userData: { ...action.userData, authToken: prevState.userData.authToken },
      };
    case 'queryUpdate':
      return {
        ...prevState,
        userData: { ...action.userData, authToken: prevState.userData.authToken },
      };
    // This would only occur for dual users for P0/P1
    case 'setImpersonationIntent':
      let impersonation: { impersonationIntent: ImpersonationIntentType; impersonationTarget: string } | null = null;
      if (action.impersonationIntent === 'dualRole') {
        window.localStorage.setItem(THIRD_PARTY_FLAG, TRUE);
        window.localStorage.setItem(THIRD_PARTY_TARGET, prevState.userData.dualRoleTarget);
        // -> dualRole
        impersonation = {
          impersonationIntent: action.impersonationIntent,
          impersonationTarget: prevState.userData.dualRoleTarget,
        };
      } else {
        window.localStorage.removeItem(THIRD_PARTY_FLAG);
        window.localStorage.removeItem(THIRD_PARTY_TARGET);
        if (prevState.userData.email === action.userAuthData.email) {
          // 1P dual role -> disabled
          impersonation = {
            impersonationIntent: 'disabled',
            impersonationTarget: prevState.userData.dualRoleTarget,
          };
        } else {
          // 1P impersonating dual role -> 1P impersonating
          impersonation = {
            impersonationIntent: 'impersonationRole',
            impersonationTarget: prevState.userData.email.split('@')[0],
          };
        }
      }
      return {
        performedInit: prevState.performedInit,
        ...impersonation,
        userData: {
          ...prevState.userData,
          primaryContact: false,
          // role must be set before User Details response to immediately update router
          role: action.impersonationIntent === 'dualRole' ? RoleType.VENDOR : RoleType.REQUESTER,
        },
      };
  }
};

type ImpersonationContextType = {
  impersonationIntent: ImpersonationIntentType;
  impersonationTarget: string;
  setImpersonationIntent: (impersonationIntent: ImpersonationIntentType) => void;
};

const EMPTY_IMPERSONATION = {
  hasDualRole: false,
  dualRoleTarget: '',
  canImpersonate: false,
  hasImpersonated: false,
  impersonatedUser: '',
};

const UserDataContext = createContext<UserMetadata>({
  name: '',
  email: '',
  authToken: '',
  role: RoleType.NONE,
  primaryContact: false,
  ...EMPTY_IMPERSONATION,
});

const ImpersonationContext = createContext<ImpersonationContextType>({
  impersonationIntent: 'disabled',
  impersonationTarget: '',
  setImpersonationIntent: (impersonationIntent) => {
    process.env.NODE_ENV === 'development' && console.log('impersonationIntent', impersonationIntent);
  },
});

const useUserContext = () => useContext(UserDataContext);
const useImpersonation = () => useContext(ImpersonationContext);

interface UserProviderProps {
  userAuthData: UserAuthData;
  children: ReactNode;
}

function UserProvider({ userAuthData, children }: UserProviderProps): ReactElement {
  const [state, dispatch] = useReducer(reducer, {
    performedInit: false,
    impersonationIntent: 'disabled',
    impersonationTarget: '',
    userData: {
      name: userAuthData.name,
      email: userAuthData.email,
      authToken: userAuthData.authToken,
      role: userAuthData.is1p ? RoleType.REQUESTER : RoleType.VENDOR,
      primaryContact: false,
      ...EMPTY_IMPERSONATION,
    },
  });

  const userDetailsQuery = useUserDetailsQuery(state.userData.authToken, state.impersonationTarget);

  useEffect(() => {
    if (!state.performedInit && window.localStorage.getItem(THIRD_PARTY_FLAG) === TRUE) {
      dispatch({ type: 'storageInit' });
      return;
    }
    if (!userDetailsQuery.isSuccess) return;

    const userDetailsQueryAttributes: UserDetails = {
      name: userDetailsQuery.data.name,
      email: userDetailsQuery.data.email,
      role: userDetailsQuery.data.userType === 'sys_user' ? RoleType.REQUESTER : RoleType.VENDOR,
      primaryContact: userDetailsQuery.data.primaryContact ?? false,
      hasDualRole: userDetailsQuery.data.hasDualRole ?? false,
      dualRoleTarget: userDetailsQuery.data.dualRoleTarget ?? '',
      canImpersonate: userDetailsQuery.data.canImpersonate ?? false,
      hasImpersonated: userDetailsQuery.data.hasImpersonated ?? false,
      impersonatedUser: userDetailsQuery.data.impersonatedUser ?? '',
    };

    // We may need to continue impersonation after refresh or from SNOW session
    if (!state.performedInit) {
      dispatch({
        type: 'queryInit',
        userData: userDetailsQueryAttributes,
      });
      return;
    }

    // User Details query has been invalidated due impersonation change
    dispatch({
      type: 'queryUpdate',
      userData: userDetailsQueryAttributes,
    });
  }, [state.performedInit, userDetailsQuery.data]);

  // Prevent unnecessary renders due to referential inequality
  const impersonationContextValue: ImpersonationContextType = useMemo(() => {
    return {
      impersonationTarget: state.impersonationTarget,
      impersonationIntent: state.impersonationIntent,
      setImpersonationIntent: (impersonationIntent: ImpersonationIntentType) => {
        dispatch({
          type: 'setImpersonationIntent',
          impersonationIntent,
          userAuthData,
        });
      },
    };
  }, [state.impersonationTarget, state.impersonationIntent, dispatch]);

  // Is this a dual user returning for the initial load
  if (!state.performedInit && window.localStorage.getItem(THIRD_PARTY_FLAG) === TRUE) return <LoadingSpinner />;
  return (
    <UserDataContext.Provider value={state.userData}>
      <ImpersonationContext.Provider value={impersonationContextValue}>{children}</ImpersonationContext.Provider>
    </UserDataContext.Provider>
  );
}

export { UserProvider, useUserContext, useImpersonation };
