import React, { useReducer, useEffect, useMemo, ReactElement, useRef } from 'react';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import Assessment, { SwimlaneType } from '../../../src/data/RecordMetadata';
import ThirdPartyTieringAssessmentCard from '../../components/ThirdPartyTieringAssessmentCards/ThirdPartyTieringAssessmentCard';
import MuiTypography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import NewIntakeCard from '../../components/ThirdPartyTieringAssessmentCards/NewIntakeCard';
import LoadingSpinner from '../../components/LoadingComponents/LoadingSpinner';
import FilterBar from '../../components/FilterBar/FilterBar';
import filterReducer from '../../components/FilterBar/FilterReducer';
import { sortFavoritesFirst } from '../../utils/sortingUtils';
import { useUserContext } from '../../context/UserProvider';
import { createSwimlanes, filterBarFilter } from '../../utils/swimlaneStageUtils';
import { useDataContext } from '../../context/DataProvider';
import { activitiesDataIsLoaded, inProgressAssessmentsAreFiltered } from '../../utils/dataUtils';
import { RoleType, UserMetadata } from '../../data/UserMetadata';
import sortTPTAByActionability from '../../utils/tptaCardTextColorUtils';
import { BREAKPOINT, FILTER_LABELS, PAGE_TITLE } from '../../constants/constants';
import './LandingPage.css';

const SwimLaneHeader = styled(MuiTypography)(({ theme }) => ({
  fontSize: '1rem',
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumZoomResolution)]: {
    fontSize: '13px',
  },
}));

export const SWIMLANE_TYPES: readonly SwimlaneType[] = [SwimlaneType.INTAKE, SwimlaneType.DDQ, SwimlaneType.ISSUE];

interface SwimlaneProps extends WithBundleProps {
  laneType: SwimlaneType;
  cards: Assessment[];
  activitiesAreLoaded: boolean;
  user: UserMetadata;
}
function Swimlane(props: SwimlaneProps): ReactElement {
  // Sorting above the return to get the correct count
  const assessments = sortFavoritesFirst(props.cards.sort((a, b) => sortTPTAByActionability(a, b, props.user)));

  return (
    <article className="swimlane-container">
      <SwimLaneHeader>{props.bundle.formatMessage(props.laneType, { num: assessments.length })}</SwimLaneHeader>
      <div className="swimlane-divider"></div>
      {assessments.map((assessment: Assessment) => (
        <ThirdPartyTieringAssessmentCard
          key={assessment.tpsRecordId}
          assessment={assessment}
          type={props.laneType}
          activitiesAreLoaded={props.activitiesAreLoaded}
        />
      ))}
      {props.laneType === SwimlaneType.INTAKE && <NewIntakeCard />}
    </article>
  );
}

function LandingPage({ bundle }: WithBundleProps) {
  const user = useUserContext();
  const [state, dispatch] = useReducer(filterReducer, { filtersLoading: true });
  const [dataState] = useDataContext();

  const swimLaneRef = useRef<Map<SwimlaneType, Assessment[]>>();

  const filteredSwimLanes = useMemo(() => {
    const filteredSwimlaneToAssessments = new Map<SwimlaneType, Assessment[]>();
    if (swimLaneRef.current) {
      SWIMLANE_TYPES.forEach((swimlaneType) => {
        const unfilteredSwimlane = swimLaneRef.current!.get(swimlaneType) ?? [];
        filteredSwimlaneToAssessments.set(
          swimlaneType,
          unfilteredSwimlane.filter(
            filterBarFilter(bundle, state.filtersLoading, state.filterFields?.labelToFilters, swimlaneType, user.role),
          ),
        );
      });
    }
    return filteredSwimlaneToAssessments;
  }, [state.filtersLoading, state.filterFields, swimLaneRef.current]);

  useEffect(() => {
    if (!inProgressAssessmentsAreFiltered(dataState)) {
      if (!state.filtersLoading) {
        dispatch({ type: 'reset' });
      }
      return;
    }

    swimLaneRef.current = createSwimlanes(dataState.inProgressAssessments);

    // We can't use state because there are sometimes remainder
    // assessments that aren't shown. We don't want to filter
    // on those.
    const assessmentsShown: Assessment[] = [];
    swimLaneRef.current.forEach((value) => value.forEach((assessment) => assessmentsShown.push(assessment)));

    if (swimLaneRef.current) {
      dispatch({
        type: 'initialSetup',
        payload: {
          labels: [FILTER_LABELS.VENDOR, FILTER_LABELS.TIER, FILTER_LABELS.STATE, FILTER_LABELS.ISSUE_SEVERITY],
          bundle,
          componentData: assessmentsShown || [],
        },
      });
    }
  }, [dataState, state.filtersLoading]);

  return (
    <div className="landing-page-content-container">
      <React.Fragment>
        <div className="landing-page-header">
          {state.filtersLoading ? (
            <LoadingSpinner />
          ) : (
            <FilterBar
              filterFields={state.filterFields!}
              filterLabels={state.labels!}
              dispatch={dispatch}
              title={PAGE_TITLE.LANDING_PAGE}
            />
          )}
        </div>
        {inProgressAssessmentsAreFiltered(dataState) && filteredSwimLanes && (
          <section className="swimlane-container-container">
            {SWIMLANE_TYPES.map((swimLaneType: SwimlaneType) =>
              user.role === RoleType.VENDOR && swimLaneType === SwimlaneType.INTAKE ? null : (
                <Swimlane
                  laneType={swimLaneType}
                  cards={filteredSwimLanes.get(swimLaneType) ?? []}
                  bundle={bundle}
                  key={swimLaneType}
                  activitiesAreLoaded={activitiesDataIsLoaded(dataState)}
                  user={user}
                />
              ),
            )}
          </section>
        )}
      </React.Fragment>
    </div>
  );
}

export default withBundle('pages.LandingPage')(LandingPage);
