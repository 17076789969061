import { MessageBundle } from '@amzn/arb-tools';
import { Issue, Task } from '../../data/RecordMetadata';
import { stateStrToEnum, severityStrToEnum, outcomeStrToEnum, tierStrToNum } from '../../utils/enumUtils';
import { FILTER_LABELS } from '../../constants/constants';

export type FilterActions = 'selectOption' | 'removeOption' | 'clear' | 'fuzzySearch' | 'fuzzySearchClear';

/* eslint-disable */
// TODO make these more specific types
export type DataActions =
  | { type: 'initialSetup'; payload: { labels?: string[]; bundle: MessageBundle; componentData: any[] } }
  | { type: FilterActions; payload: { label: string; filters: Array<string> } }
  | { type: 'fuzzySearch'; payload?: any }
  | { type: 'fuzzySearchClear'; payload?: any }
  | { type: 'reset' };
/* eslint-enable */

export interface FilterFields {
  labelToFilters: Map<FilterLabel, Array<string>>;
  labelToValueToOccurrences: Map<FilterLabel, Map<string, number>>;
}

interface FilterState {
  filtersLoading: boolean;
  labels?: string[];
  filterFields?: FilterFields;
}

const filterDropdownLabels: readonly string[] = [
  FILTER_LABELS.VENDOR,
  FILTER_LABELS.TIER,
  FILTER_LABELS.STATE,
  FILTER_LABELS.SEVERITY,
  FILTER_LABELS.ISSUE_STATE,
  FILTER_LABELS.ISSUE_SEVERITY,
  FILTER_LABELS.TASK_STATE,
  FILTER_LABELS.TASK_SEVERITY,
  FILTER_LABELS.OUTCOME,
];
export type FilterDropdownLabel = (typeof filterDropdownLabels)[number];
export function isFilterDropdownLabel(str: string): boolean {
  return filterDropdownLabels.includes(str);
}

const filterLabels: readonly string[] = [...filterDropdownLabels].concat(FILTER_LABELS.SEARCH);
export type FilterLabel = (typeof filterLabels)[number];

export function isFilterLabel(str: string): boolean {
  return filterLabels.includes(str);
}

function propertyValueGetter(label: string, data: any, bundle: any): string {
  switch (label) {
    case FILTER_LABELS.VENDOR:
      return !data.vendor ? data.name : data.vendor;
    case FILTER_LABELS.TIER:
      return bundle.formatMessage('tier_number', { tier: tierStrToNum(data.tier) });
    case FILTER_LABELS.STATE:
    case FILTER_LABELS.ISSUE_STATE:
    case FILTER_LABELS.TASK_STATE:
      return bundle.formatMessage('record_state', { state: stateStrToEnum(data.state) });
    case FILTER_LABELS.SEVERITY:
    case FILTER_LABELS.ISSUE_SEVERITY:
    case FILTER_LABELS.TASK_SEVERITY:
      return bundle.formatMessage('severity_state', { severity: severityStrToEnum(data.severity) });
    case FILTER_LABELS.OUTCOME:
      return bundle.formatMessage('outcome_status', {
        outcome: outcomeStrToEnum(data.assessmentOutcome),
      });
  }

  return '';
}

function fieldsMapper(
  labelToValueToOccurrences: Map<FilterLabel, Map<string, number>>,
  label: FilterLabel,
  labelValue: string,
) {
  if (!labelToValueToOccurrences.has(label)) labelToValueToOccurrences.set(label, new Map<string, number>());

  const valueToOccurrences = labelToValueToOccurrences.get(label)!;
  valueToOccurrences.set(labelValue, (valueToOccurrences.get(labelValue) ?? 0) + 1);
}

//fields in state is very dynamic depending on which component using it so intentionally kept it as any
function filterReducer(state: FilterState, action: DataActions): FilterState {
  if (action.type === 'reset') return { filtersLoading: true };

  const label = action.payload?.label;

  switch (action.type) {
    case 'initialSetup': {
      state = state ?? {};
      const labelToFilters = state.filterFields?.labelToFilters ?? new Map<FilterLabel, Array<FILTER_LABELS>>();
      //to keep track of count in case of repeated
      const labelToValueToOccurrences = new Map<FilterLabel, Map<string, number>>();
      state.filterFields = {
        labelToFilters: labelToFilters,
        labelToValueToOccurrences: labelToValueToOccurrences,
      };

      state.labels = action.payload.labels ?? [];
      for (const label of state.labels) {
        for (const data of action.payload.componentData) {
          let records: Issue[] | Task[] = [];
          if (label === FILTER_LABELS.ISSUE_STATE || label === FILTER_LABELS.ISSUE_SEVERITY) {
            if (data.issues) records = data.issues;
          }

          if (label === FILTER_LABELS.TASK_STATE || label === FILTER_LABELS.TASK_SEVERITY) {
            if (data.tasks) records = data.tasks;
          }

          if (records.length > 0) {
            records.forEach((record: Issue | Task) => {
              fieldsMapper(labelToValueToOccurrences, label, propertyValueGetter(label, record, action.payload.bundle));
            });
          } else {
            fieldsMapper(labelToValueToOccurrences, label, propertyValueGetter(label, data, action.payload.bundle));
          }
        }
      }
      state.filtersLoading = false;
      break;
    }
    case 'selectOption':
    case 'removeOption':
    case 'clear': {
      if (action.payload.filters.length === 0) state.filterFields!.labelToFilters.delete(label);
      else state.filterFields!.labelToFilters.set(label, action.payload.filters);
      break;
    }

    case 'fuzzySearch': {
      state.filterFields!.labelToFilters.set(
        FILTER_LABELS.SEARCH,
        action.payload.searchString
          .split(',')
          .filter((searchTerm: string) => searchTerm.trim().length > 0)
          .map((searchTerm: string) => searchTerm.trim().toLowerCase()),
      );
      break;
    }

    case 'fuzzySearchClear': {
      state.filterFields!.labelToFilters.delete(FILTER_LABELS.SEARCH);
      break;
    }
  }

  state.filterFields = { ...state.filterFields! }; // shallow copy to prompt state update
  return { ...state };
}

export default filterReducer;
