import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import { MessageBundle } from '@amzn/arb-tools';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import { styled } from '@mui/material/styles';
import MuiTypography from '@mui/material/Typography';
import MuiBox from '@mui/material/Box';
import MuiCollapse from '@mui/material/Collapse';
import MuiDivider from '@mui/material/Divider';
import MuiLaunchIcon from '@mui/icons-material/Launch';
import MuiLink from '@mui/material/Link';
import AccordionButton from '../../components/ReusableComponents/AccordionButton/AccordionButton';
import FavoritesStar from '../../components/FavoritesStar/FavoritesStar';
import LoadingSpinner from '../../components/LoadingComponents/LoadingSpinner';
import ProgressBar from '../../components/ReusableComponents/ProgressBar/ProgressBar';
import FilterBar from '../../components/FilterBar/FilterBar';
import filterReducer from '../../components/FilterBar/FilterReducer';
import Assessment, {
  AssessmentOutcomeStatus,
  Issue,
  Priority,
  RelatedLink,
  TPSRecordType,
} from '../../data/RecordMetadata';
import {
  BLACK,
  BLUE_BLACK,
  DARK_GRAY,
  RED,
  SECONDARY_BLUE,
  TERTIARY_BLUE,
  HOVER_EFFECT,
  BREAKPOINT,
  PAGE_TITLE,
  FILTER_LABELS,
} from '../../constants/constants';
import { useImpersonation, useUserContext } from '../../context/UserProvider';
import { RoleType, UserMetadata } from '../../data/UserMetadata';
import {
  stateStrToEnum,
  outcomeStrToEnum,
  severityStrToEnum,
  tierToEnum,
  outcomeLabelWithTextColor,
  getIssueOwnershipType,
} from '../../utils/enumUtils';
import getTPSPortalUrl from '../../utils/redirectionUtils';
import {
  areIssueFiltersApplied,
  areNonIssueTaskSearchFiltersApplied,
  getVisibleIssueTpsRecordIds,
  isIncludedInFilter,
  isSearchApplied,
  isSearchResult,
  isTptaSearchApplied,
} from '../../utils/filterUtils';
import { LoadState, useDataContext } from '../../context/DataProvider';
import {
  sortFavoritesFirst,
  sortAssessmentRecords,
  sortTasksOrIssues,
  getOverallRecordsSev,
} from '../../utils/sortingUtils';
import { getIssueLabelColor, getLoadState, isIssueActionable, issueDataIsLoaded } from '../../utils/dataUtils';
import { SnowUrlType } from '../../constants/urlConstants';
import './IssuesPage.css';

const OutcomeStatusLabel = styled(MuiTypography)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '13px',
  borderRadius: '20px',
  height: '30px',
  width: '200px',
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '9px',
    height: '20px',
    width: '120px',
  },
}));

const StateLabel = styled(MuiTypography)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '11px',
  borderRadius: '20px',
  width: '200px',
  height: '25px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '7px',
    height: '15px',
    width: '130px',
  },
}));

const TableHeaderText = styled(MuiTypography)(({ theme }) => ({
  fontSize: '18px',
  padding: '6px',
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '13px',
    padding: '4px',
  },
}));

const CellText = styled(MuiTypography)(({ theme }) => ({
  fontSize: '14px',
  padding: '6px',
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '11px',
    padding: '4px',
  },
}));

const Divider = styled(MuiDivider)({
  margin: '20px 0',
});

const VendorText = styled(MuiTypography)(({ theme }) => ({
  color: SECONDARY_BLUE,
  fontSize: '18px',
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '12px',
  },
}));

const TptaTierText = styled(MuiTypography)(({ theme }) => ({
  color: DARK_GRAY,
  fontSize: '14px',
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '11px',
  },
}));

const ProjectNameText = styled(MuiTypography)(({ theme }) => ({
  color: TERTIARY_BLUE,
  fontSize: '14px',
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '11px',
  },
}));

const SeverityText = styled(MuiTypography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 'bold',
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '11px',
  },
}));

const IssueTpriText = styled(MuiTypography)(({ theme }) => ({
  color: DARK_GRAY,
  '&:hover': HOVER_EFFECT,
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '11px',
  },
}));

const VendorTextLink = styled(MuiLink)({
  color: SECONDARY_BLUE,
  '&:hover': HOVER_EFFECT,
});

/**
 * Returns the JSX for the Shepherd box link. The reason
 * we want this function separate is because we want to
 * display a black box if there is no related link.
 * Not all issues have shepherd links, but all requesters
 * should see this column, even if it's empty.
 *
 * @param links
 * @returns JSX
 */
interface ShepherdLinkProps {
  links: RelatedLink[];
}
function ShepherdLink({ links }: ShepherdLinkProps): React.ReactElement {
  if (links.length < 1) return <></>;

  const shepherdLinks = links.filter((link: RelatedLink) => link.siteName.toLowerCase().includes('shepherd'));

  if (shepherdLinks.length < 1) return <></>;

  return (
    <MuiLink
      {...{
        component: Link,
        to: shepherdLinks[0].url,
        target: '_blank',
      }}
      underline="none"
    >
      <MuiLaunchIcon
        color="primary"
        sx={{
          '@media screen and (max-width: 1536px)': {
            width: '13px',
            height: '13px',
          },
        }}
      />
    </MuiLink>
  );
}

interface OverallSeverityStateProps extends WithBundleProps {
  assessment: Assessment;
}
function OverallSeverityState({ bundle, assessment }: OverallSeverityStateProps): React.ReactElement {
  if (assessment.assessmentOutcome) {
    if (
      outcomeStrToEnum(assessment.assessmentOutcome) === AssessmentOutcomeStatus.BAR_MET ||
      outcomeStrToEnum(assessment.assessmentOutcome) === AssessmentOutcomeStatus.BAR_MET_EXCEPTIONS
    ) {
      return (
        <SeverityText>
          {bundle.formatMessage('overall_severity', {
            status: 'ready',
          })}
        </SeverityText>
      );
    }
  }

  const sev = getOverallRecordsSev(assessment.issues!);
  const status = sev === Priority.HIGH ? 'blocked' : sev;
  const styling = sev === Priority.HIGH ? { color: RED } : {};
  return (
    <SeverityText sx={styling}>
      {bundle.formatMessage('overall_severity', {
        status: status,
      })}
    </SeverityText>
  );
}

interface SeverityStateProps extends WithBundleProps {
  issue: Issue;
}
function SeverityState({ bundle, issue }: SeverityStateProps): React.ReactElement {
  return severityStrToEnum(issue.severity) === Priority.HIGH ? (
    <CellText sx={{ color: RED }} className="issue-table-severity">
      {bundle.formatMessage('severity_state', {
        severity: 'blocked',
      })}
    </CellText>
  ) : (
    <CellText className="issue-table-severity">
      {bundle.formatMessage('severity_state', {
        severity: severityStrToEnum(issue.severity),
      })}
    </CellText>
  );
}

interface IssuesTableProps extends WithBundleProps {
  assessment: Assessment;
  user: UserMetadata;
  bundle: MessageBundle;
  visibleIssueTpsRecordIds: Set<string>;
  expanded: boolean;
}
/**
 * A helper component that can be looped over for a variable
 * amount of Vendors with various issues.
 */
function IssuesTable({ bundle, ...props }: IssuesTableProps): React.ReactElement {
  const [expanded, setExpanded] = useState(props.expanded);
  const { impersonationIntent } = useImpersonation();

  useEffect(() => {
    if (expanded !== props.expanded) setExpanded(props.expanded);
  }, [props.expanded, props.visibleIssueTpsRecordIds]);
  const handleExpand = () => setExpanded(!expanded);

  return (
    <React.Fragment key={props.assessment.tpsRecordId}>
      <MuiBox className={`issue-collapse-container issue-collapse-container-${props.user.role}`}>
        <MuiBox className="issue-accordion-favorites-container">
          <AccordionButton expanded={expanded} onClick={handleExpand} hasarrow />
          <FavoritesStar style={{ padding: '3px 4px 0px' }} assessment={props.assessment} />
          <AccordionButton expanded={expanded} onClick={handleExpand}>
            <React.Fragment key={props.assessment.tpsRecordId}>
              <VendorText>{!props.assessment.vendor ? props.assessment.name : props.assessment.vendor}</VendorText>
              <TptaTierText>
                {bundle.formatMessage('tpta_tier', {
                  tptaNum: !props.assessment.tpsRecordId ? '' : props.assessment.tpsRecordId,
                  tier: !props.assessment.tier ? '' : tierToEnum(String(props.assessment.tier)),
                  link: (children: string) => (
                    <VendorTextLink
                      {...{
                        component: Link,
                        to: getTPSPortalUrl({
                          id: props.assessment.tpsRecordSystemId,
                          linkType: SnowUrlType.ASSESSMENT,
                          userRole: props.user.role,
                          impersonationIntent,
                        }),
                      }}
                      underline="none"
                      key={props.assessment.tpsRecordSystemId}
                    >
                      {children}
                    </VendorTextLink>
                  ),
                })}
              </TptaTierText>
              <ProjectNameText>{!props.assessment.name ? '' : props.assessment.name}</ProjectNameText>
            </React.Fragment>
          </AccordionButton>
        </MuiBox>
        {props.user.role === RoleType.REQUESTER && <MuiBox></MuiBox>}
        <MuiBox>
          <OverallSeverityState
            bundle={bundle}
            assessment={props.assessment}
            key={props.assessment.tpsRecordSystemId}
          />
        </MuiBox>
        <MuiBox>
          {props.assessment.assessmentOutcome !== undefined && (
            <OutcomeStatusLabel sx={outcomeLabelWithTextColor(outcomeStrToEnum(props.assessment.assessmentOutcome))}>
              {bundle.formatMessage('outcome_status', {
                outcome: outcomeStrToEnum(props.assessment.assessmentOutcome),
              })}
            </OutcomeStatusLabel>
          )}
        </MuiBox>
        <MuiBox>
          <VendorText>
            {bundle.formatMessage('open_issues_count', {
              issueCount: props.assessment.issueCount!,
            })}
          </VendorText>
        </MuiBox>
      </MuiBox>
      <MuiCollapse in={expanded} timeout="auto" unmountOnExit>
        <MuiBox role="table" className="issues-table-container">
          <MuiBox role="rowheader" className={`issue-table-row issue-header-row issue-table-row-${props.user.role}`}>
            <MuiBox role="columnheader">
              <TableHeaderText>{bundle.getMessage('issue_title_header')}</TableHeaderText>
            </MuiBox>
            {props.user.role === RoleType.REQUESTER && (
              <MuiBox role="columnheader">
                <TableHeaderText>{bundle.getMessage('remediation_owner')}</TableHeaderText>
              </MuiBox>
            )}
            <MuiBox role="columnheader">
              <TableHeaderText>{bundle.getMessage('severity_header')}</TableHeaderText>
            </MuiBox>
            <MuiBox role="columnheader">
              <TableHeaderText>{bundle.getMessage('state_header')}</TableHeaderText>
            </MuiBox>
            <MuiBox role="columnheader">
              <TableHeaderText>{bundle.getMessage('remediation_timeline_header')}</TableHeaderText>
            </MuiBox>
            {props.user.role === RoleType.REQUESTER && (
              <MuiBox role="columnheader">
                <TableHeaderText>{bundle.getMessage('shepherd_link_header')}</TableHeaderText>
              </MuiBox>
            )}
          </MuiBox>
          {props.assessment.issues !== undefined &&
            props.assessment.issues.length > 0 &&
            props.assessment.issues
              .filter((issue: Issue) => props.visibleIssueTpsRecordIds.has(issue.tpsRecordId))
              .sort((a, b) => sortTasksOrIssues(props.assessment, props.user, a, b))
              .map((issue: Issue) => {
                return (
                  <MuiBox
                    role="row"
                    className={`issue-table-row issue-table-row-${props.user.role}`}
                    key={issue.tpsRecordId}
                  >
                    <MuiBox role="cell">
                      <MuiLink
                        {...{
                          component: Link,
                          to: getTPSPortalUrl({
                            id: issue.tpsRecordSystemId,
                            linkType: SnowUrlType.ISSUE,
                            userRole: props.user.role,
                            impersonationIntent,
                          }),
                        }}
                        underline="none"
                        color="inherit"
                      >
                        <MuiBox className="issue-table-project-name">
                          <MuiTypography
                            sx={{
                              color: isIssueActionable(issue, props.user) ? RED : BLUE_BLACK,
                              '&:hover': HOVER_EFFECT,
                              '@media screen and (max-width: 1376px)': {
                                fontSize: '11px',
                              },
                            }}
                          >
                            {issue.title ? issue.title : bundle.getMessage('no_issue_title')}
                          </MuiTypography>
                          <IssueTpriText>{issue.tpsRecordId}</IssueTpriText>
                        </MuiBox>
                      </MuiLink>
                    </MuiBox>
                    {props.user.role === RoleType.REQUESTER && (
                      <MuiBox role="cell" className="issue-table-shepherd-container">
                        <CellText sx={{ color: BLACK }} key={issue.issueOwnership}>
                          {bundle.getMessage(getIssueOwnershipType(issue))}
                        </CellText>
                      </MuiBox>
                    )}
                    <MuiBox role="cell" className="issue-table-severity-container">
                      <SeverityState bundle={bundle} issue={issue} key={issue.tpsRecordSystemId} />
                    </MuiBox>
                    <MuiBox role="cell" className="issue-table-state-container">
                      <StateLabel sx={getIssueLabelColor(issue, props.user)}>
                        {bundle.formatMessage('record_state', {
                          state: stateStrToEnum(issue.state),
                        })}
                      </StateLabel>
                    </MuiBox>
                    <MuiBox role="cell" className="issue-table-timeline-container">
                      {issue.remediationSchedule &&
                      issue.remediationSchedule.start &&
                      issue.remediationSchedule.dueDate ? (
                        <ProgressBar
                          from={issue.remediationSchedule.start}
                          to={issue.remediationSchedule.dueDate}
                          text={bundle.formatMessage('remediation_timeline', {
                            startDate: new Date(0).setUTCSeconds(Number(issue.remediationSchedule.start)),
                            endDate: new Date(0).setUTCSeconds(Number(issue.remediationSchedule.dueDate)),
                          })}
                          key={issue.tpsRecordSystemId}
                        />
                      ) : issue.remediationSchedule && !issue.remediationSchedule.start ? (
                        <CellText sx={{ color: BLACK }} key={issue.tpsRecordSystemId}>
                          {bundle.getMessage('no_start_date_error')}
                        </CellText>
                      ) : (
                        <CellText sx={{ color: BLACK }} key={issue.tpsRecordSystemId}>
                          {bundle.getMessage('no_due_date_error')}
                        </CellText>
                      )}
                    </MuiBox>
                    {props.user.role === RoleType.REQUESTER && (
                      <MuiBox role="cell" className="issue-table-shepherd-container">
                        <CellText className="issue-table-shepherd" key={issue.tpsRecordSystemId}>
                          <ShepherdLink links={issue.relatedLinks} />
                        </CellText>
                      </MuiBox>
                    )}
                  </MuiBox>
                );
              })}
        </MuiBox>
      </MuiCollapse>
      <Divider variant="fullWidth" />
    </React.Fragment>
  );
}

function IssuesPage({ bundle }: WithBundleProps): React.ReactElement {
  const user = useUserContext();
  const [filterState, dispatch] = useReducer(filterReducer, { filtersLoading: true });
  const [dataState] = useDataContext();
  const nonEmptyAssessments = useMemo(
    () => dataState.assessments.filter((assessment) => assessment.issueCount && assessment.issueCount > 0),
    [dataState],
  );

  // useEffect for initial state to contain filtermap, label sets at component level
  useEffect(() => {
    if (!issueDataIsLoaded(dataState)) {
      if (!filterState.filtersLoading) {
        dispatch({ type: 'reset' });
      }
      return;
    }

    dispatch({
      type: 'initialSetup',
      payload: {
        labels: [FILTER_LABELS.VENDOR, FILTER_LABELS.TIER, FILTER_LABELS.ISSUE_STATE, FILTER_LABELS.ISSUE_SEVERITY],
        bundle,
        componentData: nonEmptyAssessments,
      },
    });
  }, [filterState.filtersLoading, dataState]);

  return (
    <div className="issues-page-container">
      {filterState.filtersLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <FilterBar
            filterFields={filterState.filterFields!}
            filterLabels={filterState.labels!}
            dispatch={dispatch}
            title={PAGE_TITLE.ISSUES_PAGE}
          />
        </>
      )}

      <MuiBox className="issues-assessment-container">
        {issueDataIsLoaded(dataState) && !filterState.filtersLoading && nonEmptyAssessments.length === 0 && (
          <MuiBox>{bundle.getMessage('no_issue_records')}</MuiBox>
        )}
        {getLoadState(dataState, TPSRecordType.ISSUE) === LoadState.LOADING && <LoadingSpinner />}
        {getLoadState(dataState, TPSRecordType.ISSUE) === LoadState.SUCCESS &&
          !filterState.filtersLoading &&
          sortFavoritesFirst(
            nonEmptyAssessments.sort((aAssessment, bAssessment) =>
              sortAssessmentRecords(user)(aAssessment, bAssessment, TPSRecordType.ISSUE),
            ),
          ).map((assessment: Assessment) => {
            const empty = <React.Fragment key={assessment.tpsRecordSystemId}></React.Fragment>;
            if (!assessment.issues!.length) return empty; //remove assessments that have empty issues

            let visibleIssueTpsRecordIds: Set<string>;
            const filterQuery = {
              vendor: assessment.vendor,
              tier: bundle.formatMessage('tier_number', { tier: assessment.tier }),
              search: [
                assessment.tpsRecordId,
                assessment.name,
                bundle.formatMessage('outcome_status', {
                  outcome: outcomeStrToEnum(assessment.assessmentOutcome || ''),
                }),
              ],
            };

            let isTptaSearchResult = false;
            if (filterState.filterFields!.labelToFilters.size > 0) {
              if (isTptaSearchApplied(filterState.filterFields?.labelToFilters)) {
                if (!isSearchResult(filterQuery, filterState.filterFields!.labelToFilters)) return empty;
                else isTptaSearchResult = true;
              }
              if (
                areNonIssueTaskSearchFiltersApplied(filterState.filterFields?.labelToFilters) &&
                !isIncludedInFilter(filterQuery, filterState.filterFields!.labelToFilters)
              ) {
                return empty; // assessment was filtered out
              }
              // assessment survived filtering, get all issues
              visibleIssueTpsRecordIds = getVisibleIssueTpsRecordIds(
                filterState.filterFields!.labelToFilters,
                assessment.issues!,
                bundle,
              );
            } else {
              // no filter is applied thus all issues are visible
              visibleIssueTpsRecordIds = new Set(assessment.issues!.map((issue) => issue.tpsRecordId));
            }

            let visible = visibleIssueTpsRecordIds.size > 0;
            if (!visible) {
              visible =
                isSearchApplied(filterState.filterFields!.labelToFilters) &&
                (isTptaSearchResult || isSearchResult(filterQuery, filterState.filterFields!.labelToFilters));
              if (visible && !areIssueFiltersApplied(filterState.filterFields?.labelToFilters)) {
                // The only match is from the TPTA section
                visibleIssueTpsRecordIds = new Set(assessment.issues!.map((issue) => issue.tpsRecordId));
              }
            }

            return visible ? (
              <IssuesTable
                assessment={assessment}
                bundle={bundle}
                user={user}
                key={assessment.tpsRecordId}
                visibleIssueTpsRecordIds={visibleIssueTpsRecordIds}
                expanded={
                  (areIssueFiltersApplied(filterState.filterFields?.labelToFilters) ||
                    isSearchApplied(filterState.filterFields?.labelToFilters)) &&
                  visibleIssueTpsRecordIds.size > 0
                }
              />
            ) : (
              empty
            );
          })}
      </MuiBox>
    </div>
  );
}

export default withBundle('pages.IssuesPage')(IssuesPage);
