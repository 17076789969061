import Assessment, { Priority, Issue, Task, SortResult, TPSRecordType } from '../data/RecordMetadata';
import { severityStrToEnum } from './enumUtils';
import { UserMetadata } from '../data/UserMetadata';
import { isClosedCompleteNa, isIssueActionable, isTaskActionable, isIssueRecord } from './dataUtils';

// See this link for how compare (& sort) functions work:
// https://tiny.amazon.com/rtg7y31y/devemoziorgenUSdocsWebJava

/**
 * We specifically sort in this fashion to preserve other
 * sorting logic that may have been applied. Using 2 separate lists,
 * we can apply the same sorting logic to both the favorited, and
 * unfavorited.
 *
 * @param assessments
 * @returns Assessment[]
 */
export function sortFavoritesFirst(assessments: Assessment[]): Assessment[] {
  const sorted: Assessment[] = [];
  assessments.forEach((assessment) => {
    if (assessment.isFavorited) sorted.push(assessment);
  });
  assessments.forEach((assessment) => {
    if (!assessment.isFavorited) sorted.push(assessment);
  });

  return sorted;
}

/**
 * Always searches for and returns the highest severity,
 * also known as Priority, item.
 *
 * @param records Issue or Task record(s)
 * @returns Priority
 */
export function getOverallRecordsSev(records: (Issue | Task)[]): Priority {
  const sevMap = new Map<Priority, number>([
    [Priority.HIGH, 3],
    [Priority.MEDIUM, 2],
    [Priority.LOW, 1],
    [Priority.NONE, 0],
  ]);

  const highestSevMap = new Map<number, Priority>([
    [3, Priority.HIGH],
    [2, Priority.MEDIUM],
    [1, Priority.LOW],
    [0, Priority.NONE],
  ]);

  let highest = 0;
  let sev: Priority;
  records &&
    records.forEach((record: Issue | Task) => {
      if (highest === 3) return;
      sev = severityStrToEnum(record.severity);

      if (highest < sevMap.get(sev)!) highest = sevMap.get(sev)!;
    });

  return highestSevMap.get(highest)!;
}

function sortRecordsBySeverity(record1Severity: string, record2Severity: string): SortResult {
  const aSev = severityStrToEnum(record1Severity);
  const bSev = severityStrToEnum(record2Severity);

  // A is high
  if (aSev === Priority.HIGH && bSev === Priority.HIGH) return SortResult.NO_RECORD_WINS;
  if (aSev === Priority.HIGH && bSev === Priority.MEDIUM) return SortResult.FIRST_RECORD_WINS;
  if (aSev === Priority.HIGH && bSev === Priority.LOW) return SortResult.FIRST_RECORD_WINS;

  // A is medium
  if (aSev === Priority.MEDIUM && bSev === Priority.MEDIUM) return SortResult.NO_RECORD_WINS;
  if (aSev === Priority.MEDIUM && bSev === Priority.HIGH) return SortResult.SECOND_RECORD_WINS;
  if (aSev === Priority.MEDIUM && bSev === Priority.LOW) return SortResult.FIRST_RECORD_WINS;

  // A is low
  if (aSev === Priority.LOW && bSev === Priority.LOW) return SortResult.NO_RECORD_WINS;
  if (aSev === Priority.LOW && bSev === Priority.HIGH) return SortResult.SECOND_RECORD_WINS;
  if (aSev === Priority.LOW && bSev === Priority.MEDIUM) return SortResult.SECOND_RECORD_WINS;

  return SortResult.NO_RECORD_WINS;
}

const getMinimumDate = (previousDate: number, record: Issue | Task) => {
  const recordType = isIssueRecord(record);
  return Math.min(
    previousDate,
    recordType
      ? Number(
          record.remediationSchedule && record.remediationSchedule.dueDate
            ? record.remediationSchedule.dueDate
            : 9999999999,
        )
      : Number(record.dueDate ? record.dueDate : 9999999999),
  );
};
//sorting helper function for most past due record(s)
const sortRecordsByDueDate = (aRecords: (Issue | Task)[], bRecords: (Issue | Task)[]) => {
  // setting date to effectively infinity in seconds, this will work for over 250 years
  let aDate = 9999999999;
  let bDate = 9999999999;

  // Getting past dueDate of highest priority record(s)
  aRecords &&
    aRecords.forEach((record) => {
      //getting most most past due bRecord
      aDate = getMinimumDate(aDate, record);
    }); //Bad data conditions when no dueDate for record added 9999999999 as it's date

  bRecords &&
    bRecords.forEach((record) => {
      //getting most most past due bRecord
      bDate = getMinimumDate(bDate, record);
    });

  // The past dueDate record is prioritized first
  if (aDate < bDate) return SortResult.FIRST_RECORD_WINS;
  if (aDate > bDate) return SortResult.SECOND_RECORD_WINS;

  return SortResult.NO_RECORD_WINS;
};

/**
 * Based off BRD criteria on (08/07/23)
 * Sorts the tasks based off the following criteria
 * TPTAs will be sorted by:
 *  1) Favorited
 *  2) Has actionable record(s)
 *    - 1st tiebreaker: both assessments has actionable record(s) then assessment with highest overall severity comes first
 *    - 2nd tiebreaker: both assessments overall highest severity then assessment that contains most past due highest severity child record comes first
 *  3) Has open record(s)
 *    - 1st tiebreaker: both assessments has different overall highest severity then assessment with highest overall severity comes first
 *    - 2nd tiebreaker: both assessments has same overall highest severity then assessment that contains most past due highest severity child record comes first
 */
export function sortAssessmentRecords(user: UserMetadata) {
  return (a: Assessment, b: Assessment, sortOn: TPSRecordType): SortResult => {
    const aRecords: (Issue | Task)[] = sortOn === TPSRecordType.ISSUE ? a.issues! : a.tasks!;
    const bRecords: (Issue | Task)[] = sortOn === TPSRecordType.ISSUE ? b.issues! : b.tasks!;

    // Initially Checking edge cases
    //a. If only one Assessment has record(s)
    if ((aRecords && aRecords.length === 0) !== (bRecords && bRecords.length === 0)) {
      if (aRecords && aRecords.length !== 0) return SortResult.FIRST_RECORD_WINS; //if aAssessment had record it has to come first in the orders
      return SortResult.SECOND_RECORD_WINS;
    }
    //b. both assessment has missing record(s)
    if (aRecords && aRecords.length === 0 && bRecords && bRecords.length === 0) return SortResult.NO_RECORD_WINS;

    //2. Has actionable record(s)
    const aActionableRecords: (Issue | Task)[] = aRecords
      ? aRecords.filter((record) =>
          isIssueRecord(record) ? isIssueActionable(record, user) : isTaskActionable(record, user),
        )
      : []; //verify type of record and applying actionability reason only Issue Record(s) had dependency of actionability on Assessment
    const bActionableRecords: (Issue | Task)[] = bRecords
      ? bRecords.filter((record) =>
          isIssueRecord(record) ? isIssueActionable(record, user) : isTaskActionable(record, user),
        )
      : [];

    //a. Check if only one has actionable record(s)
    if ((aActionableRecords.length === 0) !== (bActionableRecords.length === 0)) {
      if (aActionableRecords.length !== 0) return SortResult.FIRST_RECORD_WINS;
      return SortResult.SECOND_RECORD_WINS;
    }

    //b. both has actionable record(s)
    if (aActionableRecords.length !== 0 && bActionableRecords.length !== 0) {
      //get highest severity of actionable child records under an Assessment
      const aSev: Priority = getOverallRecordsSev(aActionableRecords!);
      const bSev: Priority = getOverallRecordsSev(bActionableRecords!);

      //1st tiebreaker: both assessments has actionable record(s) then assessment with highest overall severity comes first
      if (aSev !== bSev) {
        return sortRecordsBySeverity(aSev, bSev);
      }

      //2nd tiebreaker: both assessments overall highest severity then assessment that contains past due dated highest severity child record comes first
      return sortRecordsByDueDate(
        aActionableRecords!.filter((record) => severityStrToEnum(record.severity) === aSev),
        bActionableRecords!.filter((record) => severityStrToEnum(record.severity) === aSev),
      );
    }

    //3. Has open record(s)
    const aOpenRecords: (Issue | Task)[] = aRecords
      ? aRecords.filter((record) => isClosedCompleteNa(record.state))
      : [];
    const bOpenRecords: (Issue | Task)[] = bRecords
      ? bRecords.filter((record) => isClosedCompleteNa(record.state))
      : [];

    //a. Check if only one has open record(s)
    if ((aOpenRecords.length === 0) !== (bOpenRecords.length === 0)) {
      if (aOpenRecords.length !== 0) return SortResult.FIRST_RECORD_WINS;
      return SortResult.SECOND_RECORD_WINS;
    }

    //b. both have open records
    if (aOpenRecords.length !== 0 && bOpenRecords.length !== 0) {
      //get highest severity of open child records under an Assessment
      const aSev = getOverallRecordsSev(aOpenRecords!);
      const bSev = getOverallRecordsSev(bOpenRecords!);

      //1st tiebreaker: both assessments has different overall highest severity then assessment with highest overall severity comes first
      if (aSev !== bSev) {
        return sortRecordsBySeverity(aSev, bSev);
      }

      //2nd tiebreaker: both assessments has same overall highest severity then assessment that contains past due dated highest severity child record comes first
      return sortRecordsByDueDate(
        aOpenRecords!.filter((record) => severityStrToEnum(record.severity) === aSev),
        bOpenRecords!.filter((record) => severityStrToEnum(record.severity) === aSev),
      );
    }

    return SortResult.NO_RECORD_WINS;
  };
}

/**
 * Based on requirement specified in this link https://tiny.amazon.com/1h831zgc3/simamazissuTPSE
 * Sorts the record(s) based off the following criteria
 * Issue or Task Record(s) will be sorted by:
 *  1) Actionable
 *  2) Severity
 *  3) most past due Date
 */
export function sortTasksOrIssues(
  assessment: Assessment,
  user: UserMetadata,
  record1: Issue | Task,
  record2: Issue | Task,
): SortResult {
  const record1Actionable: boolean = isIssueRecord(record1)
    ? isIssueActionable(record1, user)
    : isTaskActionable(record1, user); //only Issue Record(s) actionability dependent on it's associated Assessment
  const record2Actionable: boolean = isIssueRecord(record2)
    ? isIssueActionable(record2, user)
    : isTaskActionable(record2, user);

  //1) Actionable - if only one record is actionable
  if ((record1Actionable && !record2Actionable) || (!record1Actionable && record2Actionable)) {
    if (record1Actionable) return SortResult.FIRST_RECORD_WINS;
    if (record2Actionable) return SortResult.SECOND_RECORD_WINS;
  }

  //2) Severity - record(s) both actionable or both not actionable the record with highest severity comes first
  const recordsSeverityResult: SortResult = sortRecordsBySeverity(record1.severity!, record2.severity!);

  if (recordsSeverityResult !== SortResult.NO_RECORD_WINS) return recordsSeverityResult;

  //3) nearest due date (or most past due) - both actionable or both not actionable with same severity most past dueDate record comes first
  return sortRecordsByDueDate([record1], [record2]);
}
