import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import { MessageBundle } from '@amzn/arb-tools';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import { styled } from '@mui/material/styles';
import MuiTypography from '@mui/material/Typography';
import MuiBox from '@mui/material/Box';
import MuiCollapse from '@mui/material/Collapse';
import MuiDivider from '@mui/material/Divider';
import MuiLink from '@mui/material/Link';
import AccordionButton from '../../components/ReusableComponents/AccordionButton/AccordionButton';
import FavoritesStar from '../../components/FavoritesStar/FavoritesStar';
import LoadingSpinner from '../../components/LoadingComponents/LoadingSpinner';
import FilterBar from '../../components/FilterBar/FilterBar';
import filterReducer from '../../components/FilterBar/FilterReducer';
import Assessment, { Task, TPSRecordType } from '../../data/RecordMetadata';
import { HOVER_EFFECT, BREAKPOINT, PAGE_TITLE, FILTER_LABELS } from '../../constants/constants';
import { useImpersonation, useUserContext } from '../../context/UserProvider';
import { UserMetadata } from '../../data/UserMetadata';
import { stateStrToEnum, severityStrToEnum, tierToEnum } from '../../utils/enumUtils';
import getTPSPortalUrl from '../../utils/redirectionUtils';
import {
  areTaskFiltersApplied,
  areNonIssueTaskSearchFiltersApplied,
  getVisibleTaskTpsRecordIds,
  isIncludedInFilter,
  isSearchApplied,
  isSearchResult,
  isTptaSearchApplied,
} from '../../utils/filterUtils';
import { SnowUrlType } from '../../constants/urlConstants';
import { LoadState, useDataContext } from '../../context/DataProvider';
import { sortFavoritesFirst, sortAssessmentRecords, sortTasksOrIssues } from '../../utils/sortingUtils';
import { getTaskLabelColor, isTaskActionable, getLoadState, taskDataIsLoaded } from '../../utils/dataUtils';
import { tasks } from './tasksTestData';

const AssessmentDetailsOuterContainer = styled(MuiBox)({
  display: 'grid',
  width: '95%',
  padding: '6px',
  gridTemplateColumns: '47%',
  '& > *:not(:first-of-type)': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
});

const AssessmentDetailsInnerContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  marginTop: '20px',
}));

const RecordContainer = styled(MuiBox)(({ theme }) => ({
  display: 'grid',
  width: '95%',
  margin: '20px 0px',
  borderBottom: `1px ${theme.palette.info.dark} solid`,
  color: theme.palette.info.dark,
  '& > div:last-child': {
    borderBottomColor: 'transparent',
  },
}));

const StateLabel = styled(MuiTypography)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '11px',
  borderRadius: '20px',
  width: '200px',
  height: '25px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  [theme.breakpoints.down(BREAKPOINT.MAPMediumResolution)]: {
    fontSize: '9px',
    height: '18px',
    width: '150px',
  },
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '7px',
    height: '15px',
    width: '130px',
  },
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumZoomResolution)]: {
    fontSize: '6px',
    height: '15px',
    width: '120px',
  },
}));

const TableRowContainer = styled(MuiBox)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '47% 13% 17% 23%',
  '& > div': {
    border: `1px ${theme.palette.info.dark} solid`,
    borderBottom: 'none',
    borderLeft: 'none',
    fontSize: '14px',
  },
  '& > div:first-child': {
    borderLeft: `1px ${theme.palette.info.dark} solid`,
  },
}));

const TableHeaderText = styled(MuiTypography)(({ theme }) => ({
  fontSize: '17px',
  padding: '6px',
  [theme.breakpoints.down(BREAKPOINT.MAPMediumResolution)]: {
    fontSize: '15px',
    padding: '5px',
  },
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '13px',
    padding: '4px',
  },
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumZoomResolution)]: {
    fontSize: '11px',
    padding: '3px',
  },
}));

const CellText = styled(MuiTypography)(({ theme }) => ({
  fontSize: '14px',
  padding: '6px',
  [theme.breakpoints.down(BREAKPOINT.MAPMediumResolution)]: {
    fontSize: '12px',
    padding: '5px',
  },
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '10px',
    padding: '4px',
  },
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumZoomResolution)]: {
    fontSize: '8px',
    padding: '3px',
  },
}));

const Divider = styled(MuiDivider)({
  margin: '20px 0',
});

const VendorName = styled(MuiTypography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '18px',
  [theme.breakpoints.down(BREAKPOINT.MAPMediumResolution)]: {
    fontSize: '14px',
  },
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '12px',
  },
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumZoomResolution)]: {
    fontSize: '10px',
  },
}));

const RecordDetails = styled(MuiTypography)(({ theme }) => ({
  color: theme.palette.info.dark,
  fontSize: '14px',
  [theme.breakpoints.down(BREAKPOINT.MAPMediumResolution)]: {
    fontSize: '13px',
  },
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '11px',
  },
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumZoomResolution)]: {
    fontSize: '9px',
  },
}));

const AssessmentName = styled(MuiTypography)(({ theme }) => ({
  color: theme.palette.primary.light,
  fontSize: '14px',
  [theme.breakpoints.down(BREAKPOINT.MAPMediumResolution)]: {
    fontSize: '13px',
  },
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '11px',
  },
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumZoomResolution)]: {
    fontSize: '9px',
  },
}));

const TaskDetails = styled(MuiTypography)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '6px',
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    padding: '2px',
  },
}));

const TaskDetailContainer = styled(MuiBox)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
});

type TaskTitleProps = {
  assessment: Assessment;
  task: Task;
  user: UserMetadata;
};

const TaskTitle = styled(MuiTypography)<TaskTitleProps>(({ theme, task, user }) => ({
  fontSize: '14px',
  color: isTaskActionable(task, user) ? theme.palette.error.main : theme.palette.primary.backDrop,
  '&:hover': HOVER_EFFECT,
  [theme.breakpoints.down(BREAKPOINT.MAPMediumResolution)]: {
    fontSize: '12px',
    padding: '2px',
  },
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '10px',
    padding: '1px',
  },
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumZoomResolution)]: {
    fontSize: '9px',
    padding: '0.5px',
  },
}));

const TaskRecordText = styled(MuiTypography)(({ theme }) => ({
  fontSize: '14px',
  color: theme.palette.info.dark,
  '&:hover': HOVER_EFFECT,
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '10px',
    padding: '1px',
  },
}));

const VendorTextLink = styled(MuiLink)(({ theme }) => ({
  color: theme.palette.primary.dark,
  '&:hover': HOVER_EFFECT,
}));

interface TasksTableProps extends WithBundleProps {
  assessment: Assessment;
  user: UserMetadata;
  bundle: MessageBundle;
  visibleTaskTpsRecordIds: Set<string>;
  expanded: boolean;
}
/**
 * A helper component that can be looped over for a variable
 * amount of Vendors with various tasks.
 */
export function TasksTable({ bundle, ...props }: TasksTableProps): React.ReactElement {
  const [expanded, setExpanded] = useState(props.expanded);
  const { impersonationIntent } = useImpersonation();

  useEffect(() => {
    if (expanded !== props.expanded) setExpanded(props.expanded);
  }, [props.expanded, props.visibleTaskTpsRecordIds]);
  const handleExpand = () => setExpanded(!expanded);

  return (
    <React.Fragment key={props.assessment.tpsRecordId}>
      <AssessmentDetailsOuterContainer className="tasks-table-outer-container">
        <AssessmentDetailsInnerContainer className="tasks-table-inner-container">
          <AccordionButton expanded={expanded} onClick={handleExpand} hasarrow />
          <FavoritesStar style={{ padding: '3px 4px 0px' }} assessment={props.assessment} />
          <AccordionButton expanded={expanded} onClick={handleExpand}>
            <React.Fragment key={props.assessment.tpsRecordId}>
              <VendorName className="tasks-assessment-vendor-name">
                {!props.assessment.vendor ? props.assessment.name : props.assessment.vendor}
              </VendorName>
              <RecordDetails className="tasks-assessment-record-details">
                {bundle.formatMessage('tpta_tier', {
                  tptaNum: !props.assessment.tpsRecordId ? '' : props.assessment.tpsRecordId,
                  tier: !props.assessment.tier ? '' : tierToEnum(String(props.assessment.tier)),
                  link: (children: string) => (
                    <VendorTextLink
                      {...{
                        component: Link,
                        to: getTPSPortalUrl({
                          id: props.assessment.tpsRecordSystemId,
                          linkType: SnowUrlType.ASSESSMENT,
                          userRole: props.user.role,
                          impersonationIntent,
                        }),
                      }}
                      underline="none"
                      key={props.assessment.tpsRecordSystemId}
                    >
                      {children}
                    </VendorTextLink>
                  ),
                })}
              </RecordDetails>
              <AssessmentName className="tasks-page-assessment-name">
                {!props.assessment.name ? '' : props.assessment.name}
              </AssessmentName>
            </React.Fragment>
          </AccordionButton>
        </AssessmentDetailsInnerContainer>
      </AssessmentDetailsOuterContainer>
      <MuiCollapse in={expanded} timeout="auto" unmountOnExit>
        <RecordContainer role="table" className="tasks-table-container">
          <TableRowContainer role="rowheader" className="tasks-table-row-header-container">
            <MuiBox role="columnheader">
              <TableHeaderText>{bundle.getMessage('task_title_header')}</TableHeaderText>
            </MuiBox>
            <MuiBox role="columnheader">
              <TableHeaderText>{bundle.getMessage('severity_header')}</TableHeaderText>
            </MuiBox>
            <MuiBox role="columnheader">
              <TableHeaderText>{bundle.getMessage('due_date_header')}</TableHeaderText>
            </MuiBox>
            <MuiBox role="columnheader">
              <TableHeaderText>{bundle.getMessage('state_header')}</TableHeaderText>
            </MuiBox>
          </TableRowContainer>
          {props.assessment.tasks !== undefined &&
            props.assessment.tasks.length > 0 &&
            props.assessment.tasks
              .filter((task: Task) => props.visibleTaskTpsRecordIds.has(task.tpsRecordId))
              .sort((aTask, bTask) => sortTasksOrIssues(props.assessment, props.user, aTask, bTask))
              .map((task: Task) => {
                return (
                  <TableRowContainer role="row" className="task-table-row" key={task.tpsRecordId}>
                    <MuiBox role="cell" className="task-table-details">
                      <MuiLink
                        {...{
                          component: Link,
                          to: getTPSPortalUrl({
                            id: task.tpsRecordSystemId,
                            linkType: SnowUrlType.TASK,
                            userRole: props.user.role,
                            impersonationIntent,
                          }),
                        }}
                        underline="none"
                        color="inherit"
                      >
                        <TaskDetails>
                          <TaskTitle assessment={props.assessment} task={task} user={props.user}>
                            {task.title ? task.title : bundle.getMessage('no_task_title')}
                          </TaskTitle>
                          <TaskRecordText>{task.tpsRecordId}</TaskRecordText>
                        </TaskDetails>
                      </MuiLink>
                    </MuiBox>
                    <TaskDetailContainer role="cell" className="task-table-severity">
                      <CellText>
                        {bundle.formatMessage('severity_state', {
                          severity: severityStrToEnum(task.severity),
                        })}
                      </CellText>
                    </TaskDetailContainer>
                    <TaskDetailContainer role="cell" className="task-table-dueDate">
                      <CellText>
                        {task.dueDate
                          ? bundle.formatMessage('task_due_date', {
                              dueDate: new Date(0).setUTCSeconds(Number(task.dueDate)),
                            })
                          : bundle.getMessage('no_due_date_error')}
                      </CellText>
                    </TaskDetailContainer>
                    <TaskDetailContainer role="cell" className="task-table-state" sx={{ justifyContent: 'center' }}>
                      <StateLabel sx={getTaskLabelColor(task, props.user)}>
                        {bundle.formatMessage('record_state', {
                          state: stateStrToEnum(task.state),
                        })}
                      </StateLabel>
                    </TaskDetailContainer>
                  </TableRowContainer>
                );
              })}
        </RecordContainer>
      </MuiCollapse>
      <Divider variant="fullWidth" />
    </React.Fragment>
  );
}

function TasksPage({ bundle }: WithBundleProps): React.ReactElement {
  const user = useUserContext();
  const [filterState, dispatch] = useReducer(filterReducer, { filtersLoading: true });
  const [dataState] = useDataContext();
  const nonEmptyAssessments = useMemo(
    () => dataState.assessments.filter((assessment) => assessment.taskCount && assessment.taskCount > 0),
    [dataState],
  );

  //comment above and uncomment below one for testing for requester persona
  // const nonEmptyAssessments: Assessment[] = tasks;

  // useEffect for initial state to contain filtermap, label sets at component level
  useEffect(() => {
    if (!taskDataIsLoaded(dataState)) {
      if (!filterState.filtersLoading) {
        dispatch({ type: 'reset' });
      }
      return;
    }

    dispatch({
      type: 'initialSetup',
      payload: {
        labels: [FILTER_LABELS.VENDOR, FILTER_LABELS.TIER, FILTER_LABELS.TASK_STATE, FILTER_LABELS.TASK_SEVERITY],
        bundle,
        componentData: nonEmptyAssessments,
      },
    });
  }, [filterState.filtersLoading, dataState]);

  return (
    <MuiBox className="tasks-page-container">
      {filterState.filtersLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <FilterBar
            filterFields={filterState.filterFields!}
            filterLabels={filterState.labels!}
            dispatch={dispatch}
            title={PAGE_TITLE.TASKS_PAGE}
          />
        </>
      )}

      <MuiBox className="tasks-assessment-container">
        {taskDataIsLoaded(dataState) && !filterState.filtersLoading && nonEmptyAssessments.length === 0 && (
          <MuiBox>{bundle.getMessage('no_task_records')}</MuiBox>
        )}
        {getLoadState(dataState, TPSRecordType.TASK) === LoadState.LOADING && <LoadingSpinner />}
        {getLoadState(dataState, TPSRecordType.TASK) === LoadState.SUCCESS &&
          !filterState.filtersLoading &&
          sortFavoritesFirst(
            nonEmptyAssessments.sort((aAssessment, bAssessment) =>
              sortAssessmentRecords(user)(aAssessment, bAssessment, TPSRecordType.TASK),
            ),
          ).map((assessment: Assessment) => {
            const empty = <React.Fragment key={assessment.tpsRecordSystemId}></React.Fragment>;

            let visibleTaskTpsRecordIds: Set<string>;
            const filterQuery = {
              vendor: assessment.vendor,
              tier: bundle.formatMessage('tier_number', { tier: assessment.tier }),
              search: [assessment.tpsRecordId, assessment.name],
            };

            let isTptaSearchResult = false;
            if (filterState.filterFields!.labelToFilters.size > 0) {
              if (isTptaSearchApplied(filterState.filterFields?.labelToFilters)) {
                if (!isSearchResult(filterQuery, filterState.filterFields!.labelToFilters)) return empty;
                else isTptaSearchResult = true;
              }
              if (
                areNonIssueTaskSearchFiltersApplied(filterState.filterFields?.labelToFilters) &&
                !isIncludedInFilter(filterQuery, filterState.filterFields!.labelToFilters)
              ) {
                return empty; // assessment were filtered out
              }
              // assessment survived filtering, get all tasks
              visibleTaskTpsRecordIds = getVisibleTaskTpsRecordIds(
                filterState.filterFields!.labelToFilters,
                assessment.tasks!,
                bundle,
              );
            } else {
              // no filter is applied thus all tasks are visible
              visibleTaskTpsRecordIds = new Set(assessment.tasks!.map((task) => task.tpsRecordId));
            }

            let visible = visibleTaskTpsRecordIds.size > 0;

            if (!visible) {
              visible =
                isSearchApplied(filterState.filterFields!.labelToFilters) &&
                (isTptaSearchResult || isSearchResult(filterQuery, filterState.filterFields!.labelToFilters));
              if (visible && !areTaskFiltersApplied(filterState.filterFields?.labelToFilters)) {
                // The only match is from the TPTA section
                visibleTaskTpsRecordIds = new Set(assessment.tasks!.map((task) => task.tpsRecordId));
              }
            }

            return visible ? (
              <TasksTable
                assessment={assessment}
                bundle={bundle}
                user={user}
                key={assessment.tpsRecordId}
                visibleTaskTpsRecordIds={visibleTaskTpsRecordIds}
                expanded={
                  areTaskFiltersApplied(filterState.filterFields?.labelToFilters) ||
                  (isSearchApplied(filterState.filterFields?.labelToFilters) && visibleTaskTpsRecordIds.size > 0)
                }
              />
            ) : (
              empty
            );
          })}
      </MuiBox>
    </MuiBox>
  );
}

export default withBundle('pages.TasksPage')(TasksPage);
