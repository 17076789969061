import React, { useReducer, createContext, useContext, useEffect } from 'react';
import Assessment, {
  Consult,
  Email,
  Issue,
  Questionnaire,
  State,
  Survey,
  TpsRecordActivity,
  Activity,
  ActivityType,
  Task,
} from '../data/RecordMetadata';
import { useGetFavoritesByUserQuery } from '../apis/FavoritesAPI';
import { useGetAssessmentsByUserQuery } from '../apis/AssessmentsAPI';
import { useGetIssuesByUserQuery } from '../apis/IssuesAPI';
import { useGetTasksByUserQuery } from '../apis/TasksAPI';
import { useGetConsultsByUserQuery } from '../apis/ConsultsAPI';
import { useGetSurveysByUserQuery } from '../apis/SurveysAPI';
import { useGetActivitiesByUserQuery } from '../apis/ActivitiesAPI';
import { useImpersonation, useUserContext } from './UserProvider';
import { isStateClosed, stateStrToEnum, getTPSRecordType } from '../utils/enumUtils';
import { isClosedCompleteNa } from '../utils/dataUtils';
import { RoleType } from '../data/UserMetadata';
import { filterActivity } from '../utils/activityLog';

/**
 * Mapping helper function to create new Assessments and
 * fill in any data gaps so that there is less null data.
 *
 * @param newAssessment
 * @param oldAssessment
 * @returns Assessment
 */
function fillAssessmentData(newAssessment: Assessment, oldAssessment?: Assessment) {
  // Draft shouldn't appear at all in the UI
  const getNonDraftRecords = (record: Issue | Task) => stateStrToEnum(record.state) !== State.DRAFT;
  const isRecordActive = (record: Issue | Task) => !isClosedCompleteNa(stateStrToEnum(record.state));

  return !oldAssessment
    ? {
        ...newAssessment,
        issues: !newAssessment.issues ? [] : newAssessment.issues.filter(getNonDraftRecords),
        issueCount: !newAssessment.issues
          ? 0
          : newAssessment.issues.filter(getNonDraftRecords).filter(isRecordActive).length,
        tasks: !newAssessment.tasks ? [] : newAssessment.tasks.filter(getNonDraftRecords),
        taskCount: !newAssessment.tasks
          ? 0
          : newAssessment.tasks.filter(getNonDraftRecords).filter(isRecordActive).length,
        questionnaires: !newAssessment.questionnaires ? [] : newAssessment.questionnaires,
        thirdParty: !newAssessment.thirdParty ? [] : newAssessment.thirdParty,
        thirdPartyContact: !newAssessment.thirdPartyContact ? '' : newAssessment.thirdPartyContact,
        activities: [], // We know we aren't getting this in the Assessment / Issue API / Task API
        inheritedDdq: !newAssessment.inheritedDdq ? false : newAssessment.inheritedDdq,
        inheritedDdqTpsRecordId: !newAssessment.inheritedDdqTpsRecordId ? '' : newAssessment.inheritedDdqTpsRecordId,
        securityTeam: !newAssessment.securityTeam ? '' : newAssessment.securityTeam,
        assignedUsers: !newAssessment.assignedUsers ? [] : newAssessment.assignedUsers,
      }
    : {
        ...newAssessment,
        issues: newAssessment.issues
          ? newAssessment.issues.filter(getNonDraftRecords)
          : oldAssessment.issues!.filter(getNonDraftRecords),
        issueCount: newAssessment.issues
          ? newAssessment.issues.filter(getNonDraftRecords).filter(isRecordActive).length
          : oldAssessment.issueCount,
        tasks: newAssessment.tasks
          ? newAssessment.tasks.filter(getNonDraftRecords)
          : oldAssessment.tasks!.filter(getNonDraftRecords),
        taskCount: newAssessment.tasks
          ? newAssessment.tasks.filter(getNonDraftRecords).filter(isRecordActive).length
          : oldAssessment.taskCount,
        questionnaires: newAssessment.questionnaires ? newAssessment.questionnaires : oldAssessment.questionnaires,
        thirdParty: newAssessment.thirdParty ? newAssessment.thirdParty : oldAssessment.thirdParty,
        thirdPartyContact: newAssessment.thirdPartyContact
          ? newAssessment.thirdPartyContact
          : oldAssessment.thirdPartyContact,
        inheritedDdq: newAssessment.inheritedDdq ? newAssessment.inheritedDdq : oldAssessment.inheritedDdq,
        inheritedDdqTpsRecordId: newAssessment.inheritedDdqTpsRecordId
          ? newAssessment.inheritedDdqTpsRecordId
          : oldAssessment.inheritedDdqTpsRecordId,
        securityTeam: newAssessment.securityTeam ? newAssessment.securityTeam : oldAssessment.securityTeam,
        assignedUsers: newAssessment.assignedUsers ? newAssessment.assignedUsers : oldAssessment.assignedUsers,
      };
}

/**
 * This function handles de-duping and comining the issues
 * and assessment data into 1 object. There are slight data
 * differences coming from the APIs currently (8/30/23) so
 * we need to handle those difference to remove null data.
 *
 * @param state
 * @param newAssessments
 * @returns Assessment[]
 */
function getUpdatedAssessments(state: Assessment[], newAssessments: Assessment[]): Assessment[] {
  const dupeMap: Map<string, Assessment> = new Map();
  state.forEach((assessment) => dupeMap.set(assessment.tpsRecordId, assessment));
  if (newAssessments)
    newAssessments.forEach((assessment) => {
      if (dupeMap.has(assessment.tpsRecordId))
        dupeMap.set(assessment.tpsRecordId, fillAssessmentData(assessment, dupeMap.get(assessment.tpsRecordId)));
      else dupeMap.set(assessment.tpsRecordId, fillAssessmentData(assessment));
    });

  return Array.from(dupeMap.values());
}

function addActivityMetadata(activities: TpsRecordActivity[]): TpsRecordActivity[] {
  const mappedActivities: TpsRecordActivity[] = [];
  activities.forEach((activity) => {
    const modifiedActivities = activity.activities.map((record) => ({
      ...record,
      tpsRecordId: activity.tpsRecordId,
      tpsRecordSystemId: activity.tpsRecordSystemId,
      tpsRecordType: getTPSRecordType(activity.tpsRecordId, activity.tpsRecordType!),
    }));

    const modifiedEmails = activity.emailsSent.map((email) => ({
      ...email,
      tpsRecordId: activity.tpsRecordId,
      tpsRecordSystemId: activity.tpsRecordSystemId,
      tpsRecordType: getTPSRecordType(activity.tpsRecordId, activity.tpsRecordType!),
    }));

    mappedActivities.push({ ...activity, activities: modifiedActivities, emailsSent: modifiedEmails });
  });
  return mappedActivities;
}

function mapActivities(assessments: Assessment[], activities: TpsRecordActivity[], userRole: RoleType): Assessment[] {
  const assessmentMap: Map<string, Assessment> = new Map();
  // Key is TPRI id and Value it's associated TPTA id
  const issuesMap: Map<string, string> = new Map();
  // Key is TPRT id and Value it's associated TPTA id
  const tasksMap: Map<string, string> = new Map();
  // Key is TPRA id and Value it's associated TPTA id
  const ddqMap: Map<string, string> = new Map();

  assessments.forEach((assessment: Assessment) => {
    assessment.activities = [];
    assessmentMap.set(assessment.tpsRecordId, assessment);
    if (assessment.issues) {
      assessment.issues.forEach((issue: Issue) => issuesMap.set(issue.tpsRecordId, assessment.tpsRecordId));
    }
    if (assessment.tasks) {
      assessment.tasks.forEach((task: Task) => tasksMap.set(task.tpsRecordId, assessment.tpsRecordId));
    }
    if (assessment.questionnaires) {
      assessment.questionnaires.forEach((questionnaire: Questionnaire) =>
        ddqMap.set(questionnaire.id, assessment.tpsRecordId),
      );
    }
  });

  if (activities) {
    activities.forEach((activity: TpsRecordActivity) => {
      if (
        (!activity.activities || activity.activities.length === 0) &&
        (!activity.emailsSent || activity.emailsSent.length === 0)
      )
        return;

      const modifiedActivities = activity.activities.filter((perActivity: Activity) => {
        return filterActivity(perActivity, userRole, activity.tpsRecordId, activity.tpsRecordType!);
      });

      let activityEmailUnion = [];

      if (userRole === RoleType.REQUESTER) {
        activityEmailUnion = [...modifiedActivities, ...activity.emailsSent];
      } else {
        activityEmailUnion = [...modifiedActivities];
      }

      if (assessmentMap.has(activity.tpsRecordId)) {
        assessmentMap.get(activity.tpsRecordId)!.activities = [
          ...assessmentMap.get(activity.tpsRecordId)!.activities!,
          ...activityEmailUnion,
        ];
      } else if (ddqMap.has(activity.tpsRecordId) && assessmentMap.has(ddqMap.get(activity.tpsRecordId)!)) {
        // Since the VALUE of these maps is the associated TPTA number
        // and we want to associate the activities to Assessments.
        assessmentMap.get(ddqMap.get(activity.tpsRecordId)!)!.activities = [
          ...assessmentMap.get(ddqMap.get(activity.tpsRecordId)!)!.activities!,
          ...activityEmailUnion,
        ];
      } else if (issuesMap.has(activity.tpsRecordId) && assessmentMap.has(issuesMap.get(activity.tpsRecordId)!)) {
        // Since the VALUE of these maps is the associated TPTA number
        // and we want to associate the activities to Assessments.
        assessmentMap.get(issuesMap.get(activity.tpsRecordId)!)!.activities = [
          ...assessmentMap.get(issuesMap.get(activity.tpsRecordId)!)!.activities!,
          ...activityEmailUnion,
        ];
      } else if (tasksMap.has(activity.tpsRecordId) && assessmentMap.has(tasksMap.get(activity.tpsRecordId)!)) {
        // Since the VALUE of these maps is the associated TPTA number
        // and we want to associate the activities to Assessments.
        assessmentMap.get(tasksMap.get(activity.tpsRecordId)!)!.activities = [
          ...assessmentMap.get(tasksMap.get(activity.tpsRecordId)!)!.activities!,
          ...activityEmailUnion,
        ];
      }
    });
  }

  return Array.from(assessmentMap.values());
}

export enum LoadState {
  LOADING,
  SUCCESS,
  ERROR,
}

interface DataLoadStore {
  activities: LoadState;
  assessments: LoadState;
  consults: LoadState;
  issues: LoadState;
  tasks: LoadState;
  surveys: LoadState;
  filtedInProgressAssessments: LoadState;
}

type StateType = {
  activities: TpsRecordActivity[];
  assessments: Assessment[];
  inProgressAssessments: Assessment[];
  consults: Consult[];
  surveys: Survey[];
  favoritesUpdatedAt: number;
  loadStore: DataLoadStore;
};

/* eslint-disable */
type DataActions =
  | { type: 'getFavorites'; payload: { favorites: Set<string>; favoritesUpdatedAt: number } }
  | { type: 'addFavorite'; payload: string }
  | { type: 'removeFavorite'; payload: string }
  | { type: 'toggleFilter'; payload: string[] }
  | { type: 'initialSetup'; payload: Assessment[] }
  | { type: 'filterInProgressAssessments'; payload: Assessment[] }
  | { type: 'addActivities'; payload: { data: TpsRecordActivity[]; role: RoleType } }
  | { type: 'updateActivities'; payload: { data: TpsRecordActivity[]; loadState: LoadState; role: RoleType } }
  | { type: 'updateAssessments'; payload: { data: Assessment[]; loadState: LoadState } }
  | { type: 'updateConsults'; payload: { data: Consult[]; loadState: LoadState } }
  | { type: 'updateIssues'; payload: { data: Assessment[]; loadState: LoadState } }
  | { type: 'updateTasks'; payload: { data: Assessment[]; loadState: LoadState } }
  | { type: 'updateSurveys'; payload: { data: Survey[]; loadState: LoadState } }
  | { type: 'resetData' };

/* eslint-enable */

const projectsReducer = (state: StateType, action: DataActions): StateType => {
  switch (action.type) {
    case 'initialSetup':
      return {
        ...state,
        assessments: action.payload,
        favoritesUpdatedAt: -1,
      };

    case 'addActivities':
      return {
        ...state,
        assessments: mapActivities(state.assessments, action.payload.data, action.payload.role),
      };

    case 'updateActivities':
      // This filtering is because of this Quip doc:
      // https://quip-amazon.com/CmTRAUf8pkrq/CX-Activities-Log
      if (!action.payload.data)
        return {
          ...state,
          loadStore: {
            ...state.loadStore,
            activities: action.payload.loadState,
          },
        };

      const filteredRecords: TpsRecordActivity[] = [];
      const intakeTrackerSet: Set<string> = new Set();

      action.payload.data.forEach((record) => {
        const filteredActivities = record.activities.filter((activity) => {
          if (activity?.type === ActivityType.INTAKE_COMPLETED) {
            //added this to avoid duplicate intake complete activities per record that we are receiving from activities API as of 09/24
            if (intakeTrackerSet.has(activity.tpsRecordId!)) {
              return false;
            } else {
              intakeTrackerSet.add(activity.tpsRecordId!);
            }
          }

          return filterActivity(activity, action.payload.role, record.tpsRecordId, record.tpsRecordType!);
        });

        let emails: Email[] = [];

        if (action.payload.role === RoleType.REQUESTER) {
          //with updated activity log emails sent activity can only be seen by 1P user's
          emails = [...record.emailsSent];
        }

        filteredRecords.push({ ...record, activities: filteredActivities, emailsSent: emails });
      });
      return {
        ...state,
        activities: addActivityMetadata(filteredRecords),
        loadStore: {
          ...state.loadStore,
          activities: action.payload.loadState,
        },
      };

    case 'updateAssessments':
      return {
        ...state,
        assessments: getUpdatedAssessments(state.assessments, action.payload.data),
        favoritesUpdatedAt: -1,
        loadStore: {
          ...state.loadStore,
          assessments: action.payload.loadState,
        },
      };

    case 'filterInProgressAssessments':
      return {
        ...state,
        inProgressAssessments: action.payload.filter((assessment) => {
          if (stateStrToEnum(assessment.state) === State.COMPLETE && assessment.issueCount! > 0) return true;
          else if (!isStateClosed(assessment.state)) return true;
          return false;
        }),
        loadStore: {
          ...state.loadStore,
          filtedInProgressAssessments: LoadState.SUCCESS,
        },
      };

    case 'updateIssues':
      return {
        ...state,
        assessments: getUpdatedAssessments(state.assessments, action.payload.data),
        favoritesUpdatedAt: -1,
        loadStore: {
          ...state.loadStore,
          issues: action.payload.loadState,
        },
      };

    case 'updateTasks':
      return {
        ...state,
        assessments: getUpdatedAssessments(state.assessments, action.payload.data),
        favoritesUpdatedAt: -1,
        loadStore: {
          ...state.loadStore,
          tasks: action.payload.loadState,
        },
      };

    case 'updateConsults':
      return {
        ...state,
        consults: action.payload.data,
        loadStore: {
          ...state.loadStore,
          consults: action.payload.loadState,
        },
      };

    case 'updateSurveys':
      return {
        ...state,
        surveys: action.payload.data,
        loadStore: {
          ...state.loadStore,
          surveys: action.payload.loadState,
        },
      };

    case 'getFavorites':
      return {
        ...state,
        assessments: [...state.assessments].map((project) => {
          if (action.payload.favorites.has(project.tpsRecordId)) project.isFavorited = true;
          else project.isFavorited = false;
          return project;
        }),
        favoritesUpdatedAt: action.payload.favoritesUpdatedAt,
      };

    case 'addFavorite':
      return {
        ...state,
        assessments: [...state.assessments].map((project) => {
          if (project.tpsRecordId === action.payload) project.isFavorited = true;
          return project;
        }),
        favoritesUpdatedAt: state.favoritesUpdatedAt,
      };

    case 'removeFavorite':
      return {
        ...state,
        assessments: [...state.assessments].map((project) => {
          if (project.tpsRecordId === action.payload) project.isFavorited = false;
          return project;
        }),
        favoritesUpdatedAt: state.favoritesUpdatedAt,
      };

    case 'resetData':
      return {
        ...EMPTY_STATE,
      };
  }
  return state;
};

export type TpsData = Omit<StateType, 'favoritesUpdatedAt'>;
const INIT_DATA: TpsData = Object.freeze({
  activities: [],
  assessments: [],
  inProgressAssessments: [],
  consults: [],
  surveys: [],
  loadStore: {
    activities: LoadState.LOADING,
    assessments: LoadState.LOADING,
    consults: LoadState.LOADING,
    issues: LoadState.LOADING,
    tasks: LoadState.LOADING,
    surveys: LoadState.LOADING,
    filtedInProgressAssessments: LoadState.LOADING,
  },
});
const DataContext = createContext<[TpsData, React.Dispatch<DataActions>]>([
  INIT_DATA,
  () => {}, // eslint-disable-line
]);

const useDataContext = () => useContext(DataContext);

const EMPTY_STATE = Object.freeze({
  ...INIT_DATA,
  favoritesUpdatedAt: -1,
});
interface DataProviderProps {
  children: React.ReactNode;
}
const DataProvider = ({ children }: DataProviderProps) => {
  const { role: userRole } = useUserContext();
  const { impersonationTarget } = useImpersonation();
  const [state, dispatch] = useReducer(projectsReducer, EMPTY_STATE);

  const favoritesQuery = useGetFavoritesByUserQuery();
  const assessmentQuery = useGetAssessmentsByUserQuery();
  const issuesQuery = useGetIssuesByUserQuery();
  const tasksQuery = useGetTasksByUserQuery();
  const activityQuery = useGetActivitiesByUserQuery();
  const consultsQuery = useGetConsultsByUserQuery();
  const surveyQuery = useGetSurveysByUserQuery();

  // This useEffect must be first so that data is reset before it's updated
  useEffect(() => {
    dispatch({ type: 'resetData' });
  }, [impersonationTarget]);

  useEffect(() => {
    if (assessmentQuery.isSuccess) {
      dispatch({
        type: 'updateAssessments',
        payload: {
          data: assessmentQuery.data.assessments,
          loadState: LoadState.SUCCESS,
        },
      });
    } else if (assessmentQuery.isError) {
      dispatch({
        type: 'updateAssessments',
        payload: {
          data: !assessmentQuery.data ? [] : assessmentQuery.data.assessments,
          loadState: LoadState.ERROR,
        },
      });
    }
  }, [assessmentQuery.data, assessmentQuery.isSuccess, assessmentQuery.isError]);

  // Used for filtering OPEN and COMPLETED assessments
  // Must occur after the Issues and Assessments data is
  // loaded.
  useEffect(() => {
    if (
      state.loadStore.assessments === LoadState.SUCCESS &&
      state.loadStore.issues === LoadState.SUCCESS &&
      state.loadStore.tasks === LoadState.SUCCESS
    ) {
      dispatch({
        type: 'filterInProgressAssessments',
        payload: state.assessments,
      });
    }
  }, [state.loadStore.assessments, state.loadStore.issues, state.loadStore.tasks, state.assessments]);

  useEffect(() => {
    if (issuesQuery.isSuccess) {
      dispatch({
        type: 'updateIssues',
        payload: {
          data: issuesQuery.data.assessments,
          loadState: LoadState.SUCCESS,
        },
      });
    } else if (issuesQuery.isError) {
      dispatch({
        type: 'updateIssues',
        payload: {
          data: !issuesQuery.data ? [] : issuesQuery.data.assessments,
          loadState: LoadState.ERROR,
        },
      });
    }
  }, [issuesQuery.data, issuesQuery.isSuccess, issuesQuery.isError]);

  useEffect(() => {
    if (tasksQuery.isSuccess) {
      dispatch({
        type: 'updateTasks',
        payload: {
          data: tasksQuery.data.assessments,
          loadState: LoadState.SUCCESS,
        },
      });
    } else if (tasksQuery.isError) {
      dispatch({
        type: 'updateTasks',
        payload: {
          data: !tasksQuery.data ? [] : tasksQuery.data.assessments,
          loadState: LoadState.ERROR,
        },
      });
    }
  }, [tasksQuery.data, tasksQuery.isSuccess, tasksQuery.isError]);

  useEffect(() => {
    if (
      state.loadStore.activities === LoadState.SUCCESS &&
      state.loadStore.assessments === LoadState.SUCCESS &&
      state.loadStore.issues === LoadState.SUCCESS &&
      state.loadStore.tasks === LoadState.SUCCESS
    ) {
      dispatch({
        type: 'addActivities',
        payload: {
          data: state.activities,
          role: userRole,
        },
      });
    }
  }, [state.loadStore.activities, state.loadStore.assessments, state.loadStore.issues, state.loadStore.tasks]);

  useEffect(() => {
    if (activityQuery.isSuccess) {
      dispatch({
        type: 'updateActivities',
        payload: {
          data: activityQuery.data.activities,
          loadState: LoadState.SUCCESS,
          role: userRole,
        },
      });
    } else if (activityQuery.isError) {
      dispatch({
        type: 'updateActivities',
        payload: {
          data: !activityQuery.data ? [] : activityQuery.data.activities,
          loadState: LoadState.ERROR,
          role: userRole,
        },
      });
    }
  }, [activityQuery.data, activityQuery.isSuccess, activityQuery.isError]);

  useEffect(() => {
    if (consultsQuery.isSuccess) {
      dispatch({
        type: 'updateConsults',
        payload: {
          data: consultsQuery.data.consults,
          loadState: LoadState.SUCCESS,
        },
      });
    } else if (consultsQuery.error) {
      dispatch({
        type: 'updateConsults',
        payload: {
          data: !consultsQuery.data ? [] : consultsQuery.data.consults,
          loadState: LoadState.ERROR,
        },
      });
    }
  }, [consultsQuery.data, consultsQuery.isSuccess, consultsQuery.isError]);

  useEffect(() => {
    if (surveyQuery.isSuccess) {
      dispatch({
        type: 'updateSurveys',
        payload: {
          data: surveyQuery.data.surveys,
          loadState: LoadState.SUCCESS,
        },
      });
    } else if (surveyQuery.isError) {
      dispatch({
        type: 'updateSurveys',
        payload: {
          data: !surveyQuery.data ? [] : surveyQuery.data.surveys,
          loadState: LoadState.ERROR,
        },
      });
    }
  }, [surveyQuery.data, surveyQuery.isSuccess, surveyQuery.isError]);

  if (favoritesQuery.isSuccess && favoritesQuery.dataUpdatedAt !== state.favoritesUpdatedAt) {
    const favorites = new Set<string>();
    if (favoritesQuery.data?.favorite?.tpsRecord) {
      Object.entries(favoritesQuery.data.favorite.tpsRecord).forEach(([key]) => {
        favorites.add(key);
      });
    }
    dispatch({
      type: 'getFavorites',
      payload: {
        favorites: favorites,
        favoritesUpdatedAt: favoritesQuery.dataUpdatedAt,
      },
    });
  }

  return (
    <DataContext.Provider
      value={[
        {
          activities: state.activities,
          assessments: state.assessments,
          inProgressAssessments: state.inProgressAssessments,
          consults: state.consults,
          surveys: state.surveys,
          loadStore: state.loadStore,
        },
        dispatch,
      ]}
    >
      {children}
    </DataContext.Provider>
  );
};

export { type DataActions, DataProvider, useDataContext };
