import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import MuiTypography from '@mui/material/Typography';
import MuiCheckbox from '@mui/material/Checkbox';
import MuiTextField from '@mui/material/TextField';
import MuiCheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import MuiCheckBoxIcon from '@mui/icons-material/CheckBox';
import MuiBox from '@mui/material/Box';
import MuiClearIcon from '@mui/icons-material/Clear';
import MuiIconButton from '@mui/material/IconButton';
import MuiAutocomplete from '@mui/material/Autocomplete';
import MuiInputAdornment from '@mui/material/InputAdornment';
import MuiSearchIcon from '@mui/icons-material/Search';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import { BLACK, BREAKPOINT, FILTER_LABELS, LINK_BLUE, PRIMARY_BLUE } from '../../constants/constants';
import {
  FilterDropdownLabel,
  FilterActions,
  FilterFields,
  FilterLabel,
  isFilterLabel,
  isFilterDropdownLabel,
} from './FilterReducer';
import { DataActions } from '../../components/FilterBar/FilterReducer';
import './FilterBar.css';

interface FilterBarProps extends WithBundleProps {
  title: string;
  filterFields: FilterFields;
  filterLabels: FilterDropdownLabel[];
  dispatch: Dispatch<DataActions>;
}

const icon = <MuiCheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <MuiCheckBoxIcon fontSize="small" />;

const closeBtnStyle = {
  '& .MuiButtonBase-root.MuiAutocomplete-clearIndicator': {
    color: BLACK,
    visibility: 'visible',
  },
  '& .MuiButtonBase-root.MuiAutocomplete-clearIndicator:hover': {
    color: LINK_BLUE,
  },
  '& .MuiAutocomplete-input': {
    fontSize: '15px',
  },
};

const FilterOptions = styled(MuiTypography)(({ theme }) => ({
  fontSize: '12px',
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '11px',
  },
}));

function FilterBar({ bundle, ...props }: FilterBarProps): React.ReactElement {
  //constant reference for filter fields to avoid unnecessary child renders due to filter state changes
  const fieldToSelectionRef = useRef<Map<FilterDropdownLabel, { lastSelection: string; numSelected: number }>>(
    new Map(),
  );
  const [searchString, setSearchString] = useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams(new URLSearchParams());
  const location = useLocation();

  const updateFilter = (values: Array<string>, reason: string, fieldLabel: string) => {
    const lastFilter = values.length > 0 ? values[values.length - 1] : ''; // no filters applied
    props.dispatch({
      type: reason as FilterActions,
      payload: {
        label: fieldLabel,
        filters: values,
      },
    });

    fieldToSelectionRef.current.set(fieldLabel, {
      lastSelection: lastFilter,
      numSelected: values.length,
    });
  };

  useEffect(() => {
    // Parse filters from the search params
    const labelToFilter = new Map<FilterLabel, string[]>();
    for (const [paramKey, paramValue] of searchParams) {
      if (isFilterLabel(paramKey)) {
        if (!labelToFilter.has(paramKey)) labelToFilter.set(paramKey, []);
        labelToFilter.get(paramKey)!.push(paramValue);
      }
    }

    for (const label of labelToFilter.keys()) {
      if (isFilterDropdownLabel(label)) {
        updateFilter(labelToFilter.get(label)!, 'selectOption', label);
      } else {
        const searchStringParam = labelToFilter.get(label)!.join(',');
        props.dispatch({
          type: 'fuzzySearch',
          payload: {
            searchString: searchStringParam,
          },
        });
        setSearchString(searchStringParam);
      }
    }
  }, [location.search]);

  useEffect(() => {
    // Add filters to URL
    setSearchParams((prevSearchParams: URLSearchParams) => {
      const newSearchParams = new URLSearchParams(prevSearchParams);
      newSearchParams.forEach((_value: string, key: string) => {
        if (!props.filterFields.labelToFilters.has(key)) {
          newSearchParams.delete(key);
        }
      });
      for (const label of props.filterFields.labelToFilters.keys()) {
        props.filterFields.labelToFilters.get(label)!.forEach((filter, index) => {
          if (index === 0) newSearchParams.set(label, filter);
          else newSearchParams.append(label, filter);
        });
      }
      return newSearchParams;
    });
  }, [props.filterFields]);

  return (
    <React.Fragment>
      <MuiBox
        sx={{
          height: '32px',
          marginBottom: '40px',
        }}
        className="filter-bar-container"
      >
        <MuiBox className={`filter-label filter-label-${props.title}`}>
          <MuiTypography variant="h6" sx={{ color: PRIMARY_BLUE, fontSize: '0.75vw' }}>
            {bundle.getMessage(props.title)}
          </MuiTypography>
        </MuiBox>
        <MuiBox
          sx={{
            width: '3.5vw',
          }}
          className="filter-by"
        >
          <MuiTypography variant="h6" sx={{ color: BLACK, fontSize: '0.75vw' }}>
            {bundle.getMessage('filter_by')}
          </MuiTypography>
        </MuiBox>
        <MuiBox className={`filters filters-${props.title}`}>
          {props.filterLabels &&
            props.filterLabels.map((fieldLabel) => {
              const labelValues = props.filterFields.labelToValueToOccurrences.get(fieldLabel)?.keys();
              const options: Array<string> = labelValues ? Array.from(labelValues) : [];

              return (
                <MuiAutocomplete
                  multiple
                  id={fieldLabel}
                  key={fieldLabel}
                  value={props.filterFields.labelToFilters.get(fieldLabel) ?? []}
                  options={[...options]}
                  disableCloseOnSelect
                  getOptionLabel={(option: string) => option}
                  onChange={(_event: React.SyntheticEvent, values: Array<string>, reason: string) => {
                    updateFilter(values, reason, fieldLabel);
                  }}
                  renderOption={(renderprops, option, { selected }) => (
                    <li {...renderprops}>
                      <MuiCheckbox icon={icon} checkedIcon={checkedIcon} sx={{ ml: '-15px' }} checked={selected} />
                      <FilterOptions>
                        {fieldLabel === FILTER_LABELS.VENDOR
                          ? option
                          : bundle.formatMessage('filter_option', {
                              filterOption: option === '' ? bundle.getMessage('blank_option') : option,
                              numAvailable: props.filterFields.labelToValueToOccurrences.get(fieldLabel)!.get(option),
                            })}
                      </FilterOptions>
                    </li>
                  )}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      label={
                        fieldToSelectionRef.current.get(fieldLabel) &&
                        fieldToSelectionRef.current.get(fieldLabel)!.numSelected > 0
                          ? bundle.formatMessage('filter_label', {
                              filterLabel: fieldLabel,
                              number: fieldToSelectionRef.current.get(fieldLabel)!.numSelected,
                            })
                          : bundle.formatMessage('filter_label_empty', { filterLabel: fieldLabel })
                      }
                      placeholder={
                        fieldToSelectionRef.current.get(fieldLabel) &&
                        fieldToSelectionRef.current.get(fieldLabel)!.lastSelection
                          ? fieldToSelectionRef.current.get(fieldLabel)!.lastSelection
                          : options[0]
                      }
                      InputLabelProps={{
                        style: {
                          color:
                            fieldToSelectionRef.current.get(fieldLabel) &&
                            fieldToSelectionRef.current.get(fieldLabel)!.numSelected > 0
                              ? LINK_BLUE
                              : undefined,
                          height: '1.4375em',
                        },
                      }}
                      sx={closeBtnStyle}
                      className="filter"
                    />
                  )}
                  sx={{
                    mr: '10px',
                    width:
                      fieldLabel == FILTER_LABELS.VENDOR ||
                      fieldLabel == FILTER_LABELS.STATE ||
                      fieldLabel == FILTER_LABELS.ISSUE_STATE
                        ? '26%'
                        : '28%',
                    height: '1em',
                    '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall.MuiAutocomplete-input': {
                      paddingLeft: '1.5px',
                    },
                  }}
                  size={'small'}
                  renderTags={() => {
                    return null;
                  }}
                  autoHighlight
                  fullWidth={true}
                />
              );
            })}
        </MuiBox>
        <MuiBox className="search-bar">
          {/* <SearchBar /> */}
          <MuiTextField
            id="outlined-start-adornment"
            className="search-bar-textfield"
            InputProps={{
              startAdornment: (
                <MuiInputAdornment position="start">
                  <MuiSearchIcon />
                </MuiInputAdornment>
              ),
              endAdornment: searchString ? (
                <MuiIconButton
                  onClick={() => {
                    setSearchString('');
                    props.dispatch({
                      type: 'fuzzySearchClear',
                    });
                  }}
                  sx={{
                    '&:hover': {
                      color: LINK_BLUE,
                    },
                  }}
                >
                  <MuiClearIcon />
                </MuiIconButton>
              ) : null,
            }}
            placeholder={bundle.getMessage('search_placeholder')}
            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              setSearchString(event.target.value);

              if (event.target.value == '') {
                props.dispatch({
                  type: 'fuzzySearchClear',
                });
              } else {
                props.dispatch({
                  type: 'fuzzySearch',
                  payload: {
                    searchString: event.target.value,
                  },
                });
              }
            }}
            autoComplete="off"
            value={searchString}
          />
        </MuiBox>
      </MuiBox>
    </React.Fragment>
  );
}

export default React.memo(withBundle('components.FilterBar')(FilterBar));
