/**
 *
 * These constants serve to have a consistent place for all the STATIC
 * links and breadcrumb links live. See the following page for the sitemap
 * and more info on each tier of pages:
 *
 * https://quip-amazon.com/pd18A2VLbcTV/CX-Project-Sitemap
 *
 * All of the files label element is part of a PUFF-J identifier.
 * PLEASE BE MINDFUL WHEN CHANGING THINGS.
 */

export interface LinkType {
  label: string;
  href: string;
  isDynamic?: boolean;
}

// Home page & Logout
export const LOGOUT: LinkType = { label: 'logout', href: '/logout' };
export const LOGIN: LinkType = { label: 'login', href: '/login' };
export const HOME_PAGE: LinkType = { label: 'home_page', href: '/' };
export const DASHBOARD: LinkType = { label: 'dashboard', href: '/' };
export const DEFAULT_BREADCRUMBS: LinkType[] = [HOME_PAGE, DASHBOARD];

// First tier pages
export const ISSUES_PAGE: LinkType = { label: 'issues', href: '/issues' };
export const TASKS_PAGE: LinkType = { label: 'tasks', href: '/tasks' };
export const CONSULTS_PAGE: LinkType = { label: 'consults', href: '/consults' };
export const SURVEYS_PAGE: LinkType = { label: 'surveys', href: '/surveys' };
export const COMPLETE_ASSESSMENTS_PAGE: LinkType = { label: 'complete_assessments', href: '/complete-assessments' };

export const MY_PROFILE: LinkType = { label: 'my_profile', href: '/' };
export const MANAGE_TEAM: LinkType = { label: 'manage_team', href: '/' };
export const CHANGE_PASSWORD: LinkType = { label: 'change_password', href: '/' };

// Second tier pages
export const NEW_CONSULT: LinkType = { label: 'new_consult', href: '/request-consult' };

// Lookup table using K<relative link (string)>, V<Breadcrumb> to very quickly add the breadcrumbs
export const LINKS: Record<string, LinkType> = {
  logout: LOGOUT,
  home: HOME_PAGE,
  dashboard: DASHBOARD,
  issues: ISSUES_PAGE,
  tasks: TASKS_PAGE,
  consults: CONSULTS_PAGE,
  surveys: SURVEYS_PAGE,
  'complete-assessments': COMPLETE_ASSESSMENTS_PAGE,
};

export const SIDEBAR_LINKS: LinkType[] = [
  DASHBOARD,
  ISSUES_PAGE,
  TASKS_PAGE,
  CONSULTS_PAGE,
  SURVEYS_PAGE,
  COMPLETE_ASSESSMENTS_PAGE,
];
