import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import MuiLink from '@mui/material/Link';
import MuiNavigateNextIcon from '@mui/icons-material/NavigateNext';
import { DEFAULT_BREADCRUMBS, LINKS } from '../../constants/linkConstants';
import { SnowUrlType } from '../../constants/urlConstants';
import {
  BREADCRUMBS_HEIGHT_PX,
  BREADCRUMBS_HEIGHT_SM_PX,
  BREADCRUMBS_PADDING_PX,
  BREAKPOINT,
  GRAY,
  LINK_BLUE,
} from '../../constants/constants';
import { useImpersonation, useUserContext } from '../../context/UserProvider';
import { RoleType } from '../../data/UserMetadata';
import getTPSPortalUrl from '../../utils/redirectionUtils';
import './Breadcrumbs.css';
import { styled } from '@mui/material/styles';

const BreadcrumbsContainer = styled('div')(({ theme }) => ({
  height: BREADCRUMBS_HEIGHT_PX,
  padding: BREADCRUMBS_PADDING_PX,
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    height: BREADCRUMBS_HEIGHT_SM_PX,
  },
}));

function Breadcrumbs({ bundle }: WithBundleProps) {
  const { role: userRole } = useUserContext();
  const { impersonationIntent } = useImpersonation();
  const location = useLocation().pathname;
  const [breadcrumbs, setBreadcrumbs] = useState(DEFAULT_BREADCRUMBS);

  useEffect(() => {
    const pathnames = location.split('/').filter((x) => x);
    let breadcrumbTrail = userRole === RoleType.VENDOR ? DEFAULT_BREADCRUMBS.slice(1, 2) : DEFAULT_BREADCRUMBS;
    breadcrumbTrail = breadcrumbTrail.concat(
      pathnames.map((path) => {
        if (!LINKS[path]) {
          return { label: path, href: path, isDynamic: true }; // For dynamic links to projects
        }
        return path.length === 0 ? LINKS['dashboard'] : LINKS[path];
      }),
    );
    setBreadcrumbs(breadcrumbTrail);
  }, [location]);

  return (
    <BreadcrumbsContainer role="presentation" className="breadcrumb-background">
      <MuiBreadcrumbs separator={<MuiNavigateNextIcon className="breadcrumb-seperator" />} aria-label="breadcrumb">
        {breadcrumbs.map((breadcrumb, index) => {
          if (index === breadcrumbs.length - 1) {
            return (
              <MuiLink key={index} fontFamily="Amazon Ember" color={GRAY} underline="none">
                {breadcrumb.isDynamic ? breadcrumb.label : bundle.getMessage(breadcrumb.label)}
              </MuiLink>
            );
          }
          return (
            <MuiLink
              {...{
                component: Link,
                to:
                  index === 0 && breadcrumb === DEFAULT_BREADCRUMBS[index]
                    ? getTPSPortalUrl({ linkType: SnowUrlType.HOME, userRole, impersonationIntent })
                    : breadcrumb.href,
              }}
              key={index}
              fontFamily="Amazon Ember"
              color={LINK_BLUE}
              underline="none"
            >
              {breadcrumb.isDynamic ? breadcrumb.label : bundle.getMessage(breadcrumb.label)}
            </MuiLink>
          );
        })}
      </MuiBreadcrumbs>
    </BreadcrumbsContainer>
  );
}

export default withBundle('components.Breadcrumbs')(Breadcrumbs);
