import React from 'react';
import MuiTypography from '@mui/material/Typography';
import { styled } from '@mui/material';
import { BREAKPOINT } from '../../../constants/constants';

const LabelText = styled(MuiTypography)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.text.disabled,
  fontSize: '12px',
  marginTop: '2px',
  zIndex: 1,
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '7px',
  },
}));

const ProgressContainer = styled('span')(({ theme }) => ({
  display: 'block',
  position: 'relative',
  overflow: 'hidden',
  height: '25px',
  zIndex: 0,
  backgroundColor: theme.palette.info.main,
  borderRadius: '80px',
  width: '85%',
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    height: '15px',
  },
}));

const Progress = styled('span')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  left: 0,
  bottom: 0,
  top: 0,
  transition: 'transform .4s linear',
  transformOrigin: 'left',
  backgroundColor: theme.palette.text.primary,
}));

interface ProgressBarBase {
  text?: string;
}

interface ProgressBarDate extends ProgressBarBase {
  to: number | Date;
  from: number | Date;
  current?: never;
}

interface ProgressBarNumber extends ProgressBarBase {
  to?: never;
  from?: never;
  current: number;
}

type ProgressBarProps = ProgressBarDate | ProgressBarNumber;

function ProgressBar(props: ProgressBarProps) {
  let diff = 100;

  // If it is a date that is passed in
  if (props.to && props.from) {
    // As of 08/07/23:
    //  We expect the Dates passed in to be UTC seconds
    const now = Date.now() / 1000;

    diff = ((now - Number(props.from)) / (Number(props.to) - Number(props.from))) * 100;
    diff = diff < 0 ? 0 : diff >= 100 ? 100 : diff;
  } else {
    diff = props.current!;
    diff = diff < 0 ? 0 : diff >= 100 ? 100 : props.current!;
  }

  return (
    <ProgressContainer role="progressbar" aria-valuenow={diff} aria-valuemin={0} aria-valuemax={100}>
      <LabelText>{props.text}</LabelText>
      <Progress sx={{ transform: `translateX(${diff - 100}%)` }} />
    </ProgressContainer>
  );
}

export default ProgressBar;
