import React from 'react';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import MuiBox from '@mui/material/Box';
import MuiTypography from '@mui/material/Typography';
import Button from '../Button/Button';
import { UseQueryResult } from '@tanstack/react-query';

interface ErrorRetryProps extends WithBundleProps {
  query: UseQueryResult;
}

function ErrorRetry({ bundle, ...props }: ErrorRetryProps) {
  return (
    <MuiBox
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        marginLeft: '20px',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <MuiTypography align="center">{bundle.getMessage('retry_text')}</MuiTypography>
      <Button
        // eslint-disable-next-line
        onClick={() => props.query.refetch()}
        type="dark"
        style={{ fontSize: '10px', margin: '5px', marginBottom: 0 }}
      >
        {bundle.getMessage('retry_button_text')}
      </Button>
    </MuiBox>
  );
}

export default withBundle('components.ErrorRetry')(ErrorRetry);
