import { UseQueryResult, useQuery, useMutation } from '@tanstack/react-query';
import { get, put, post } from './ApiGatewayInvoker';
import { FavoritesPayload } from '../data/RecordMetadata';
import { retryConfig, cacheConfig, getCommonParams } from './ApiUtils';
import { useUserContext } from '../context/UserProvider';
import { useMetricsAndLoggerContext } from '../logging/MetricsProvider';

const FAVORITES_API_PATH = '/api/v1/favorites';
const FAVORITES_METRIC_NAME = 'Favorites';

type UpdateFavoritesType = {
  tptaNumber: string;
  setLocal: (favorite: boolean) => void;
  setRemote: (favorite: boolean) => void;
};

export const useGetFavoritesByUserQuery = (): UseQueryResult<FavoritesPayload> => {
  const commonParams = getCommonParams();

  return useQuery<FavoritesPayload>({
    queryKey: ['getFavoritesKey'],
    queryFn: async () => {
      return await get<FavoritesPayload>({
        apiPath: FAVORITES_API_PATH,
        name: FAVORITES_METRIC_NAME,
        emptyResponseConfig: {
          404: {} as FavoritesPayload,
        },
        ...commonParams,
      });
    },
    ...retryConfig,
    ...cacheConfig,
  });
};

export const useCreateFavoritesForUserMutation = ({ tptaNumber, setRemote, setLocal }: UpdateFavoritesType) => {
  const { email: userEmail } = useUserContext();
  const commonParams = getCommonParams();
  const logger = commonParams.logger;

  return useMutation({
    mutationKey: ['createFavoritesKey', tptaNumber],
    mutationFn: () => {
      const response = post({
        apiPath: FAVORITES_API_PATH,
        name: FAVORITES_METRIC_NAME,
        body: {
          userId: userEmail,
          tpsRecord: {
            // Currently only ASSESSMENTs are being favorited from the landing page
            [tptaNumber]: 'ASSESSMENT',
          },
        },
        ...commonParams,
      });
      logger.info('Successfully added the record to the Favorites');
      return response;
    },
    ...retryConfig,
    onSuccess: () => setRemote(true),
    onError: () => setLocal(false),
  });
};

export const useDeleteFavoritesForUserMutation = ({ tptaNumber, setLocal, setRemote }: UpdateFavoritesType) => {
  const { email: userEmail } = useUserContext();
  const commonParams = getCommonParams();
  const logger = commonParams.logger;

  return useMutation({
    mutationKey: ['deleteFavoritesKey', tptaNumber],
    mutationFn: () => {
      const response = put({
        apiPath: FAVORITES_API_PATH,
        name: FAVORITES_METRIC_NAME,
        body: {
          userId: userEmail,
          tpsRecord: {
            // Currently only ASSESSMENTs are being favorited from the landing page
            [tptaNumber]: 'ASSESSMENT',
          },
        },
        ...commonParams,
      });
      logger.info('Successfully removed the record from the Favorites');
      return response;
    },
    ...retryConfig,
    onSuccess: () => setRemote(false),
    onError: () => setLocal(true),
  });
};
