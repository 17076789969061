import React, { ReactElement } from 'react';
import MuiBox from '@mui/material/Box';
import { styled } from '@mui/material';
import AccordionDownArrowIcon from '../../../images/accordionDownArrow.svg';

const AccordionBtn = styled('button')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  border: 'none',
  backgroundColor: 'white',
  padding: '0',
  '&:hover': {
    backgroundColor: 'unset',
    color: 'unset',
    cursor: 'pointer',
  },
});

const ChildrenRows = styled(MuiBox)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

interface AccordionButtonBase {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  expanded: boolean;
  children?: ReactElement;
}

interface AccordionButtonImage extends AccordionButtonBase {
  hasarrow: boolean;
}

interface AccordionButtonNoImage extends AccordionButtonBase {
  hasarrow?: never;
}

type AccordionButtonProps = AccordionButtonImage | AccordionButtonNoImage;

function AccordionButton(props: AccordionButtonProps) {
  return (
    <React.Fragment>
      <AccordionBtn role="rowgroup" className="drop-down-inner-header" onClick={props.onClick}>
        {props.hasarrow ? (
          <img
            src={AccordionDownArrowIcon}
            style={{
              transform: !props.expanded ? 'rotate(-90deg)' : 'rotate(0deg)',
              transitionDuration: '500ms',
            }}
            aria-expanded={props.expanded}
            aria-label="show more"
          />
        ) : (
          <></>
        )}
        <ChildrenRows>{props.children}</ChildrenRows>
      </AccordionBtn>
    </React.Fragment>
  );
}

export default AccordionButton;
