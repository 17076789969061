import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { get } from './ApiGatewayInvoker';
import Assessment, { AssessmentPayload } from '../data/RecordMetadata';
import { retryConfig, cacheConfig, getCommonParams } from './ApiUtils';
import { RoleType } from '../data/UserMetadata';
import { useImpersonation, useUserContext } from '../context/UserProvider';

const TASKS_API_PATH = '/api/v1/tasks';
const TASKS_QUERY_KEY = 'getTasksKey';

export const useGetTasksByUserQuery = (): UseQueryResult<AssessmentPayload> => {
  const { role } = useUserContext();
  const { impersonationTarget } = useImpersonation();
  const commonParams = getCommonParams();

  // Only Requesters can use this query and they will always
  // be 1P users; aka Amazon employees.
  if (role === RoleType.VENDOR) {
    return useQuery<AssessmentPayload>({
      queryKey: [TASKS_QUERY_KEY],
      queryFn: async () => {
        return { assessments: [] };
      },
    });
  }

  return useQuery<AssessmentPayload>({
    queryKey: [TASKS_QUERY_KEY, impersonationTarget],
    queryFn: async () => {
      const data = await get<{ assessmentTasks: Assessment[] }>({
        apiPath: `${TASKS_API_PATH}`,
        name: 'Task',
        user: impersonationTarget,
        emptyResponseConfig: {
          404: { assessmentTasks: [] },
        },
        ...commonParams,
      });
      return { assessments: [...data.assessmentTasks] };
    },
    ...retryConfig,
    ...cacheConfig,
  });
};
