import { RoleType, UserMetadata } from '../data/UserMetadata';
import { LoadState, TpsData } from '../context/DataProvider';
import Assessment, {
  Activity,
  ActivityType,
  Consult,
  Email,
  Issue,
  Task,
  NotificationItem,
  PersonType,
  Priority,
  Questionnaire,
  QuestionnaireType,
  SortResult,
  State,
  Survey,
  TPSRecordType,
  TakeActionType,
  Tier,
  TpsRecordActivity,
  UserType,
  TPSRecordIdPrefix,
} from '../data/RecordMetadata';
import {
  activityTypeToEnum,
  questionnaireType,
  questionnaireTypeToEnum,
  severityStrToEnum,
  stateStrToEnum,
  tierToEnum,
  getTPSRecordType,
  questionnaireTypeToSnowUrlType,
} from './enumUtils';
import { DARK_GRAY, RED, YELLOW, WHITE, BLACK, GRAY } from '../constants/constants';
import { ISSUES_PAGE, CONSULTS_PAGE, SURVEYS_PAGE, TASKS_PAGE } from '../constants/linkConstants';
import getTPSPortalUrl from './redirectionUtils';
import { SnowUrlType } from '../constants/urlConstants';
import { ImpersonationIntentType } from '../context/UserProvider';

// --------------- Helper types ---------------

// Need this to account for Closed / Complete instead of a boolean
export enum ActionableState {
  CLOSED_COMPLETE_NA = 'closed_complete_na',
  PAUSED = 'paused',
  NON_ACTIONABLE = 'non_actionable',
  ACTIONABLE = 'actionable',
}

// Used for strict typing of colors
export interface ActionableColor {
  backgroundColor: string;
  color: string;
}

interface RankedData {
  key: string;
  value: any;
  text: TakeActionType;
  link: string;
}

// Temporary type to handle the case of more than 1
// questionnaire. We will pick the most important
// one that needs to be followed up on.
interface QuestionnaireMap {
  key: string;
  questionnaire: Questionnaire;
  vendor: string;
  tier: string;
}

const actionable1pPersonaTypes: Set<UserType> = new Set([
  UserType.REQUESTER,
  UserType.PRIMARY_OWNER,
  UserType.SECONDARY_OWNER,
  UserType.TECHNICAL_OWNER,
]);

// --------------- is Data loaded helper functions ---------------
function isLoaded(loadState: LoadState) {
  return loadState !== LoadState.LOADING;
}

export function allPageDataIsLoaded(state: TpsData | undefined, role: RoleType): boolean {
  if (!state) return false;
  if (!state.loadStore) return false;

  const assessmentsLoaded = isLoaded(state.loadStore.assessments);
  const issuesLoaded = isLoaded(state.loadStore.issues);
  const tasksLoaded = isLoaded(state.loadStore.tasks);
  const consultsLoaded = isLoaded(state.loadStore.consults);
  const surveysLoaded = isLoaded(state.loadStore.surveys);
  const activitiesLoaded = isLoaded(state.loadStore.activities);

  // It will never load because 3P shouldn't even query it in the first place.
  if (role === RoleType.VENDOR) return activitiesLoaded && assessmentsLoaded && surveysLoaded && issuesLoaded;

  return activitiesLoaded && assessmentsLoaded && issuesLoaded && tasksLoaded && surveysLoaded && consultsLoaded;
}

/**
 * In progress assessments is synonmous for open but
 * to match our business domain, we are not saying calling
 * them open. This is akin to a load function because this
 * will never fire until both Assessments and Issues are
 * loaded. This is so we don't filter too early.
 *
 * @param state
 * @return boolean
 */
export function inProgressAssessmentsAreFiltered(state: TpsData | undefined): boolean {
  if (!state) return false;
  if (!state.loadStore) return false;
  return isLoaded(state.loadStore.filtedInProgressAssessments);
}

export function activitiesDataIsLoaded(state: TpsData | undefined): boolean {
  if (!state) return false;
  if (!state.loadStore) return false;
  return isLoaded(state.loadStore.activities);
}

function getActivitiesLoadState(state: TpsData): LoadState {
  return state.loadStore.activities;
}

export function assessmentDataIsLoaded(state: TpsData | undefined): boolean {
  if (!state) return false;
  if (!state.loadStore) return false;
  return isLoaded(state.loadStore.assessments);
}

function getAssessmentsLoadState(state: TpsData): LoadState {
  return state.loadStore.assessments;
}

export function issueDataIsLoaded(state: TpsData | undefined): boolean {
  if (!state) return false;
  if (!state.loadStore) return false;
  return isLoaded(state.loadStore.issues);
}

export function taskDataIsLoaded(state: TpsData | undefined): boolean {
  if (!state) return false;
  if (!state.loadStore) return false;
  return isLoaded(state.loadStore.tasks);
}

function getIssueLoadState(state: TpsData): LoadState {
  return state.loadStore.issues;
}

function getTaskLoadState(state: TpsData): LoadState {
  return state.loadStore.tasks;
}

export function consultDataIsLoaded(state: TpsData | undefined): boolean {
  if (!state) return false;
  if (!state.loadStore) return false;
  return isLoaded(state.loadStore.consults);
}

function getConsultsLoadState(state: TpsData): LoadState {
  return state.loadStore.consults;
}

export function surveyDataIsLoaded(state: TpsData | undefined): boolean {
  if (!state) return false;
  if (!state.loadStore) return false;
  return isLoaded(state.loadStore.surveys);
}

function getSurveysLoadState(state: TpsData): LoadState {
  return state.loadStore.surveys;
}

export function getLoadState(state: TpsData, type: TPSRecordType): LoadState {
  switch (type) {
    case TPSRecordType.ASSESSMENT:
      return getAssessmentsLoadState(state);
    case TPSRecordType.ISSUE:
      return getIssueLoadState(state);
    case TPSRecordType.TASK:
      return getTaskLoadState(state);
    case TPSRecordType.CONSULT:
      return getConsultsLoadState(state);
    case TPSRecordType.SURVEY:
      return getSurveysLoadState(state);
    case TPSRecordType.ACTIVITY:
      return getActivitiesLoadState(state);
    default:
      return LoadState.ERROR;
  }
}

/**
 * This function provides a conveinient way to get the overall
 * state of the loading of all items. It works as follows:
 *  1. If ALL states are successful, then it returns SUCCESS
 *  2. If ANY of the states are LOADING, then it returns loading.
 *  3. If ANY of the state are ERROR, then it returns error.
 * @param state
 * @returns
 */
export function getOverallLoadState(state: TpsData): LoadState {
  const states = new Set([
    state.loadStore.activities,
    state.loadStore.assessments,
    state.loadStore.consults,
    state.loadStore.issues,
    state.loadStore.tasks,
    state.loadStore.surveys,
  ]);
  if (states.has(LoadState.ERROR)) return LoadState.ERROR;
  if (states.has(LoadState.LOADING)) return LoadState.LOADING;
  return LoadState.SUCCESS;
}

// --------------- Type checking ---------------
function isAssessment(object: any): object is Assessment {
  return 'questionnaires' in object;
}

function isSurvey(object: any): object is Survey {
  return 'progressPercentage' in object && !('expiresOn' in object);
}

function isIssue(object: any): object is Issue {
  return 'issues' in object;
}

function isQuestionnaire(object: any): object is Questionnaire {
  return 'typeId' in object || 'expiresOn' in object;
}

function isQuestionnaireMap(object: any): object is QuestionnaireMap {
  return 'questionnaire' in object;
}

function isComment(object: any): object is Activity {
  return 'currentValue' in object || 'previousValue' in object;
}

export function isEmail(object: any): object is Email {
  return 'recipients' in object || 'copied' in object;
}

export function getActivityOrEmailType(activity: Activity | Email | undefined): ActivityType {
  if (!activity) return ActivityType.NONE;
  if (isEmail(activity)) return ActivityType.EMAIL_SENT;

  return getActivityType(activity as Activity);
}

export function isIssueRecord(record: Issue | Task): record is Issue {
  return getTPSRecordType(record.tpsRecordId, record.tpsRecordType) === TPSRecordType.ISSUE;
}

export function getActivityType(activity: Activity): ActivityType {
  if (!activity) return ActivityType.NONE;

  switch (activityTypeToEnum(activity.type)) {
    case ActivityType.COMMENT:
    case ActivityType.ADDITIONAL_COMMENTS:
      return getCommentType(activity.tpsRecordType!);

    case ActivityType.STATE_CHANGE:
      return getStateChangeType(activity.tpsRecordType!);

    case ActivityType.USER_WATCHLIST:
      return getWatchListType(activity.tpsRecordType!, activity.currentValue);

    default:
      return activityTypeToEnum(activity.type, activity.text);
  }
}

/**
 * Determines the comment type based on the recordId passed in.
 * @param tpsRecordType
 */
export function getCommentType(tpsRecordType: string): ActivityType {
  switch (tpsRecordType) {
    case TPSRecordType.ASSESSMENT:
      return ActivityType.ASSESSMENT_COMMENT;
    case TPSRecordType.ISSUE:
      return ActivityType.ISSUE_COMMENT;
    case TPSRecordType.TASK:
      return ActivityType.TASK_COMMENT;
    case TPSRecordType.DDQ:
    case TPSRecordType.SECURITY_FOLLOWUP:
      return ActivityType.QUESTIONNAIRE_COMMENT;
    default:
      return ActivityType.COMMENT;
  }
}

/**
 * Determines the state_change type based on the recordId passed in.
 * @param tpsRecordType
 */
export function getStateChangeType(tpsRecordType: string): ActivityType {
  switch (tpsRecordType) {
    case TPSRecordType.ASSESSMENT:
      return ActivityType.ASSESSMENT_STATE_CHANGE;
    case TPSRecordType.ISSUE:
      return ActivityType.ISSUE_STATE_CHANGE;
    case TPSRecordType.TASK:
      return ActivityType.TASK_STATE_CHANGE;
    case TPSRecordType.CONSULT:
      return ActivityType.CONSULT_STATE_CHANGE;
    case TPSRecordType.DDQ:
    case TPSRecordType.SECURITY_FOLLOWUP:
      return ActivityType.QUESTIONNAIRE_STATE_CHANGE;
    default:
      return ActivityType.STATE_CHANGE;
  }
}

/**
 * Determines the watchlist type based on the recordId passed in.
 * @param tpsRecordType
 */
export function getWatchListType(tpsRecordType: string, currentValue: string): ActivityType {
  switch (tpsRecordType) {
    case TPSRecordType.ASSESSMENT: {
      if (currentValue === '') return ActivityType.USER_WATCHLIST_EMPTY;
      return ActivityType.USER_WATCHLIST;
    }
    case TPSRecordType.TASK: {
      if (currentValue === '') return ActivityType.USER_WATCHLIST_EMPTY_TASK;
      return ActivityType.USER_WATCHLIST_TASK;
    }
    default:
      return ActivityType.USER_WATCHLIST;
  }
}

function getRecordType(record: any): TPSRecordType {
  if (isAssessment(record)) return TPSRecordType.ASSESSMENT;
  else if (isSurvey(record)) return TPSRecordType.SURVEY;
  else if (isIssue(record)) return TPSRecordType.ISSUE;
  else if (isQuestionnaire(record) || isQuestionnaireMap(record)) return TPSRecordType.DDQ;
  else if (isComment(record)) return TPSRecordType.ACTIVITY;
  else return TPSRecordType.CONSULT;
}

function mapToNotification(rankedRecord: RankedData): NotificationItem {
  const record = rankedRecord.value;
  const text = rankedRecord.text ?? TakeActionType.UNRANKED;

  switch (getRecordType(record)) {
    case TPSRecordType.ASSESSMENT:
      const assessment = record as Assessment;
      return {
        tpsRecordId: assessment.tpsRecordId,
        tpsRecordSystemId: assessment.tpsRecordSystemId,
        takeActionType: text,
        // 21 days after creation start / creation date
        // 1814400 = 60sec * 60min * 24hrs * 21days
        expiresOnDate: assessment.startDate ? new Date(0).setUTCSeconds(assessment.startDate! + 1814400) : undefined,
        vendor: assessment.vendor,
        tier: tierToEnum(assessment.tier),
        personType: PersonType.USER,
        link: rankedRecord.link,
      };

    case TPSRecordType.SURVEY:
      const survey = record as Survey;
      return {
        tpsRecordId: survey.tpsRecordId,
        tpsRecordSystemId: survey.tpsRecordSystemId,
        takeActionType: text,
        vendor: survey.name,
        personType: PersonType.USER,
        link: rankedRecord.link,
      };

    case TPSRecordType.DDQ:
      const ddq = record as QuestionnaireMap;
      return {
        tpsRecordId: ddq.key,
        tpsRecordSystemId: ddq.questionnaire.tpsRecordSystemId,
        takeActionType: text,
        link: rankedRecord.link,
        vendor: ddq.vendor,
        tier: ddq.tier,
        questionnaireType: questionnaireType(ddq.questionnaire.type),
      };

    case TPSRecordType.ACTIVITY:
      return {
        tpsRecordId: rankedRecord.key,
        tpsRecordSystemId: rankedRecord.value.sysId ?? '',
        containedRecordId: rankedRecord.value.tpsRecordId ?? rankedRecord.key,
        takeActionType: text,
        link: rankedRecord.link,
        vendor: rankedRecord.value.vendor ?? '',
        tier: rankedRecord.value.tier ?? '',
        questionnaireType: questionnaireType(rankedRecord.value.ddqType),
      };

    default:
      const consult = record as Consult;
      return {
        tpsRecordId: consult.tpsRecordId,
        tpsRecordSystemId: consult.tpsRecordSystemId,
        takeActionType: text,
        vendor: consult.name,
        personType: PersonType.USER,
        link: rankedRecord.link,
      };
  }
}

// --------------- Determining Actionability ---------------
/**
 * Currently as of 08/20/23:
 * REQUESTER + ASSESSOR = 1P; but only Requester is used
 * VENDOR = 3P
 *
 * Link to Actionable Matrix: https://tiny.amazon.com/tnognkib/quipjQVNCXIm
 */

export function isClosedCompleteNa(state: State) {
  switch (state) {
    case State.ABANDONED:
    case State.CANCELLED:
    case State.COMPLETE:
    case State.CLOSED:
    case State.NOT_APPLICABLE:
      return true;
    default:
      return false;
  }
}

function isUserActionableOnAssessment(assessment: Assessment, user: UserMetadata): boolean {
  if (!(user.role === RoleType.REQUESTER || RoleType.ASSESSOR || user.role === RoleType.VENDOR)) return false;

  if (user.role === RoleType.VENDOR) {
    return (
      user.primaryContact ||
      assessment.thirdParty?.find((assignedUser) => user.email === assignedUser.primaryContactEmail) !== undefined
    );
  }

  return (
    assessment.assignedUsers?.find(
      (assignedUser) => actionable1pPersonaTypes.has(assignedUser.type) && user.email === assignedUser.emailAddress,
    ) !== undefined
  );
}

function isUserActionableOnIssue(issue: Issue, user: UserMetadata): boolean {
  if (!(user.role === RoleType.REQUESTER || RoleType.ASSESSOR || user.role === RoleType.VENDOR)) return false;

  if (user.role === RoleType.VENDOR) {
    return (
      user.primaryContact ||
      issue.assignedUsers.find((assignedUser) => user.email === assignedUser.emailAddress) !== undefined
    );
  }

  return (
    issue.assignedUsers?.find(
      (assignedUser) => actionable1pPersonaTypes.has(assignedUser.type) && user.email === assignedUser.emailAddress,
    ) !== undefined
  );
}

function isUserActionableOnQuestionnaire(questionnaire: Questionnaire, user: UserMetadata): boolean {
  if (!(user.role === RoleType.REQUESTER || RoleType.ASSESSOR || user.role === RoleType.VENDOR)) return false;

  if (user.role === RoleType.VENDOR) {
    return (
      user.primaryContact || (questionnaire.assignedUser && questionnaire.assignedUser.emailAddress === user.email)
    );
  }

  return (
    questionnaire.assignedUser &&
    actionable1pPersonaTypes.has(questionnaire.assignedUser.type) &&
    questionnaire.assignedUser.emailAddress === user.email
  );
}

//actionable as per this doc https://quip-amazon.com/jQVNAWZa2SKj/-CX-Improvements-My-Assessment-Portal-and-TPTA-RecordBRD-V2#temp:C:WFS67aa7acaec8663e900e2f2558
const takeActionActionableSet: Set<TakeActionType> = new Set([
  TakeActionType.HIGH_SEV_ISSUE_ACTIONABLE,
  TakeActionType.DDQ_ACTIONABLE,
  TakeActionType.SECURITY_QUESTIONNAIRE_INCOMPLETE,
  TakeActionType.TPTA_ACTIONABLE,
  TakeActionType.TASK_ACTIONABLE,
  TakeActionType.INTAKE_INCOMPLETE,
  TakeActionType.CONSULT_ACTIONABLE,
  TakeActionType.LOW_SEV_ISSUE_ACTIONABLE,
  TakeActionType.SURVEY_ACTIONABLE,
  TakeActionType.MED_SEV_ISSUE_ACTIONABLE,
]);

export function isTakeActionItemActionable(takeActionType: TakeActionType): boolean {
  return takeActionActionableSet.has(takeActionType);
}

// TPTA Actionable - intake also comes under this case where state is Awaiting Requester
function getAssessmentActionableState(assessment: Assessment, user: UserMetadata): ActionableState {
  const state = stateStrToEnum(assessment.state);

  if (isClosedCompleteNa(state)) return ActionableState.CLOSED_COMPLETE_NA;
  if (state === State.PAUSED) return ActionableState.PAUSED;
  if (!isUserActionableOnAssessment(assessment, user)) return ActionableState.NON_ACTIONABLE;

  if (user.role === RoleType.ASSESSOR && state === State.AWAITING_ASSESSOR) return ActionableState.ACTIONABLE;
  if (user.role === RoleType.REQUESTER && (state === State.AWAITING_REQUESTER || state === State.DRAFT))
    return ActionableState.ACTIONABLE;
  if (user.role === RoleType.VENDOR && state === State.AWAITING_THIRD_PARTY) return ActionableState.ACTIONABLE;
  return ActionableState.NON_ACTIONABLE;
}

export function isAssessmentActionable(assessment: Assessment, user: UserMetadata): boolean {
  return getAssessmentActionableState(assessment, user) === ActionableState.ACTIONABLE;
}

function getDdqActionableState(
  assessment: Assessment,
  questionnaire: Questionnaire,
  user: UserMetadata,
): ActionableState {
  if (stateStrToEnum(assessment.state) === State.PAUSED) return ActionableState.PAUSED;
  const state = stateStrToEnum(questionnaire.state);
  if (isClosedCompleteNa(state)) return ActionableState.CLOSED_COMPLETE_NA;
  if (!isUserActionableOnQuestionnaire(questionnaire, user)) return ActionableState.NON_ACTIONABLE;

  if (user.role === RoleType.ASSESSOR && state === State.AWAITING_ASSESSOR) return ActionableState.ACTIONABLE;
  if (user.role === RoleType.REQUESTER && state === State.AWAITING_REQUESTER) return ActionableState.ACTIONABLE;
  if (user.role === RoleType.VENDOR && (state === State.AWAITING_THIRD_PARTY || state === State.SENT_TO_THIRD_PARTY))
    return ActionableState.ACTIONABLE;

  return ActionableState.NON_ACTIONABLE;
}

export function isDdqActionable(assessment: Assessment, questionnaire: Questionnaire, user: UserMetadata): boolean {
  return getDdqActionableState(assessment, questionnaire, user) === ActionableState.ACTIONABLE;
}

export function getLatestDdq(questionnaires: Questionnaire[]): Questionnaire | null {
  if (questionnaires.length === 0) return null;
  let latestDdq = questionnaires[0];
  questionnaires.forEach((ddq) => {
    latestDdq = compareDates(latestDdq.dateCreated, ddq.dateCreated) > 0 ? latestDdq : ddq;
  });

  return latestDdq;
}

function getSurveyActionableState(survey: Survey): ActionableState {
  const state = stateStrToEnum(survey.state);
  if (isClosedCompleteNa(state)) return ActionableState.CLOSED_COMPLETE_NA;
  return ActionableState.ACTIONABLE;
}

export function isSurveyActionable(survey: Survey): boolean {
  return getSurveyActionableState(survey) === ActionableState.ACTIONABLE;
}

function getConsultActionableState(consult: Consult, user: UserMetadata): ActionableState {
  const state = stateStrToEnum(consult.state);
  if (isClosedCompleteNa(state)) return ActionableState.CLOSED_COMPLETE_NA;
  const isActionablePerson =
    consult.assignedUsers
      .filter((assignedUser) => assignedUser.type && assignedUser.type.toLowerCase() === UserType.REQUESTER)
      .find((assignedUser) => assignedUser.emailAddress === user.email) !== undefined;

  if (user.role === RoleType.ASSESSOR && state === State.AWAITING_ASSESSOR) return ActionableState.ACTIONABLE;
  if (user.role === RoleType.REQUESTER && state === State.AWAITING_REQUESTER && isActionablePerson)
    return ActionableState.ACTIONABLE;

  return ActionableState.NON_ACTIONABLE;
}

export function isConsultActionable(consult: Consult, user: UserMetadata): boolean {
  return getConsultActionableState(consult, user) === ActionableState.ACTIONABLE;
}

// TPRI
function getIssueActionableState(issue: Issue, user: UserMetadata): ActionableState {
  const state = stateStrToEnum(issue.state);
  if (isClosedCompleteNa(state)) return ActionableState.CLOSED_COMPLETE_NA;
  if (!isUserActionableOnIssue(issue, user)) return ActionableState.NON_ACTIONABLE;

  if (user.role === RoleType.ASSESSOR && state === State.AWAITING_ASSESSOR) return ActionableState.ACTIONABLE;
  if (user.role === RoleType.REQUESTER && state === State.AWAITING_REQUESTER) return ActionableState.ACTIONABLE;
  if (user.role === RoleType.VENDOR && state === State.AWAITING_THIRD_PARTY) return ActionableState.ACTIONABLE;

  return ActionableState.NON_ACTIONABLE;
}
const taskActionable1pPersonaTypes: Set<UserType> = new Set([
  UserType.REQUESTER,
  UserType.PRIMARY_OWNER,
  UserType.SECONDARY_OWNER,
]);

function getTaskActionableState(task: Task, user: UserMetadata): ActionableState {
  const state = stateStrToEnum(task.state);
  if (isClosedCompleteNa(state)) return ActionableState.CLOSED_COMPLETE_NA;
  const isActionablePerson =
    task.assignedUsers.find(
      (taskUser) => taskActionable1pPersonaTypes.has(taskUser.type) && user.email === taskUser.emailAddress,
    ) !== undefined;

  if (user.role === RoleType.ASSESSOR && state === State.AWAITING_ASSESSOR) return ActionableState.ACTIONABLE;
  if (user.role === RoleType.REQUESTER && state === State.AWAITING_REQUESTER && isActionablePerson)
    return ActionableState.ACTIONABLE;

  return ActionableState.NON_ACTIONABLE;
}

export function isIssueActionable(issue: Issue, user: UserMetadata): boolean {
  return getIssueActionableState(issue, user) === ActionableState.ACTIONABLE;
}

export function isTaskActionable(task: Task, user: UserMetadata): boolean {
  return getTaskActionableState(task, user) === ActionableState.ACTIONABLE;
}

export function getSecurityFollowupActionableState(questionnaire: Questionnaire, user: UserMetadata): ActionableState {
  const state = stateStrToEnum(questionnaire.state);
  if (isClosedCompleteNa(state)) return ActionableState.CLOSED_COMPLETE_NA;
  if (!isUserActionableOnQuestionnaire(questionnaire, user)) return ActionableState.NON_ACTIONABLE;

  if (user.role === RoleType.REQUESTER && (state === State.READY_TO_TAKE || state === State.IN_PROGRESS))
    return ActionableState.ACTIONABLE;

  return ActionableState.NON_ACTIONABLE;
}

export function isSecurityFollowupActionable(questionnaire: Questionnaire, user: UserMetadata): boolean {
  return getSecurityFollowupActionableState(questionnaire, user) === ActionableState.ACTIONABLE;
}

export function getActionableColor(actionableState: ActionableState): ActionableColor {
  switch (actionableState) {
    case ActionableState.ACTIONABLE:
      return { backgroundColor: RED, color: WHITE };
    case ActionableState.NON_ACTIONABLE:
      return { backgroundColor: YELLOW, color: BLACK };
    case ActionableState.PAUSED:
      return { backgroundColor: GRAY, color: BLACK };
    default:
      return { backgroundColor: DARK_GRAY, color: WHITE };
  }
}

export function getAssessmentLabelColor(assessment: Assessment, user: UserMetadata) {
  return getActionableColor(getAssessmentActionableState(assessment, user));
}

export function getDdqLabelColor(assessment: Assessment, questionnaire: Questionnaire, user: UserMetadata) {
  return getActionableColor(getDdqActionableState(assessment, questionnaire, user));
}

export function getConsultLabelColor(consult: Consult, user: UserMetadata) {
  return getActionableColor(getConsultActionableState(consult, user));
}

export function getIssueLabelColor(issue: Issue, user: UserMetadata) {
  return getActionableColor(getIssueActionableState(issue, user));
}

export function getTaskLabelColor(task: Task, user: UserMetadata) {
  return getActionableColor(getTaskActionableState(task, user));
}

export function getSecurityFollowupLabelColor(questionnaire: Questionnaire, user: UserMetadata) {
  return getActionableColor(getSecurityFollowupActionableState(questionnaire, user));
}

export function getSurveyLabelColor(survey: Survey) {
  return getActionableColor(getSurveyActionableState(survey));
}

export enum SortDirection {
  ASC,
  DESC,
}

// helper function to compare two dates
export function compareDates(
  dateA?: string | number | Date,
  dateB?: string | number | Date,
  direction?: SortDirection,
) {
  if (!dateA || !dateB) {
    if (!dateA && !dateB) return SortResult.NO_RECORD_WINS;
    if (!dateA) return SortResult.SECOND_RECORD_WINS;
    return SortResult.FIRST_RECORD_WINS;
  }

  if (direction && direction === SortDirection.DESC) return new Date(dateB).getTime() - new Date(dateA).getTime();

  return new Date(dateA).getTime() - new Date(dateB).getTime();
}

// --------------- Ranking Algorithm ---------------

export function getRankedNotifications(
  state: TpsData | undefined,
  user: UserMetadata,
  impersonationIntent: ImpersonationIntentType,
): NotificationItem[] {
  if (!state || !user) return [];
  return rankTpsRecords(state, user, impersonationIntent).map(mapToNotification);
}

// This algorithm is gathered from the following resource (08/20/23)
//    https://tiny.amazon.com/1jjebn85v/quipJ8iMCXSo
// It is a sorting algorithm that will be used by both the
//    - Todo list; the widget in the top right corner
//    - Take action list; the banner on top of all pages
function rankTpsRecords(state: TpsData, user: UserMetadata, impersonationIntent: ImpersonationIntentType) {
  const setKey = (id: string, index: number) => (!id || id === '' ? index.toString() : id);

  const assessments: Map<string, Assessment> = new Map();
  if (state.inProgressAssessments)
    state.inProgressAssessments.forEach((assessment, i) =>
      assessments.set(setKey(assessment.tpsRecordId, i), assessment),
    );

  const consults: Map<string, Consult> = new Map();
  if (state.consults) state.consults.forEach((consult, i) => consults.set(setKey(consult.tpsRecordId, i), consult));

  const surveys: Map<string, Survey> = new Map();
  if (state.surveys) state.surveys.forEach((survey, i) => surveys.set(setKey(survey.tpsRecordId, i), survey));

  const activities: Map<string, TpsRecordActivity> = new Map();
  if (state.activities)
    state.activities.forEach((activity, i) => activities.set(setKey(activity.tpsRecordId, i), activity));

  /**
   * preRankedData is used for additional tiebreaker sorting.
   * rankedData is the combination of all items while ranking.
   * combinedRankedData is after all the duplicates have been removed.
   */
  let preRankedData: RankedData[] = [];
  const rankedData: RankedData[] = [];
  const combinedRankedData: RankedData[] = [];

  // ------------------ HELPER FUNCTIONS ------------------
  /**
   * A convienence function to quickly add data. This is mainly
   * used so we can determine tiebreakers and group correctly
   * before returning the data. This grouping can be found at
   * the very bottom before the return statement.
   *
   * @param key
   * @param value
   */
  const addPreRankedData = (key: string, value: any, type: TakeActionType, link: string) => {
    preRankedData.push({ key: key, value: value, text: type, link: link });
  };

  /**
   * A convienence function to quickly add data to the (almost)
   * final ranked data. One more step is done at the bottom.
   */
  const addRankedData = () => {
    if (preRankedData.length === 0) return;

    if (isAssessment(preRankedData[0].value)) preRankedData.sort(assessmentTieBreakSort);

    preRankedData.forEach((item) => rankedData.push(item));
    preRankedData = [];
  };

  /**
   * In the case of a tie between two records (ex. two TPTAs in
   * Awaiting Requester) the one prioritized higher
   * is based on:
   * (1) If the TPTA is favorited it is higher priority
   * (2) The one with the closer project go live date
   *
   * @param a
   * @param b
   * @returns number
   */
  const assessmentTieBreakSort = (rankedDataA: RankedData, rankedDataB: RankedData): number => {
    const a = rankedDataA.value as Assessment;
    const b = rankedDataB.value as Assessment;

    if (a.isFavorited && !b.isFavorited) return SortResult.FIRST_RECORD_WINS;
    if (!a.isFavorited && b.isFavorited) return SortResult.SECOND_RECORD_WINS;

    if (!a.endDate && !b.endDate) return SortResult.NO_RECORD_WINS;
    if (a.endDate && !b.endDate) return SortResult.FIRST_RECORD_WINS;
    if (!a.endDate && b.endDate) return SortResult.SECOND_RECORD_WINS;

    const now = Date.now() / 1000;
    if (a.endDate! < now && b.endDate! < now) return SortResult.NO_RECORD_WINS; // Both already past due
    if (a.endDate! < now && b.endDate! > now) return SortResult.SECOND_RECORD_WINS;
    if (a.endDate! > now && b.endDate! < now) return SortResult.FIRST_RECORD_WINS;

    // The smaller one means that it is closer to now.
    if (a.endDate! < b.endDate!) return SortResult.FIRST_RECORD_WINS;
    if (a.endDate! > b.endDate!) return SortResult.SECOND_RECORD_WINS;

    return SortResult.NO_RECORD_WINS;
  };

  /**
   * Helper function to save some code. Accepts the issue and the
   * target severity.
   * @param issue
   * @param targetSeverity
   * @returns
   */
  const isIssuePastDue = (issue: Issue, targetSeverity: Priority): boolean => {
    if (!issue.severity || !issue.remediationSchedule) return false;
    if (!issue.remediationSchedule.dueDate) return false;
    if (severityStrToEnum(issue.severity) !== targetSeverity) return false;

    // We use UTC seconds, hence the division by 1000
    return Date.now() / 1000 > Number(issue.remediationSchedule.dueDate);
  };

  /**
   * A helper function that takes an Issue and the targeted
   * severity and determines if it's due within 30 days.
   *
   * @param issue
   * @param targetSeverity
   * @returns
   */
  const isIssueDueSoon = (issue: Issue, targetSeverity: Priority): boolean => {
    if (!issue.severity || !issue.remediationSchedule) return false;
    if (!issue.remediationSchedule.dueDate) return false;
    if (severityStrToEnum(issue.severity) !== targetSeverity) return false;

    // We use UTC seconds, hence the division by 1000
    // 2592000 = (60seconds * 60minutes * 24hours * 30days)
    const thirtyDays = 2592000;
    const secondsToDeadline = Number(issue.remediationSchedule.dueDate) - Date.now() / 1000;

    // > 0 means it hasn't already passed, and it's less than 30 days
    return secondsToDeadline > 0 && secondsToDeadline <= thirtyDays;
  };

  const addSeverityActionable = (assessment: Assessment, severity: Priority) => {
    if (!assessment.issues || assessment.issueCount === 0) return;

    const issues: Issue[] = [];
    assessment.issues.forEach((issue) => {
      if (severityStrToEnum(issue.severity) !== severity) return;

      if (isIssueActionable(issue, user)) issues.push(issue);
    });
    if (issues.length === 0) return;

    let takeActionType: TakeActionType;
    switch (severity) {
      case Priority.HIGH:
        takeActionType = TakeActionType.HIGH_SEV_ISSUE_ACTIONABLE;
        break;
      case Priority.MEDIUM:
        takeActionType = TakeActionType.MED_SEV_ISSUE_ACTIONABLE;
        break;
      default:
        takeActionType = TakeActionType.LOW_SEV_ISSUE_ACTIONABLE;
        break;
    }

    addPreRankedData(
      assessment.tpsRecordId,
      assessment,
      takeActionType,
      `${ISSUES_PAGE.href}?search=${assessment.tpsRecordId}`,
    );
  };

  const addTasksActionable = (assessment: Assessment) => {
    if (!assessment.tasks && assessment.taskCount === 0) return;

    const tasks: Task[] = [];
    assessment.tasks!.forEach((task) => {
      if (isTaskActionable(task, user)) tasks.push(task);
    });
    if (tasks.length === 0) return;

    addPreRankedData(
      assessment.tpsRecordId,
      assessment,
      TakeActionType.TASK_ACTIONABLE,
      `${TASKS_PAGE.href}?search=${assessment.tpsRecordId}`,
    );
  };

  const addSeverityPastDue = (assessment: Assessment, severity: Priority) => {
    if (!assessment.issues || assessment.issueCount === 0) return;

    const issues: Issue[] = [];
    assessment.issues.forEach((value) => {
      if (isIssuePastDue(value, severity)) issues.push(value);
    });
    if (issues.length === 0) return;

    let takeActionType: TakeActionType;
    switch (severity) {
      case Priority.HIGH:
        takeActionType = TakeActionType.HIGH_SEV_PAST_DUE;
        break;
      case Priority.MEDIUM:
        takeActionType = TakeActionType.MED_SEV_PAST_DUE;
        break;
      default:
        takeActionType = TakeActionType.LOW_SEV_PAST_DUE;
        break;
    }
    addPreRankedData(
      assessment.tpsRecordId,
      assessment,
      takeActionType,
      `${ISSUES_PAGE.href}?search=${assessment.tpsRecordId}`,
    );
  };

  const addSeverityDueSoon = (assessment: Assessment, severity: Priority) => {
    if (!assessment.issues || assessment.issueCount === 0) return;

    const issues: Issue[] = [];
    assessment.issues.forEach((value) => {
      if (isIssueDueSoon(value, severity)) issues.push(value);
    });
    if (issues.length === 0) return;

    let takeActionType: TakeActionType;
    switch (severity) {
      case Priority.HIGH:
        takeActionType = TakeActionType.HIGH_SEV_DUE_SOON;
        break;
      case Priority.MEDIUM:
        takeActionType = TakeActionType.MED_SEV_DUE_SOON;
        break;
      default:
        takeActionType = TakeActionType.LOW_SEV_DUE_SOON;
        break;
    }
    addPreRankedData(
      assessment.tpsRecordId,
      assessment,
      takeActionType,
      `${ISSUES_PAGE.href}?search=${assessment.tpsRecordId}`,
    );
  };

  const addQuestionnaireData = (questionnaireMap: QuestionnaireMap[], text: TakeActionType) => {
    if (questionnaireMap.length > 0) {
      const questionnaires = questionnaireMap.sort((a, b) => {
        if (!a.questionnaire.expiresOn && b.questionnaire.expiresOn) return SortResult.SECOND_RECORD_WINS;
        else if (a.questionnaire.expiresOn && !b.questionnaire.expiresOn) return SortResult.FIRST_RECORD_WINS;
        else if (a.questionnaire.expiresOn && b.questionnaire.expiresOn) {
          // Using division by 1000 for UTC seconds
          const aSecToExpiration = Date.now() / 1000 - a.questionnaire.expiresOn;
          const bSecToExpiration = Date.now() / 1000 - b.questionnaire.expiresOn;

          if (aSecToExpiration > 0 && bSecToExpiration > 0) return 0; // Both past expiration
          else if (aSecToExpiration < 0 && bSecToExpiration > 0)
            return SortResult.FIRST_RECORD_WINS; // A is due in the future
          else if (aSecToExpiration > 0 && bSecToExpiration < 0)
            return SortResult.SECOND_RECORD_WINS; // B is due in the future
          else if (aSecToExpiration < bSecToExpiration) return SortResult.SECOND_RECORD_WINS;
          else if (aSecToExpiration > bSecToExpiration) return SortResult.FIRST_RECORD_WINS;
          else return 0;
        }
        return 0;
      });

      questionnaires.forEach((record) => {
        addPreRankedData(
          record.key,
          record,
          text,
          getTPSPortalUrl({
            id: record.questionnaire.tpsRecordSystemId,
            linkType: questionnaireTypeToSnowUrlType(questionnaireTypeToEnum(record.questionnaire.type)),
            userRole: user.role,
            impersonationIntent,
            notesId: record.questionnaire.typeId,
          }),
        );
      });
    }
  };

  const addCommentData = (key: string, value: TpsRecordActivity, text: TakeActionType) => {
    if (!value.activities || value.activities.length === 0) return;
    const id = key.toLowerCase();

    // Check the type
    if (text === TakeActionType.TPTA_NEW_COMMENTS && !id.startsWith(TPSRecordIdPrefix.TPTA)) return;
    else if (text === TakeActionType.ISSUE_NEW_COMMENTS && !id.startsWith(TPSRecordIdPrefix.TPRI)) return;
    else if (text === TakeActionType.DDQ_NEW_COMMENTS && !id.startsWith(TPSRecordIdPrefix.AINST)) return;

    const activities = value.activities.filter((activity) => {
      if (activity.personType !== PersonType.USER) return false;
      else if (activity.personName === user.name) return false;
      else if (
        activityTypeToEnum(activity.type) === ActivityType.COMMENT ||
        activityTypeToEnum(activity.type) === ActivityType.ADDITIONAL_COMMENTS
      )
        return true;

      return false;
    });
    if (activities.length === 0) return;

    /**
     * Only keeping the first; per the algorithm:
     *
     * Only show 1 action item per record that has comments on it.
     * Do not show an action item for each comment. (ex. as a requester,
     * I have 3 new comments left on the TPTA by an assessor and there
     * is just 1 action item in the list for them).
     */
    const activity = activities.sort((a, b) => a.timestamp - b.timestamp)[0];
    let link = '';

    // For finding the containing assessment
    /**
     * TODO: Optimize. Maybe this ID map should be at a data provider level as this
     * sort of functionality is being used in several places across the code base.
     */
    const idMap: Map<string, Assessment> = new Map();
    assessments.forEach((assessment) => {
      idMap.set(assessment.tpsRecordId, assessment);
      if (assessment.questionnaires && assessment.questionnaires.length > 0) {
        assessment.questionnaires.forEach((record) => idMap.set(record.id, assessment));
      }
      if (assessment.issues && assessment.issues.length > 0) {
        assessment.issues.forEach((record) => {
          idMap.set(record.tpsRecordId, assessment);
        });
      }
    });

    let sysId = value.tpsRecordSystemId;
    let ddqType = '';
    switch (text) {
      case TakeActionType.TPTA_NEW_COMMENTS:
        link = getTPSPortalUrl({
          id: value.tpsRecordSystemId,
          linkType: SnowUrlType.ASSESSMENT,
          userRole: user.role,
          impersonationIntent,
        });
        break;

      case TakeActionType.ISSUE_NEW_COMMENTS:
        if (idMap.has(key)) {
          const issue = idMap.get(key)!.issues!.filter((issue) => issue.tpsRecordId === key)[0];
          sysId = issue.tpsRecordSystemId;
        }
        link = getTPSPortalUrl({
          id: sysId,
          linkType: SnowUrlType.ISSUE,
          userRole: user.role,
          impersonationIntent,
        });
        break;

      case TakeActionType.DDQ_NEW_COMMENTS:
        // If the TPTA is complete without
        if (!idMap.has(key)) return;
        const getDdqMetadata = (key: string, assessment: Assessment) => {
          const questionnaire = assessment.questionnaires!.find((questionnaire) => questionnaire.id === key);

          return { typeId: questionnaire?.typeId, type: questionnaireType(questionnaire?.type ?? '') };
        };

        const { typeId, type } = getDdqMetadata(value.tpsRecordId, idMap.get(key)!);
        ddqType = type;

        link = getTPSPortalUrl({
          id: value.tpsRecordSystemId,
          linkType: SnowUrlType.DDQ,
          userRole: user.role,
          impersonationIntent,
          notesId: typeId,
        });
        break;
    }

    /**
     * The ID map is composed of the TPS records IDs for in progress assessments. If the
     * TPS record ID is not found in the ID map, then we assume that this assessment is no longer
     * in progress and can be excluded from the activities.
     */
    if (!idMap.has(value.tpsRecordId)) return;

    const vendor = idMap.get(value.tpsRecordId)!.vendor;
    const tier = tierToEnum(idMap.get(value.tpsRecordId)!.tier);
    const tptaNum = !idMap.has(key) ? key : idMap.get(key)!.tpsRecordId;

    addPreRankedData(tptaNum, { ...activity, vendor: vendor, tier: tier, sysId: sysId, ddqType: ddqType }, text, link);
  };

  // ------------------ BEGIN ALGORITHM ------------------

  // 1. Issues with High Severity that are actionable by the persona
  assessments.forEach((value) => addSeverityActionable(value, Priority.HIGH));
  addRankedData();

  // 2. DDQ that is actionable by the persona
  const actionableDdqs: QuestionnaireMap[] = [];
  assessments.forEach((assessment, key) => {
    if (!assessment.questionnaires) return;
    assessment.questionnaires.forEach((questionnaire) => {
      const type = questionnaireTypeToEnum(questionnaire.type);
      if (type === QuestionnaireType.DDQ && isDdqActionable(assessment, questionnaire, user))
        actionableDdqs.push({
          key: key,
          questionnaire: questionnaire,
          vendor: assessment.vendor,
          tier: tierToEnum(assessment.tier),
        });
    });
  });
  addQuestionnaireData(actionableDdqs, TakeActionType.DDQ_ACTIONABLE);
  addRankedData();

  // 3. Security Follow-up questionnaire is incomplete
  const securityFollowups: QuestionnaireMap[] = [];
  assessments.forEach((value, key) => {
    if (!value.questionnaires) return;
    value.questionnaires.forEach((record) => {
      const type = questionnaireTypeToEnum(record.type);
      if (type !== QuestionnaireType.SECURITY_FOLLOWUP) return;
      else if (
        stateStrToEnum(record.state) === State.IN_PROGRESS ||
        stateStrToEnum(record.state) === State.READY_TO_TAKE
      )
        securityFollowups.push({ key: key, questionnaire: record, vendor: value.vendor, tier: tierToEnum(value.tier) });
    });
  });
  addQuestionnaireData(securityFollowups, TakeActionType.SECURITY_QUESTIONNAIRE_INCOMPLETE);
  addRankedData();

  // 4. TPTA that is actionable by the persona
  // https://quip-amazon.com/J8iMAeZe2sbk/CX-SortingRanking-Algorithm#temp:C:PWd18932d7732c145ab8850d75cf
  if (user.role === RoleType.REQUESTER) {
    assessments.forEach((assessment, key) => {
      // If Intake is already complete as of (09/01/23)
      if (tierToEnum(assessment.tier) === Tier.NONE) return;

      // If TPTA and DDQ are in the same state only show the action item for the DDQ
      // https://tiny.amazon.com/181u7xnsg/quipJ8iMCXSo
      if (
        assessment.questionnaires &&
        assessment.questionnaires.length > 0 &&
        rankedData.filter((data) => data.text === TakeActionType.DDQ_ACTIONABLE && data.key === key).length > 0
      )
        return;
      if (!isAssessmentActionable(assessment, user)) return;
      const openSecFollowups: Questionnaire[] =
        assessment.questionnaires
          ?.filter((record) => questionnaireTypeToEnum(record.type) === QuestionnaireType.SECURITY_FOLLOWUP)
          .filter(
            (record) =>
              stateStrToEnum(record.state) !== State.COMPLETE && stateStrToEnum(record.state) !== State.CANCELLED,
          ) ?? [];
      const ddqSameState: Questionnaire[] =
        assessment.questionnaires
          ?.filter((record) => questionnaireTypeToEnum(record.type) === QuestionnaireType.DDQ)
          .filter((record) => stateStrToEnum(record.state) === stateStrToEnum(assessment.state)) ?? [];

      if (
        (assessment.inheritedDdq && openSecFollowups.length === 0) ||
        (!assessment.inheritedDdq && ddqSameState.length === 0) ||
        (assessment.securityTeam &&
          assessment.securityTeam.toLowerCase() === 'healthsec' &&
          openSecFollowups.length === 0)
      ) {
        addPreRankedData(
          key,
          assessment,
          TakeActionType.TPTA_ACTIONABLE,
          getTPSPortalUrl({
            id: assessment.tpsRecordSystemId,
            linkType: SnowUrlType.ASSESSMENT,
            userRole: user.role,
            impersonationIntent,
          }),
        );
      }
    });
    addRankedData();
  }

  // 5. Task that is actionable by the persona
  assessments.forEach((assessment) => addTasksActionable(assessment));
  addRankedData();

  // 6. Intake incomplete
  if (user.role !== RoleType.VENDOR) {
    assessments.forEach((value, key) => {
      if (stateStrToEnum(value.state) === State.CANCELLED) return;
      if (tierToEnum(value.tier) !== Tier.NONE) return;

      addPreRankedData(
        key,
        value,
        TakeActionType.INTAKE_INCOMPLETE,
        getTPSPortalUrl({
          id: value.redirectUrlParams,
          linkType: SnowUrlType.INTAKE,
          userRole: user.role,
          impersonationIntent,
        }),
      );
    });
    addRankedData();
  }

  // 7. Consult that is actionable by the persona
  if (user.role !== RoleType.VENDOR) {
    const actionableConsults: Consult[] = Array.from(consults.values()).filter((consult) =>
      isConsultActionable(consult, user),
    );
    if (actionableConsults.length > 0) {
      addPreRankedData(
        actionableConsults[0].tpsRecordId,
        actionableConsults[0],
        TakeActionType.CONSULT_ACTIONABLE,
        CONSULTS_PAGE.href,
      );
      addRankedData();
    }
  }

  // 8. Survey that is actionable by the persona
  const actionableSurveys: Survey[] = Array.from(surveys.values()).filter((survey) => isSurveyActionable(survey));
  if (actionableSurveys.length > 0) {
    addPreRankedData(
      actionableSurveys[0].tpsRecordId,
      actionableSurveys[0],
      TakeActionType.SURVEY_ACTIONABLE,
      SURVEYS_PAGE.href,
    );
    addRankedData();
  }

  // 9. High Severity issue past due
  assessments.forEach((value) => addSeverityPastDue(value, Priority.HIGH));
  addRankedData();

  // 10. High Severity issue due soon (less than 30 days)
  assessments.forEach((value) => addSeverityDueSoon(value, Priority.HIGH));
  addRankedData();

  // 11. New comments left on DDQ
  activities.forEach((value, key) => addCommentData(key, value, TakeActionType.DDQ_NEW_COMMENTS));
  addRankedData();

  // 12. New comments left on TPTA
  activities.forEach((value, key) => addCommentData(key, value, TakeActionType.TPTA_NEW_COMMENTS));
  addRankedData();

  // 13. New comments left on Issue
  activities.forEach((value, key) => addCommentData(key, value, TakeActionType.ISSUE_NEW_COMMENTS));
  addRankedData();

  // 14. Issues with Medium Severity that is actionable by the persona
  assessments.forEach((value) => addSeverityActionable(value, Priority.MEDIUM));
  addRankedData();

  // 15. Issues with Low Severity that is actionable by the persona
  assessments.forEach((value) => addSeverityActionable(value, Priority.LOW));
  addRankedData();

  // 16. Medium Severity issue past due
  assessments.forEach((value) => addSeverityPastDue(value, Priority.MEDIUM));
  addRankedData();

  // 17. Low Severity issue past due
  assessments.forEach((value) => addSeverityPastDue(value, Priority.LOW));
  addRankedData();

  // 18. Medium Severity issue due soon (less than 30 days)
  assessments.forEach((value) => addSeverityDueSoon(value, Priority.MEDIUM));
  addRankedData();

  // 19. Low Severity issue due soon (less than 30 days)
  assessments.forEach((value) => addSeverityDueSoon(value, Priority.LOW));
  addRankedData();

  /**
   * Step below needed for this case:
   *
   * Only 1 action item for issues per TPTA based on the highest
   * importance scenario above (ex. as a vendor, I have 4 issues
   * in pending third party, 2 high sev, 1 medium, and 1 low, but
   * the only action item in my list should be #1 above unless the
   * rest of the Take Action list would be empty in which case I
   * could have #1, #14, and #15... as a requester.
   *
   * The ranked data at this point contains duplicate tpsRecordIds.
   * This step filters out and groups based on the data received here.
   * If there is more than 1 action item, then only the highest
   * priority item is kept. The dupeMap variable counts the number of
   * duplicate items.
   */
  const hasIssueMap: Map<string, boolean> = new Map();
  const isIssueAction = (text: string): boolean => {
    switch (text) {
      case TakeActionType.HIGH_SEV_ISSUE_ACTIONABLE:
      case TakeActionType.MED_SEV_ISSUE_ACTIONABLE:
      case TakeActionType.LOW_SEV_ISSUE_ACTIONABLE:
      case TakeActionType.HIGH_SEV_PAST_DUE:
      case TakeActionType.HIGH_SEV_DUE_SOON:
      case TakeActionType.MED_SEV_PAST_DUE:
      case TakeActionType.MED_SEV_DUE_SOON:
      case TakeActionType.LOW_SEV_PAST_DUE:
      case TakeActionType.LOW_SEV_DUE_SOON:
        return true;

      default:
        return false;
    }
  };

  rankedData.forEach((item) => {
    // If this TPTA already has an issue, skip
    if (isIssueAction(item.text) && hasIssueMap.has(item.key)) return;
    else if (isIssueAction(item.text) && !hasIssueMap.has(item.key)) {
      hasIssueMap.set(item.key, true);
      combinedRankedData.push(item);
    } else combinedRankedData.push(item);
  });

  return combinedRankedData.length === 1 ? rankedData : combinedRankedData;
}
