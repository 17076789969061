import Assessment, { UserType, State, TPSRecordType } from '../../data/RecordMetadata';

export const tasks: Assessment[] = [
  {
    assessmentOutcome: 'amazon_3p_security_bar_not_met',
    closureReasons: 'Reviewed',
    taskCount: 3,
    tasks: [
      {
        assignedUsers: [
          {
            emailAddress: 'ausnutt-test@outlook.com',
            userId: 'Austin ASR',
            type: UserType.REQUESTER,
          },
          {
            emailAddress: '',
            userId: '',
            type: UserType.PRIMARY_OWNER,
          },
        ],
        description:
          'The following requirement(s) is not implemented by the third party: \n4. Restrictions on reuse of previously used passwords\n5. User accounts locked out after ten or fewer unsuccessful login attempt\n8. Vendor default password removal/disabled/changed (before system goes to production)\n9. Passwords must never be embedded in code, configuration files, batch files, scripts, or any other mechanism that is discoverable by anyone\n',
        severity: '1 - High',
        state: State.COMPLETE,
        title:
          'Password Management Policy NOT in place The following requirement(s) is not implemented by the third party: \n4. Restrictions on reuse of previously used passwords\n5. User accounts locked out after ten or fewer unsuccessful login attempt\n8. Vendor default password removal/disabled/changed (before system goes to production)\n9. Passwords must never be embedded in code, configuration files, batch files, scripts, or any other mechanism that is discoverable by anyone\n',
        dueDate: 1700783999,
        tpsRecordId: 'TPRT0019657',
        tpsRecordSystemId: '3ee923624d5df910f79dc4c45850b17c',
        tpsRecordType: TPSRecordType.TASK,
      },
      {
        assignedUsers: [
          {
            emailAddress: 'ausnutt-test@outlook.com',
            userId: 'Austin ASR',
            type: UserType.REQUESTER,
          },
          {
            emailAddress: '',
            userId: '',
            type: UserType.PRIMARY_OWNER,
          },
        ],
        description:
          'The following requirement is not implemented by the third party: \nData must be encrypted in transit on internal and external networks (between host, network and services) using industry best practices.',
        dueDate: 1600783999,
        severity: '2 - Medium',
        state: State.AWAITING_REQUESTER,
        title: 'Data in Transit Encryption NOT fully implemented',
        tpsRecordId: 'TPRT0019655',
        tpsRecordSystemId: '6ed96f224d5df910f79dc4c45850b1b0',
        tpsRecordType: TPSRecordType.TASK,
      },
      {
        assignedUsers: [
          {
            emailAddress: 'ausnutt-test@outlook.com',
            userId: 'Austin ASR',
            type: UserType.PRIMARY_OWNER,
          },
          {
            emailAddress: '',
            userId: '',
            type: UserType.PRIMARY_OWNER,
          },
        ],
        description:
          'The following requirement is not implemented by the third party: \nDocument and maintain a written incident management policy and plan(s) aligned to industry best practices. Security incident must be reported in a timely manner and response must follow the documented policy and plan(s).',
        severity: 'Low',
        state: State.AWAITING_ASSESSOR,
        dueDate: 1500783999,
        title: 'Information Security Incident Management Plan NOT fully implemented',
        tpsRecordId: 'TPRT0019654',
        tpsRecordSystemId: '5cd9ab224d5df910f79dc4c45850b1f8',
        tpsRecordType: TPSRecordType.TASK,
      },
    ],
    name: 'Test Tier 3 Automation TPS Review',
    peerReview: 'Bar Raiser Review Not Required',
    state: State.COMPLETE,
    thirdPartyContact: 'CX UAT1 (cx.uat1a@yahoo.com)',
    tier: 3,
    tpsRecordId: 'TPTA0024129',
    tpsRecordSystemId: '6286a7ee94d1751053970809f3f70e52',
    tpsRecordType: TPSRecordType.TASK,
    vendor: 'CXTestRD',
  },
  {
    assessmentOutcome: 'amazon 3p security bar met with exceptions',
    closureReasons: 'Reviewed',
    taskCount: 21,
    tasks: [
      {
        assignedUsers: [
          {
            emailAddress: 'cxtestrd@gmail.com',
            userId: 'CXTest RD',
            type: UserType.TECHNICAL_OWNER,
          },
          {
            emailAddress: '',
            userId: '',
            type: UserType.LEVEL_8_OWNER,
          },
        ],
        description:
          'The following requirement(s) is not implemented by the third party: \n1. Access is provided based on least privilege "need to know" basis (both physical and logical access)\n2. User access reviews are performed at least once every 90 days\n3. User access (both physical and logical) is terminated no later than 24 hours from termination of personnel\n4. One unique account is assigned to each individual (not revealing level of access)\n5. Logical access attempts are logged, reviewed, and monitored (e.g. successful and failed logins) for applications, operating systems, databases, network devices, logs, and program source code\n6. Physical access is logged and reviewed (e.g. successful and failed access)\n7. Multi-factor authentication (MFA) is required for remote and privileged access (e.g. admin, firefight, etc.) along with logging and monitoring of access attempts\n8. Timeout is enforced for interactive sessions after specified period of inactivity (no longer than 15 minutes) and the users are required to re-authenticate to resume the session (workstations, servers, and applications)\n9. Timeout is enforced for non-interactive sessions (e.g, scripts and batch jobs) after specified period of inactivity (no longer than 24 hours) and re-authentication is required to initiate the session\n10. Shared / generic accounts are prohibited from interactive login\n',
        severity: 'Low',
        state: State.AWAITING_REQUESTER,
        dueDate: 1400783999,
        title: 'Access Control Policy NOT in place',
        tpsRecordId: 'TPRT0018580',
        tpsRecordSystemId: 'd8815434381d3510cc1cacf950aeaac1',
        tpsRecordType: TPSRecordType.TASK,
      },
      {
        assignedUsers: [
          {
            emailAddress: 'cxtestrd@gmail.com',
            userId: 'CXTest RD',
            type: UserType.PRIMARY_OWNER,
          },
          {
            emailAddress: '',
            userId: '',
            type: UserType.SECONDARY_OWNER,
          },
        ],
        description:
          'The following requirement is not implemented by the third party: \nChanges to production systems must be tracked, recorded, and reviewed before implementation and release along with relevant notification to stakeholders.',
        severity: '1 - High',
        state: State.AWAITING_ASSESSOR,
        dueDate: 1500783999,
        title: 'Change Management NOT fully implemented',
        tpsRecordId: 'TPRT0018570',
        tpsRecordSystemId: '7c4198f0381d3510cc1cacf950aeaaa1',
        tpsRecordType: TPSRecordType.TASK,
      },
      {
        assignedUsers: [
          {
            emailAddress: 'cxtestrd@gmail.com',
            userId: 'CXTest RD',
            type: UserType.LEVEL_8_OWNER,
          },
          {
            emailAddress: '',
            userId: '',
            type: UserType.REQUESTER,
          },
        ],
        description:
          'The following requirement is not implemented by the third party: \nPerform periodic internal and external penetration testing of systems that process Amazon Information to identify vulnerabilities and attack vectors that can be used to exploit those systems. Identified vulnerabilities must be addressed as part of Supplier’s vulnerability management program.',
        severity: '1 - High',
        state: State.AWAITING_REQUESTER,
        dueDate: 1500783999,
        title: 'Management of technical vulnerabilities NOT fully implemented',
        tpsRecordId: 'TPRT0018561',
        tpsRecordSystemId: '470150f0381d3510cc1cacf950aeaa4b',
        tpsRecordType: TPSRecordType.TASK,
      },
      {
        assignedUsers: [
          {
            emailAddress: 'cxtestrd@gmail.com',
            userId: 'CXTest RD',
            type: UserType.PRIMARY_OWNER,
          },
          {
            emailAddress: '',
            userId: '',
            type: UserType.LEVEL_8_OWNER,
          },
        ],
        description:
          'The following requirement is not implemented by the third party: \nData must be encrypted in transit on internal and external networks (between host, network and services) using industry best practices.',
        severity: '2 - Medium',
        state: State.AWAITING_REQUESTER,
        dueDate: 1700783999,
        title: 'Data in Transit Encryption NOT fully implemented',
        tpsRecordId: 'TPRT0018560',
        tpsRecordSystemId: '2d0110f0381d3510cc1cacf950aeaa71',
        tpsRecordType: TPSRecordType.TASK,
      },
    ],
    name: 'CXPa TPS Review',
    peerReview: 'Bar Raiser Review Not Required',
    state: State.COMPLETE,
    thirdPartyContact: 'CXTest RD (cxtestrd@gmail.com)',
    tier: 3,
    tpsRecordId: 'TPTA0024098',
    tpsRecordSystemId: 'f16d08b400d1b110da0d3a961bcf5b9e',
    tpsRecordType: TPSRecordType.TASK,
    vendor: 'CXTestRD',
  },
  {
    assessmentOutcome: 'amazon_3p_security_bar_bypassed_(exception_approved)',
    closureReasons: 'Reviewed',
    taskCount: 22,
    tasks: [
      {
        assignedUsers: [
          {
            emailAddress: 'ausnutt-test@outlook.com',
            userId: 'Austin ASR',
            type: UserType.PRIMARY_OWNER,
          },
          {
            emailAddress: '',
            userId: '',
            type: UserType.REQUESTER,
          },
        ],
        description:
          'The following requirement is not implemented by the third party: \nPerform periodic internal and external penetration testing of systems that process Amazon Information to identify vulnerabilities and attack vectors that can be used to exploit those systems. Identified vulnerabilities must be addressed as part of Supplier’s vulnerability management program.',
        severity: '1 - High',
        state: State.AWAITING_REQUESTER,
        dueDate: 1500783999,
        title: '',
        tpsRecordId: 'TPRT0019612',
        tpsRecordSystemId: '01da04394dd53910f79dc4c45850b1e3',
        tpsRecordType: TPSRecordType.TASK,
      },
      {
        assignedUsers: [
          {
            emailAddress: 'ausnutt-test@outlook.com',
            userId: 'Austin ASR',
            type: UserType.PRIMARY_OWNER,
          },
          {
            emailAddress: '',
            userId: '',
            type: UserType.REQUESTER,
          },
        ],
        description:
          'The following requirement is not implemented by the third party: \nData must be encrypted in transit on internal and external networks (between host, network and services) using industry best practices.',
        severity: '1 - High',
        state: State.AWAITING_REQUESTER,
        dueDate: 1900783999,
        title: 'Data in Transit Encryption NOT fully implemented',
        tpsRecordId: 'TPRT0019611',
        tpsRecordSystemId: 'e2cac0394dd53910f79dc4c45850b142',
        tpsRecordType: TPSRecordType.TASK,
      },
      {
        assignedUsers: [
          {
            emailAddress: 'ausnutt-test@outlook.com',
            userId: 'Austin ASR',
            type: UserType.PRIMARY_OWNER,
          },
          {
            emailAddress: '',
            userId: '',
            type: UserType.REQUESTER,
          },
        ],
        description:
          'The following requirement is not implemented by the third party: \nA Physical Security Program must be in place to cover the physical security of corporate offices and datacenter environments (such as IT equipment, entry and exit points security, CCTV logging and visitor access monitoring, etc.).',
        severity: '1 - High',
        state: State.AWAITING_REQUESTER,
        dueDate: 1600783999,
        title: 'Physical and Environmental Security NOT fully implemented',
        tpsRecordId: 'TPRT0019610',
        tpsRecordSystemId: 'ccca40394dd53910f79dc4c45850b1b9',
        tpsRecordType: TPSRecordType.TASK,
      },
    ],
    name: 'CXP UAT TPS Review',
    peerReview: 'Bar Raiser Review Not Required',
    state: State.AWAITING_REQUESTER,
    thirdPartyContact: 'CXTest RD (cxtestrd@gmail.com)',
    tier: 3,
    tpsRecordId: 'TPTA0024089',
    tpsRecordSystemId: '3d30f91800597110da0d3a961bcf5be4',
    tpsRecordType: TPSRecordType.TASK,
    vendor: 'CXTestRD',
  },
  {
    assessmentOutcome: 'amazon 3p security bar met with exceptions',
    closureReasons: 'Reviewed',
    taskCount: 21,
    tasks: [
      {
        assignedUsers: [
          {
            emailAddress: 'cxtestrd@gmail.com',
            userId: 'CXTest RD',
            type: UserType.TECHNICAL_OWNER,
          },
          {
            emailAddress: '',
            userId: '',
            type: UserType.LEVEL_8_OWNER,
          },
        ],
        description:
          'The following requirement(s) is not implemented by the third party: \n1. Access is provided based on least privilege "need to know" basis (both physical and logical access)\n2. User access reviews are performed at least once every 90 days\n3. User access (both physical and logical) is terminated no later than 24 hours from termination of personnel\n4. One unique account is assigned to each individual (not revealing level of access)\n5. Logical access attempts are logged, reviewed, and monitored (e.g. successful and failed logins) for applications, operating systems, databases, network devices, logs, and program source code\n6. Physical access is logged and reviewed (e.g. successful and failed access)\n7. Multi-factor authentication (MFA) is required for remote and privileged access (e.g. admin, firefight, etc.) along with logging and monitoring of access attempts\n8. Timeout is enforced for interactive sessions after specified period of inactivity (no longer than 15 minutes) and the users are required to re-authenticate to resume the session (workstations, servers, and applications)\n9. Timeout is enforced for non-interactive sessions (e.g, scripts and batch jobs) after specified period of inactivity (no longer than 24 hours) and re-authentication is required to initiate the session\n10. Shared / generic accounts are prohibited from interactive login\n',
        severity: 'Low',
        state: State.AWAITING_REQUESTER,
        dueDate: 1400783999,
        title: 'Access Control Policy NOT in place',
        tpsRecordId: 'TPRT0018580',
        tpsRecordSystemId: 'd8815434381d3510cc1cacf950aeaac1',
        tpsRecordType: TPSRecordType.TASK,
      },
      {
        assignedUsers: [
          {
            emailAddress: 'cxtestrd@gmail.com',
            userId: 'CXTest RD',
            type: UserType.PRIMARY_OWNER,
          },
          {
            emailAddress: '',
            userId: '',
            type: UserType.SECONDARY_OWNER,
          },
        ],
        description:
          'The following requirement is not implemented by the third party: \nChanges to production systems must be tracked, recorded, and reviewed before implementation and release along with relevant notification to stakeholders.',
        severity: '1 - High',
        state: State.AWAITING_ASSESSOR,
        dueDate: 1500783999,
        title: 'Change Management NOT fully implemented',
        tpsRecordId: 'TPRT0018570',
        tpsRecordSystemId: '7c4198f0381d3510cc1cacf950aeaaa1',
        tpsRecordType: TPSRecordType.TASK,
      },
      {
        assignedUsers: [
          {
            emailAddress: 'cxtestrd@gmail.com',
            userId: 'CXTest RD',
            type: UserType.LEVEL_8_OWNER,
          },
          {
            emailAddress: '',
            userId: '',
            type: UserType.REQUESTER,
          },
        ],
        description:
          'The following requirement is not implemented by the third party: \nPerform periodic internal and external penetration testing of systems that process Amazon Information to identify vulnerabilities and attack vectors that can be used to exploit those systems. Identified vulnerabilities must be addressed as part of Supplier’s vulnerability management program.',
        severity: '1 - High',
        state: State.AWAITING_REQUESTER,
        dueDate: 1500783999,
        title: 'Management of technical vulnerabilities NOT fully implemented',
        tpsRecordId: 'TPRT0018561',
        tpsRecordSystemId: '470150f0381d3510cc1cacf950aeaa4b',
        tpsRecordType: TPSRecordType.TASK,
      },
      {
        assignedUsers: [
          {
            emailAddress: 'cxtestrd@gmail.com',
            userId: 'CXTest RD',
            type: UserType.PRIMARY_OWNER,
          },
          {
            emailAddress: '',
            userId: '',
            type: UserType.LEVEL_8_OWNER,
          },
        ],
        description:
          'The following requirement is not implemented by the third party: \nData must be encrypted in transit on internal and external networks (between host, network and services) using industry best practices.',
        severity: '2 - Medium',
        state: State.AWAITING_REQUESTER,
        dueDate: 1700783999,
        title: 'Data in Transit Encryption NOT fully implemented',
        tpsRecordId: 'TPRT0018560',
        tpsRecordSystemId: '2d0110f0381d3510cc1cacf950aeaa71',
        tpsRecordType: TPSRecordType.TASK,
      },
    ],
    name: 'CXPa TPS Review',
    peerReview: 'Bar Raiser Review Not Required',
    state: State.COMPLETE,
    thirdPartyContact: 'CXTest RD (cxtestrd@gmail.com)',
    tier: 3,
    tpsRecordId: 'TPTA0024099',
    tpsRecordSystemId: 'f16d08b400d1b110da0d3a961bcf5b9e',
    tpsRecordType: TPSRecordType.TASK,
    vendor: 'CXTestRD',
  },
  {
    assessmentOutcome: 'amazon_3p_security_bar_met',
    closureReasons: 'Reviewed',
    taskCount: 22,
    tasks: [
      {
        assignedUsers: [
          {
            emailAddress: 'ausnutt-test@outlook.com',
            userId: 'Austin ASR',
            type: UserType.PRIMARY_OWNER,
          },
          {
            emailAddress: '',
            userId: '',
            type: UserType.REQUESTER,
          },
        ],
        description:
          'The following requirement is not implemented by the third party: \nPerform periodic internal and external penetration testing of systems that process Amazon Information to identify vulnerabilities and attack vectors that can be used to exploit those systems. Identified vulnerabilities must be addressed as part of Supplier’s vulnerability management program.',
        severity: '1 - High',
        state: State.AWAITING_REQUESTER,
        dueDate: 1500783999,
        title: 'Management of technical vulnerabilities NOT fully implemented',
        tpsRecordId: 'TPRT0019613',
        tpsRecordSystemId: '01da04394dd53910f79dc4c45850b1e5',
        tpsRecordType: TPSRecordType.TASK,
      },
      {
        assignedUsers: [
          {
            emailAddress: 'ausnutt-test@outlook.com',
            userId: 'Austin ASR',
            type: UserType.PRIMARY_OWNER,
          },
          {
            emailAddress: '',
            userId: '',
            type: UserType.REQUESTER,
          },
        ],
        description:
          'The following requirement is not implemented by the third party: \nData must be encrypted in transit on internal and external networks (between host, network and services) using industry best practices.',
        severity: '1 - High',
        state: State.AWAITING_REQUESTER,
        dueDate: 1900783999,
        title: 'Data in Transit Encryption NOT fully implemented',
        tpsRecordId: 'TPRT0019614',
        tpsRecordSystemId: 'e2cac0394dd53910f79dc4c45850b155',
        tpsRecordType: TPSRecordType.TASK,
      },
      {
        assignedUsers: [
          {
            emailAddress: 'ausnutt-test@outlook.com',
            userId: 'Austin ASR',
            type: UserType.PRIMARY_OWNER,
          },
          {
            emailAddress: '',
            userId: '',
            type: UserType.REQUESTER,
          },
        ],
        description:
          'The following requirement is not implemented by the third party: \nA Physical Security Program must be in place to cover the physical security of corporate offices and datacenter environments (such as IT equipment, entry and exit points security, CCTV logging and visitor access monitoring, etc.).',
        severity: '1 - High',
        state: State.AWAITING_REQUESTER,
        dueDate: 1600783999,
        title: 'Physical and Environmental Security NOT fully implemented',
        tpsRecordId: 'TPRT0019616',
        tpsRecordSystemId: 'ccca40394dd53910f79dc4c45850b1c0',
        tpsRecordType: TPSRecordType.TASK,
      },
    ],
    name: 'CXP UAT TPS Review',
    peerReview: 'Bar Raiser Review Not Required',
    state: State.AWAITING_REQUESTER,
    thirdPartyContact: 'CXTest RD (cxtestrd@gmail.com)',
    tier: 3,
    tpsRecordId: 'TPTA0024070',
    tpsRecordSystemId: '3d30f91800597110da0d3a961bcf5bc5',
    tpsRecordType: TPSRecordType.TASK,
    vendor: 'CXTestRD',
  },
  //added below tasks data for Tasks Button test on TPTA Card change Task Record State to see Count and position change, actionability
  //change Assessment RecordID to test conditions where TPTA had issues and questionnarie, any one present(issue or questionnaire), both are not present
  {
    assessmentOutcome: 'amazon_3p_security_bar_met',
    closureReasons: 'Reviewed',
    taskCount: 22,
    tasks: [
      {
        assignedUsers: [
          {
            emailAddress: 'ausnutt-test@outlook.com',
            userId: 'Austin ASR',
            type: UserType.PRIMARY_OWNER,
          },
          {
            emailAddress: '',
            userId: '',
            type: UserType.REQUESTER,
          },
        ],
        description:
          'The following requirement is not implemented by the third party: \nPerform periodic internal and external penetration testing of systems that process Amazon Information to identify vulnerabilities and attack vectors that can be used to exploit those systems. Identified vulnerabilities must be addressed as part of Supplier’s vulnerability management program.',
        severity: '1 - High',
        state: State.COMPLETE, //change this one to test count and position of tasks button on TPTA Card
        dueDate: 1500783999,
        title: 'Management of technical vulnerabilities NOT fully implemented',
        tpsRecordId: 'TPRT0019613',
        tpsRecordSystemId: '01da04394dd53910f79dc4c45850b1e5',
        tpsRecordType: TPSRecordType.TASK,
      },
      {
        assignedUsers: [
          {
            emailAddress: 'ausnutt-test@outlook.com',
            userId: 'Austin ASR',
            type: UserType.PRIMARY_OWNER,
          },
          {
            emailAddress: '',
            userId: '',
            type: UserType.REQUESTER,
          },
        ],
        description:
          'The following requirement is not implemented by the third party: \nData must be encrypted in transit on internal and external networks (between host, network and services) using industry best practices.',
        severity: '1 - High',
        state: State.COMPLETE,
        dueDate: 1900783999,
        title: 'Data in Transit Encryption NOT fully implemented',
        tpsRecordId: 'TPRT0019614',
        tpsRecordSystemId: 'e2cac0394dd53910f79dc4c45850b155',
        tpsRecordType: TPSRecordType.TASK,
      },
      {
        assignedUsers: [
          {
            emailAddress: 'ausnutt-test@outlook.com',
            userId: 'Austin ASR',
            type: UserType.PRIMARY_OWNER,
          },
          {
            emailAddress: '',
            userId: '',
            type: UserType.REQUESTER,
          },
        ],
        description:
          'The following requirement is not implemented by the third party: \nA Physical Security Program must be in place to cover the physical security of corporate offices and datacenter environments (such as IT equipment, entry and exit points security, CCTV logging and visitor access monitoring, etc.).',
        severity: '1 - High',
        state: State.COMPLETE,
        dueDate: 1600783999,
        title: 'Physical and Environmental Security NOT fully implemented',
        tpsRecordId: 'TPRT0019616',
        tpsRecordSystemId: 'ccca40394dd53910f79dc4c45850b1c0',
        tpsRecordType: TPSRecordType.TASK,
      },
    ],
    name: 'CXP UAT TPS Review',
    peerReview: 'Bar Raiser Review Not Required',
    state: State.COMPLETE,
    thirdPartyContact: 'CXTest RD (cxtestrd@gmail.com)',
    tier: 1,
    tpsRecordId: 'TPTA0025660', //change this RecordId to test position of tasks button on Dashboard Page
    tpsRecordSystemId: '874a827a086571107c34be7fac9c1c79',
    tpsRecordType: TPSRecordType.TASK,
    vendor: 'CXTestRD',
  },
];
