import React from 'react';
import { WithBundleProps, withBundle } from '@amzn/react-arb-tools';
import MuiTypography from '@mui/material/Typography';
import LoadingSpinner from '../../components/LoadingComponents/LoadingSpinner';
import { clearSessionAuth } from '../../utils/LogoutUtils';
import { useUserContext } from '../../context/UserProvider';
import { RoleType } from '../../data/UserMetadata';
import { useQuery } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';
import { retryConfig, cacheConfig } from '../../apis/ApiUtils';
import { getSnowDomainName } from '../../utils/redirectionUtils';
import { LOGGING_OUT, TRUE } from '../../constants/constants';

// logout functionality for 1P user.
// Since 1P is authenticated via Midway SSO,
// and we want to avoid signing them out from Midway,
// this logout logic only clears local cookies, cache and storage
// then redirects to the TPS homepage /tps
function LogoutPage({ bundle }: WithBundleProps) {
  const { role: userRole } = useUserContext();

  if (userRole !== RoleType.VENDOR) {
    window.localStorage.setItem(LOGGING_OUT, TRUE);
    clearSessionAuth();
    window.localStorage.removeItem(LOGGING_OUT);

    window.location.href = `${getSnowDomainName('beta')}/tps`;
  } else {
    // For 3P, sign out from cognito session
    // then clear local cookies, cache and storage
    // Redirect to the ID PRISM sign in page is handled by the cognito signout url
    useQuery({
      queryKey: ['signOutKey'],
      queryFn: async () => {
        await Auth.signOut();
        clearSessionAuth();
      },
      ...retryConfig,
      ...cacheConfig,
    });
  }

  return (
    <React.Fragment>
      <MuiTypography>{bundle.getMessage('signout_text')}</MuiTypography>
      <LoadingSpinner />
    </React.Fragment>
  );
}

export default withBundle('pages.LogoutPage')(LogoutPage);
