import { RoleType } from '../data/UserMetadata';
import { ActivityType, Activity, TPSRecordType } from '../data/RecordMetadata';
import { activityTypeToEnum, getTPSRecordType } from '../utils/enumUtils';
import { getCommentType, getStateChangeType, getWatchListType } from '../utils/dataUtils';

const thirdPartyVisibilitySet = new Set<ActivityType>([
  ActivityType.QUESTIONNAIRE_STATE_CHANGE,
  ActivityType.ISSUE_STATE_CHANGE,
  ActivityType.QUESTIONNAIRE_COMMENT,
  ActivityType.ISSUE_COMMENT,
  ActivityType.PERCENTAGE_ANSWERED,
  ActivityType.ASSESSMENT_OUTCOME,
]);

function activityLogVisibility(activityType: ActivityType, userRole: RoleType): boolean {
  if (userRole === RoleType.VENDOR) {
    if (thirdPartyVisibilitySet.has(activityType)) {
      return true;
    }

    return false;
  }

  return true;
}

export function filterActivity(
  activity: Activity,
  role: RoleType,
  tpsRecordId: string,
  tpsRecordType: string,
): boolean {
  let tempActivityType: ActivityType = activityTypeToEnum(activity.type, activity.text);
  const recordType: TPSRecordType = getTPSRecordType(tpsRecordId, tpsRecordType);
  let isCommentType = false;

  if (tempActivityType === ActivityType.DDQ_INHERITED_BY_TPTA && activity.currentValue === 'false') return false;

  if (tempActivityType === ActivityType.COMMENT) {
    tempActivityType = getCommentType(recordType);
    isCommentType = true;
  }

  if (tempActivityType === ActivityType.STATE_CHANGE) tempActivityType = getStateChangeType(recordType);

  if (tempActivityType === ActivityType.USER_WATCHLIST)
    tempActivityType = getWatchListType(recordType, activity.currentValue);

  if (!activityLogVisibility(tempActivityType, role)) return false;

  if (isCommentType) {
    activity.tpsRecordId = tpsRecordId;
  }

  return true;
}

export default filterActivity;
