import { createTheme } from '@mui/material/styles';
import {
  BREAKPOINT,
  BLUE_BLACK,
  DARK_GRAY,
  GRAY,
  LIGHT_GRAY,
  WHITE,
  BLACK,
  PRIMARY_BLUE,
  SECONDARY_BLUE,
  TERTIARY_BLUE,
  AMAZON_ORANGE,
  DARK_ORANGE,
  FADED_RED,
  LINK_BLUE,
  MINT_GREEN,
  RED,
  VENDOR_PURPLE,
  YELLOW,
  REQUESTER_GREEN,
  AMAZON_EMBER,
} from '../constants/constants'; //we can replace this constants with value later so theme will be only one source of truth for styling
import AmazonEmber_Rg from '../assets/Fonts/AmazonEmber_Rg.ttf'; //regular Amazon Ember Font

//Before adding any customizations please follow below articles and instructions
//https://tiny.amazon.com/752ne1x7/muimatecustthemcus - Information about Mui components customization by adding custom variables
//https://tiny.amazon.com/13bt3z053/typeorgdocshanddeclhtml - TypeScript's Module Augmentation to patch existing declarations only applies to named exports of a module
//module augmentation to override default options provided by Material UI's Theme for each feature
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false; // removes the `default` breakpoint set by theme by keeping it as false
    sm: false;
    md: false;
    lg: false;
    xl: false;
    MAPMinimumZoomResolution: true;
    MAPMinimumResolution: true; // adds the `map` breakpoint
    MAPMediumResolution: true;
    MAPMaximumResolution: true;
  }

  interface PaletteColor {
    primary?: string;
    backDrop?: string;
  }

  interface SimplePaletteColorOptions {
    primary?: string;
    backDrop?: string;
  }

  //adding custom color
  interface Palette {
    vendorColor: Palette['primary'];
  }

  interface PaletteOptions {
    vendorColor?: PaletteOptions['primary'];
  }
}

//theme context for Mui Components which is source of truth for styling needs of this application
const theme = createTheme({
  components: {
    MuiCardContent: {
      //Name of the component which we want to customize
      styleOverrides: {
        //key to potentially change every single style injects by Material UI into the DOM
        root: {
          //slot name or class name inside customizing component - each MuiComponent will have several parts and part will have a class name. So use Components class name(slot in other words) to override default style of MuiComponent
          '&&': {
            //&& is for more CSS specificity for Mui component style overrides
            padding: '10px',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: ${AMAZON_EMBER};
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: local(${AMAZON_EMBER}), local(${AMAZON_EMBER}), url(${AmazonEmber_Rg}) format('ttf');
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      }
    `,
    },
  },
  breakpoints: {
    values: BREAKPOINT,
  },
  palette: {
    primary: {
      //primary colors of this portal
      light: TERTIARY_BLUE,
      main: LINK_BLUE,
      dark: SECONDARY_BLUE,
      primary: PRIMARY_BLUE,
      backDrop: BLUE_BLACK,
      contrastText: WHITE,
    },
    secondary: {
      //non actionable
      light: YELLOW,
      main: AMAZON_ORANGE,
      dark: DARK_ORANGE,
      contrastText: BLACK,
    },
    info: {
      //closed and complete state text
      light: LIGHT_GRAY,
      main: GRAY,
      dark: DARK_GRAY,
      contrastText: WHITE,
    },
    error: {
      //actionable
      light: FADED_RED,
      main: RED,
      dark: RED, //add Dark RED value in future
      contrastText: WHITE,
    },
    success: {
      //successful assessment outcome
      light: REQUESTER_GREEN,
      main: MINT_GREEN,
      dark: MINT_GREEN, //add Dark RED value in future
      contrastText: WHITE,
    },
    vendorColor: {
      //custom color for vendor persona
      light: VENDOR_PURPLE,
      main: VENDOR_PURPLE, //add shaded to it
      dark: VENDOR_PURPLE,
      contrastText: BLACK,
    },
    text: {
      primary: BLACK,
      secondary: GRAY,
      disabled: WHITE,
    },
  },
  typography: {
    fontFamily: AMAZON_EMBER,
  },
});

export default theme;
