import { RoleType, UserMetadata } from '../data/UserMetadata';
import Assessment, { QuestionnaireType, State, SortResult } from '../data/RecordMetadata';
import { questionnaireTypeToEnum, stateStrToEnum } from './enumUtils';
import {
  isAssessmentActionable,
  isDdqActionable,
  isSecurityFollowupActionable,
  isIssueActionable,
  compareDates,
  SortDirection,
  isTaskActionable,
} from './dataUtils';

//function address for TPTA Card Vendor or view red text
//https://tiny.amazon.com/hduqbwx0/quipjQVNCXIm
export function isAwaitingRequesterState(assessment: Assessment, user: UserMetadata): boolean {
  if (user.role !== RoleType.REQUESTER || stateStrToEnum(assessment.state) !== State.AWAITING_REQUESTER) return false; //vendor name and view text red only for requester persona

  const assessmentActionable: boolean = isAssessmentActionable(assessment, user);

  if (!assessmentActionable) return false;

  if (assessment.questionnaires) {
    for (const questionnaire of assessment.questionnaires) {
      if (
        questionnaireTypeToEnum(questionnaire.type) === QuestionnaireType.DDQ &&
        isDdqActionable(assessment, questionnaire, user)
      )
        return false;
    }
  }

  return true;
}

//had actionable actionable issues or DDQ or Security Follow-up
//https://tiny.amazon.com/bodc0sx9/quipjQVNCXIm
export function hasActionableIssue(assessment: Assessment, user: UserMetadata): boolean {
  //kept it first for issues management lane mostly focuses on view issues button
  if (user.role !== RoleType.REQUESTER && assessment.issues) {
    for (const issue of assessment.issues) {
      if (isIssueActionable(issue, user)) return true; //actionable issues if present for both vendor & assessor
    }
  }
  return false;
}

export function hasActionableTask(assessment: Assessment, user: UserMetadata): boolean {
  if (user.role === RoleType.REQUESTER && assessment.tasks) {
    for (const task of assessment.tasks) {
      if (isTaskActionable(task, user)) return true; //actionable tasks present for Requester persona
    }
  }
  return false;
}
//had actionable actionable issues or DDQ or Security Follow-up
//https://tiny.amazon.com/37hfou96/quipjQVNCXIm
//https://tiny.amazon.com/x5am0kys/quipjQVNCXIm
export function hasActionableDDQorSecurityFollowup(assessment: Assessment, user: UserMetadata): boolean {
  if (assessment.questionnaires) {
    for (const questionnaire of assessment.questionnaires) {
      if (questionnaireTypeToEnum(questionnaire.type) === QuestionnaireType.DDQ) {
        if (isDdqActionable(assessment, questionnaire, user)) return true; //atleast one actionable DDQ with red text
      } else if (questionnaireTypeToEnum(questionnaire.type) === QuestionnaireType.SECURITY_FOLLOWUP) {
        if (isSecurityFollowupActionable(questionnaire, user)) return true; //atleast one actionable Security with red text
      }
    }
  }

  return false;
}

//this sort result is specific to Dashboard Cards to pass over to next actionability function check
enum AdditionalTPTASortResult {
  NO_DECISION_MADE = 2,
}

type TPTASortResult = SortResult | AdditionalTPTASortResult;

export function actionabilityCheck(
  a: Assessment,
  b: Assessment,
  user: UserMetadata,
  actionabilityFn: any,
): TPTASortResult {
  const aActionabilityCheck = actionabilityFn(a, user);
  const bActionabilityCheck = actionabilityFn(b, user);

  if (aActionabilityCheck && !bActionabilityCheck) return SortResult.FIRST_RECORD_WINS;
  if (!aActionabilityCheck && bActionabilityCheck) return SortResult.SECOND_RECORD_WINS;

  if (aActionabilityCheck && bActionabilityCheck) return compareDates(a.startDate, b.startDate, SortDirection.DESC); //sort order condition 3 which returns one of the -1, 0, 1

  return AdditionalTPTASortResult.NO_DECISION_MADE; //when none of assessment passed actionability function check's sending 2 number so assessments can go through another check until we fall into condition 3
}

//Assessments are sorted based on following order as TPTA Card actionable text is in the order red vendor or view text, state bubble, issues button, Questionnaire's
//Array of function's placed based on below sorting order
const actionabilityCheckFnArr = [
  isAwaitingRequesterState,
  isAssessmentActionable,
  hasActionableIssue,
  hasActionableDDQorSecurityFollowup,
  hasActionableTask,
];

/**
 * Sort order:
 * (1) Favorites (not done here)
 * (2) Actionable - (Red vendor & view text) - (Red state bubbles) - (Red issues) - (Red DDQ's)
 * (3) TPTA creation date
 *
 * Note: we don't handle the Favorites here because we have a util
 * function that handles favorites.
 */
function sortTPTAByActionability(a: Assessment, b: Assessment, user: UserMetadata): TPTASortResult {
  for (const checkingFunction of actionabilityCheckFnArr) {
    const checkResult: TPTASortResult = actionabilityCheck(a, b, user, checkingFunction);

    //if 2 value is received assessment will go for next actionability function check
    if (checkResult !== AdditionalTPTASortResult.NO_DECISION_MADE) return checkResult;
  }

  //No actionable text on TPTA then sort by start date as per sort order condition 3
  return compareDates(a.startDate, b.startDate, SortDirection.DESC);
}

export default sortTPTAByActionability;
