import React from 'react';

export const StarSvg = () => (
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3904_40698)">
      <path
        d="M13.3504 9.63306L13.068 9.90843L13.1349 10.2971L13.9263 14.8987L9.79297 12.7261L9.44402 12.5427L9.09508 12.7261L4.9617 14.8987L5.75317 10.2971L5.82014 9.90778L5.53692 9.63234L2.18727 6.37471L6.81195 5.70219L7.20172 5.64551L7.37631 5.29245L9.44402 1.11109L11.5117 5.29245L11.6863 5.64551L12.0761 5.70219L16.6928 6.37354L13.3504 9.63306Z"
        strokeWidth="1.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_3904_40698">
        <rect width="17.9408" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
