import React from 'react';
import MuiBox from '@mui/material/Box';
import MuiCircularProgress from '@mui/material/CircularProgress';

export default function LoadingSpinner() {
  return (
    <MuiBox style={{ display: 'flex', justifyContent: 'center' }}>
      <MuiCircularProgress />
    </MuiBox>
  );
}
