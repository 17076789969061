import React from 'react';

export const CatalogSvg = () => (
  <svg width="20" height="20" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1470_46018)">
      <path
        fill="#6e90a3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8225 7.5L17.3225 6H23.3225L24.8225 7.5V40.5L23.3225 42H17.3225L15.8225 40.5V7.5ZM18.8225 9V39H21.8225V9H18.8225ZM28.3355 10.035L29.2325 8.112L34.8725 6.06L36.7925 6.957L48.0785 37.965L47.1815 39.888L41.5445 41.94L39.6245 41.043L28.3325 10.035H28.3355ZM31.6655 10.419L41.9255 38.607L44.7455 37.584L34.4855 9.393L31.6655 10.419V10.419ZM3.82251 7.5L5.32251 6H11.3225L12.8225 7.5V40.5L11.3225 42H5.32251L3.82251 40.5V7.5ZM6.82251 9V39H9.82251V9H6.82251Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_1470_46018">
        <rect width="48" height="48" fill="white" transform="translate(0.82251)" />
      </clipPath>
    </defs>
  </svg>
);
