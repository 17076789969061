import Assessment, {
  AssessmentOutcomeStatus,
  State,
  Tier,
  Priority,
  TPSRecordType,
  QuestionnaireType,
  ActivityType,
  QuestionnaireRecordType,
  TPSRecordIdPrefix,
  RemediationOwner,
  Issue,
} from '../data/RecordMetadata';
import {
  ASSESSOR_ORANGE,
  BLACK,
  LINK_BLUE,
  MINT_GREEN,
  RED,
  REQUESTER_GREEN,
  VENDOR_PURPLE,
  WHITE,
  YELLOW,
} from '../constants/constants';
import { MessageBundle } from '@amzn/arb-tools';
import { SnowUrlType } from '../constants/urlConstants';

export function isStateClosed(stateLabel: string): boolean {
  if (!stateLabel) return false;
  const state = stateStrToEnum(stateLabel);

  return state === State.ABANDONED || state === State.CANCELLED || state === State.CLOSED || state === State.COMPLETE;
}

// As of 08/16/24, this is the most up to date enum records
//   https://tiny.amazon.com/12u5xj5di/quiplZmpAsse
// In the future, this should be the easiest place to find them
//   https://tiny.amazon.com/oblgh3iu/codeamazpackTPSMblobmainmode
export function stateStrToEnum(label: string): State {
  if (!label) return State.NONE;
  const state = label.toLowerCase();

  if (state === State.ABANDONED || state.includes(State.ABANDONED) || state.includes('abandoned'))
    return State.ABANDONED;
  if (
    state === State.AWAITING_ASSESSOR ||
    state.includes(State.AWAITING_ASSESSOR) ||
    state.includes('awaiting assessor')
  )
    return State.AWAITING_ASSESSOR;
  if (
    state === State.AWAITING_REQUESTER ||
    state.includes(State.AWAITING_REQUESTER) ||
    state.includes('awaiting requester')
  )
    return State.AWAITING_REQUESTER;
  if (
    state === State.AWAITING_THIRD_PARTY ||
    state.includes(State.AWAITING_THIRD_PARTY) ||
    state.includes('awaiting third party') ||
    state.includes('awaiting vendor')
  )
    return State.AWAITING_THIRD_PARTY;
  if (
    state === State.CANCELLED ||
    state.includes(State.CANCELLED) ||
    state.includes('cancelled') ||
    state.includes('canceled')
  )
    return State.CANCELLED;
  if (state === State.CLOSED || state.includes(State.CLOSED) || state.includes('closed')) return State.CLOSED;
  if (state === State.COMPLETE || state.includes(State.COMPLETE) || state.includes('complete')) return State.COMPLETE;
  if (state === State.DRAFT || state.includes(State.DRAFT) || state.includes('draft')) return State.DRAFT;
  if (state === State.IN_PROGRESS || state.includes(State.IN_PROGRESS) || state.includes('in progress'))
    return State.IN_PROGRESS;
  if (state === State.NEW || state.includes(State.NEW) || state.includes('new')) return State.NEW;
  if (state === State.OVERDUE || state.includes(State.OVERDUE) || state.includes('overdue')) return State.OVERDUE;
  if (
    state === State.PENDING_FOR_THIRD_PARTY_REMEDIATION ||
    state.includes(State.PENDING_FOR_THIRD_PARTY_REMEDIATION) ||
    state.includes('remediation')
  )
    return State.PENDING_FOR_THIRD_PARTY_REMEDIATION;
  if (
    state === State.PENDING_TPS_REVIEW ||
    state.includes(State.PENDING_TPS_REVIEW) ||
    state.includes('pending tps review')
  )
    return State.PENDING_TPS_REVIEW;
  if (state === State.QUEUED_FOR_TPRM || state.includes(State.QUEUED_FOR_TPRM) || state.includes('queued for tprm'))
    return State.QUEUED_FOR_TPRM;
  if (state === State.READY_TO_TAKE || state.includes(State.READY_TO_TAKE) || state.includes('ready'))
    return State.READY_TO_TAKE;
  if (
    state === State.SENT_TO_THIRD_PARTY ||
    state.includes(State.SENT_TO_THIRD_PARTY) ||
    state.includes('sent to third party')
  )
    return State.SENT_TO_THIRD_PARTY;
  if (
    state === State.TPS_REVIEW_LINKED ||
    state.includes(State.TPS_REVIEW_LINKED) ||
    state.includes('tps review linked')
  )
    return State.TPS_REVIEW_LINKED;
  if (
    state === State.TPS_REVIEW_NOT_REQUIRED ||
    state.includes(State.TPS_REVIEW_NOT_REQUIRED) ||
    state.includes('not required')
  )
    return State.TPS_REVIEW_NOT_REQUIRED;
  if (
    state === State.WAITING_ON_AMAZON_BUSINESS_OWNER ||
    state.includes(State.WAITING_ON_AMAZON_BUSINESS_OWNER) ||
    state.includes('amazon business owner')
  )
    return State.WAITING_ON_AMAZON_BUSINESS_OWNER;
  if (state === State.SUBMITTED || state.includes(State.SUBMITTED) || state.includes('submitted'))
    return State.SUBMITTED;
  if (state === State.RECEIVED || state.includes(State.RECEIVED) || state.includes('received')) return State.RECEIVED;
  if (state === State.NOT_APPLICABLE || state.includes(State.NOT_APPLICABLE) || state.includes('not_applicable'))
    return State.NOT_APPLICABLE;

  if (state.trim().toLowerCase().includes(State.PAUSED)) return State.PAUSED;

  return State.NONE;
}

export function stateLabelWithTextColor(label: State) {
  if (!label) return {};
  switch (label) {
    case State.NEW:
    case State.DRAFT:
      return { backgroundColor: LINK_BLUE, color: WHITE };
    case State.ABANDONED:
    case State.COMPLETE:
    case State.CANCELLED:
    case State.CLOSED:
      return { backgroundColor: BLACK, color: WHITE };
    case State.AWAITING_ASSESSOR:
      return { backgroundColor: ASSESSOR_ORANGE, color: BLACK };
    case State.AWAITING_REQUESTER:
      return { backgroundColor: REQUESTER_GREEN, color: BLACK };
    case State.AWAITING_THIRD_PARTY:
      return { backgroundColor: VENDOR_PURPLE, color: BLACK };
    case State.IN_PROGRESS:
    case State.OVERDUE:
    case State.PENDING_FOR_THIRD_PARTY_REMEDIATION:
    case State.PENDING_TPS_REVIEW:
    case State.QUEUED_FOR_TPRM:
    case State.READY_TO_TAKE:
    case State.SENT_TO_THIRD_PARTY:
    case State.TPS_REVIEW_LINKED:
    case State.SENT_TO_THIRD_PARTY:
      return { backgroundColor: RED, color: WHITE };
    case State.TPS_REVIEW_NOT_REQUIRED:
    case State.WAITING_ON_AMAZON_BUSINESS_OWNER:
      return { backgroundColor: YELLOW, color: BLACK };
    default:
      return {};
  }
}

export function outcomeLabelWithTextColor(label: AssessmentOutcomeStatus) {
  if (!label) return {};
  switch (label) {
    case AssessmentOutcomeStatus.BAR_MET:
      return { backgroundColor: MINT_GREEN, color: WHITE };
    case AssessmentOutcomeStatus.BAR_MET_EXCEPTIONS:
    case AssessmentOutcomeStatus.BAR_BYPASSED_EXCEPTIONS:
    case AssessmentOutcomeStatus.IN_PROGRESS:
    case AssessmentOutcomeStatus.PENDING_REVIEW:
      return { backgroundColor: YELLOW, color: BLACK };
    case AssessmentOutcomeStatus.BAR_NOT_MET:
      return { backgroundColor: RED, color: WHITE };
    default:
      return {};
  }
}

export function tierToEnum(label?: string | number): Tier {
  if (!label) return Tier.NONE;
  const tier = label.toString().toLowerCase();

  if (tier.includes('1') || tier.includes('one')) return Tier.ONE;
  else if (tier.includes('2') || tier.includes('two')) return Tier.TWO;
  else if (tier.includes('3') || tier.includes('three')) return Tier.THREE;
  else if (tier.includes('4') || tier.includes('four')) return Tier.FOUR;
  else return Tier.NONE;
}

export function tierStrToNum(label?: string | number): number {
  if (!label) return 0;
  const tier = String(label).toLowerCase();

  if (tier.includes('1') || tier.includes('one')) return 1;
  else if (tier.includes('2') || tier.includes('two')) return 2;
  else if (tier.includes('3') || tier.includes('three')) return 3;
  else if (tier.includes('4') || tier.includes('four')) return 4;
  else return 0;
}

export function outcomeStrToEnum(label: string): AssessmentOutcomeStatus {
  if (!label) return AssessmentOutcomeStatus.NONE;
  const outcome = label.toLowerCase();

  if (
    outcome === AssessmentOutcomeStatus.BAR_MET ||
    outcome.includes(AssessmentOutcomeStatus.BAR_MET) ||
    (outcome.includes('amazon 3p security bar met') && outcome !== 'amazon 3p security bar met with exceptions')
  )
    return AssessmentOutcomeStatus.BAR_MET;
  if (
    outcome === AssessmentOutcomeStatus.BAR_MET_EXCEPTIONS ||
    outcome.includes(AssessmentOutcomeStatus.BAR_MET_EXCEPTIONS) ||
    outcome.includes('amazon 3p security bar met with exceptions')
  )
    return AssessmentOutcomeStatus.BAR_MET_EXCEPTIONS;
  if (
    outcome.includes(AssessmentOutcomeStatus.BAR_BYPASSED_EXCEPTIONS) ||
    outcome.includes('amazon_3p_security_bar_bypassed_(exception_approved)') ||
    outcome.includes('amazon_3p_security_bar_bypassed_exception_approved')
  )
    return AssessmentOutcomeStatus.BAR_BYPASSED_EXCEPTIONS;
  if (
    outcome === AssessmentOutcomeStatus.BAR_NOT_MET ||
    outcome.includes(AssessmentOutcomeStatus.BAR_NOT_MET) ||
    outcome.includes('amazon 3p security bar not met')
  )
    return AssessmentOutcomeStatus.BAR_NOT_MET;
  if (
    outcome === AssessmentOutcomeStatus.IN_PROGRESS ||
    outcome.includes(AssessmentOutcomeStatus.IN_PROGRESS) ||
    outcome.includes('amazon 3p security bar not met')
  )
    return AssessmentOutcomeStatus.IN_PROGRESS;
  if (
    outcome === AssessmentOutcomeStatus.PENDING_REVIEW ||
    outcome.includes(AssessmentOutcomeStatus.PENDING_REVIEW) ||
    outcome.includes('pending review')
  )
    return AssessmentOutcomeStatus.PENDING_REVIEW;
  if (
    outcome === AssessmentOutcomeStatus.NOT_APPLICABLE ||
    outcome.includes(AssessmentOutcomeStatus.NOT_APPLICABLE) ||
    outcome.includes('not_applicable')
  )
    return AssessmentOutcomeStatus.NOT_APPLICABLE;

  return AssessmentOutcomeStatus.NONE;
}

export function severityStrToEnum(severity: string | undefined): Priority {
  if (!severity) return Priority.NONE;

  if (severity.toLowerCase().includes('high') || severity.toLowerCase().includes('1')) return Priority.HIGH;
  else if (
    severity.toLowerCase().includes('medium') ||
    severity.toLowerCase().includes('moderate') ||
    severity.toLowerCase().includes('2')
  )
    return Priority.MEDIUM;
  else if (severity.toLowerCase().includes('low')) return Priority.LOW;
  else return Priority.NONE;
}

export function questionnaireTypeToEnum(questionnaireType: string): QuestionnaireType {
  if (!questionnaireType) return QuestionnaireType.NONE;

  switch (questionnaireType.toLowerCase()) {
    case QuestionnaireType.INTAKE:
      return QuestionnaireType.INTAKE;

    case QuestionnaireType.DDQ:
    case 'deep dive lite questionnaire':
    case 'vendor assessment - requester satisfaction survey':
    case 'vendor assessment - vendor satisfaction survey':
    case 'direct fulfillment - data security practices survey':
    case 'tps deep dive dynamic_delete':
    case '[delete new]tps deep dive dynamic':
    case '[delete old2] tps deep dive dynamic':
    case '[delete old]tps deep dive dynamic':
    case '[backup]tps deep dive dynamic':
    case 'sample questionnaire':
    case 'tps deep dive dynamic':
    case 'esg questionnaire':
    case 'privacy':
    case 'basic':
    case 'deep dive questionnaire':
    case 'copy of deep dive lite questionnaire':
    case '[archive] security assessment lite questionnaire':
    case 'fourth-party registration questionnaire':
    case 'third party attestation':
    case 'baseline questionnaire':
    case 'attestation':
    case 'gift card semi-annual reassessment questionnaire':
    case 'gift card document request':
    case 'deep dive document request':
    case 'payment questionnaire':
    case 'gift card questionnaire':
    case 'deep dive questionnaire_delta':
      return QuestionnaireType.DDQ;

    case QuestionnaireType.SECURITY_FOLLOWUP:
    case 'security follow up questions':
      return QuestionnaireType.SECURITY_FOLLOWUP;

    case QuestionnaireType.GENERAL:
      return QuestionnaireType.GENERAL;

    default:
      return QuestionnaireType.NONE;
  }
}

const questionnaireToSnowUrl = new Map([
  [QuestionnaireType.SECURITY_FOLLOWUP, SnowUrlType.SECURITY_FOLLOWUP],
  [QuestionnaireType.INTAKE, SnowUrlType.INTAKE],
  [QuestionnaireType.DDQ, SnowUrlType.DDQ],
]);
export function questionnaireTypeToSnowUrlType(questionnaireType: QuestionnaireType): SnowUrlType {
  return questionnaireToSnowUrl.get(questionnaireType) ?? SnowUrlType.HOME;
}

export function activityTypeToEnum(activityType: string, text?: string): ActivityType {
  const type = activityType ? activityType.toLowerCase() : '';
  if (!activityType || activityType === '') return ActivityType.NONE;

  if (type === ActivityType.STATE_CHANGE || type.includes('state')) return ActivityType.STATE_CHANGE;
  if (type === ActivityType.INTAKE_COMPLETED || type.includes('intake') || type.includes('Third party tier'))
    return ActivityType.INTAKE_COMPLETED;
  if (type === ActivityType.COMMENT || type.includes('comment')) return ActivityType.COMMENT;
  if (type === ActivityType.ADDITIONAL_COMMENTS || type.includes('additional')) return ActivityType.ADDITIONAL_COMMENTS;
  //we are not receiving activity type from the activities API for DDQ percentage complete activity so adding option argument here
  if (type === ActivityType.PERCENTAGE_ANSWERED || text === 'Percent complete' || type.includes('percent'))
    return ActivityType.PERCENTAGE_ANSWERED;
  if (type === ActivityType.ASSESSMENT_OUTCOME || type.includes('assessment')) return ActivityType.ASSESSMENT_OUTCOME;
  if (type === ActivityType.DDQ_INHERITED_BY_TPTA) return ActivityType.DDQ_INHERITED_BY_TPTA;
  if (type === ActivityType.TPTA_INHERITED_DDQ || type.includes('tpta')) return ActivityType.TPTA_INHERITED_DDQ;
  if (type === ActivityType.PRIMARY_CONTACT_CHANGED || type.includes('primary'))
    return ActivityType.PRIMARY_CONTACT_CHANGED;
  if (type === ActivityType.SECONDARY_CONTACT_CHANGED || type.includes('secondary'))
    return ActivityType.SECONDARY_CONTACT_CHANGED;
  if (type === ActivityType.THIRD_PARTY_CONTACT_CHANGED || type.includes('business'))
    return ActivityType.THIRD_PARTY_CONTACT_CHANGED;
  if (type === ActivityType.TECHNICAL_CONTACT_CHANGED || type.includes('technical'))
    return ActivityType.TECHNICAL_CONTACT_CHANGED;
  if (type === ActivityType.REQUESTER_CONTACT_CHANGED || type.includes('requester'))
    return ActivityType.REQUESTER_CONTACT_CHANGED;
  if (type === ActivityType.USER_WATCHLIST || type.includes('watch list') || type.includes('watchlist'))
    return ActivityType.USER_WATCHLIST;
  if (type === ActivityType.TIER_CHANGED || type.includes('tier change')) return ActivityType.TIER_CHANGED;

  return ActivityType.NONE;
}

export function questionnaireType(questionnaireType: string) {
  switch (questionnaireType) {
    case 'Sample Questionnaire':
      return QuestionnaireRecordType.SAMPLE_QUESTIONNAIRE;
    case 'ESG Questionnaire':
      return QuestionnaireRecordType.ESG_QUESTIONNAIRE;
    case 'Privacy':
      return QuestionnaireRecordType.PRIVACY_QUESTIONNAIRE;
    case 'Basic':
      return QuestionnaireRecordType.BASIC_QUESTIONNAIRE;
    case 'Fourth-party registration questionnaire':
      return QuestionnaireRecordType.REGISTRATION_QUESTIONNAIRE;
    case 'Gift Card Semi-Annual Reassessment Questionnaire':
      return QuestionnaireRecordType.GIFT_QUESTIONNAIRE;
    case 'Third Party Attestation':
    case 'Baseline Questionnaire':
    case 'Attestation':
    case 'Gift Card Document Request':
    case 'Deep Dive Document Request':
    case 'Payment Questionnaire':
    case 'Gift Card Questionnaire':
      return QuestionnaireRecordType.DISPLAY_SAME;
    case 'Vendor Assessment - Requester Satisfaction Survey':
    case 'Vendor Assessment - Vendor Satisfaction Survey':
    case 'Log4j Vulnerability Remediation Status Survey':
      return QuestionnaireRecordType.DISPLAY_NONE;
    case 'Security Follow Up Questions':
      return QuestionnaireRecordType.SECURITY_QUESTIONNAIRE;
    default:
      return QuestionnaireRecordType.DDQ;
  }
}

/**
 * This helper function takes the enumeration of the DDQ and converts
 * it to plain-text for the user to read in the UI.
 *
 * @param key Questionnaire tpsRecordId
 * @param assessment Assessment containing questionnaire
 * @param bundle Bundle of the component
 * @returns String
 */
export function getDdqName(key: string, assessment: Assessment, bundle: MessageBundle): string {
  const questionnaire = assessment.questionnaires!.find((questionnaire) => questionnaire.id === key);
  return !questionnaire
    ? bundle.getMessage('missing_ddq_name')
    : bundle.formatMessage('ddq_type', {
        type: questionnaireType(questionnaire.type),
        ddqType: questionnaire.type,
      });
}

export function getTPSRecordType(tpsRecordId: string, tpsRecordType: string): TPSRecordType {
  tpsRecordId = tpsRecordId ? tpsRecordId.toLowerCase() : '';
  tpsRecordType = tpsRecordType ? tpsRecordType.toLowerCase() : '';

  if (tpsRecordType.includes(TPSRecordType.SURVEY))
    //surveys doesn't have any recordId Prefix only TpsRecordType field to use
    return TPSRecordType.SURVEY;
  else if (tpsRecordId.startsWith(TPSRecordIdPrefix.TPTA) || tpsRecordType.includes(TPSRecordType.ASSESSMENT))
    return TPSRecordType.ASSESSMENT;
  else if (tpsRecordId.startsWith(TPSRecordIdPrefix.TPRI) || tpsRecordType.includes(TPSRecordType.ISSUE))
    return TPSRecordType.ISSUE;
  else if (tpsRecordId.startsWith(TPSRecordIdPrefix.TPRT)) {
    if (tpsRecordType.includes(TPSRecordType.TASK)) return TPSRecordType.TASK;
    return TPSRecordType.CONSULT;
  } else if (tpsRecordId.startsWith(TPSRecordIdPrefix.AINST)) {
    if (questionnaireTypeToEnum(tpsRecordType) === QuestionnaireType.SECURITY_FOLLOWUP)
      return TPSRecordType.SECURITY_FOLLOWUP;
    return TPSRecordType.DDQ;
  }

  return TPSRecordType.NONE;
}

export function getIssueOwnershipType(issue: Issue): RemediationOwner {
  //Check for undefined as some TPRI do not have issue ownership value. Set to default "Third Party"
  if (issue?.issueOwnership?.includes('Amazon Internal')) return RemediationOwner.AMAZON_BUSINESS;
  return RemediationOwner.THIRD_PARTY;
}
