import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { get } from './ApiGatewayInvoker';
import { AssessmentPayload } from '../data/RecordMetadata';
import { retryConfig, cacheConfig, getCommonParams } from './ApiUtils';
import { useImpersonation } from '../context/UserProvider';

const ASSESSMENTS_API_PATH = '/api/v1/assessments';

export const useGetAssessmentsByUserQuery = (status?: string): UseQueryResult<AssessmentPayload> => {
  const { impersonationTarget } = useImpersonation();
  const commonParams = getCommonParams();

  return useQuery<AssessmentPayload>({
    queryKey: ['getAssessmentsKey', status, impersonationTarget],
    queryFn: async () => {
      const data = await get<AssessmentPayload>({
        apiPath: ASSESSMENTS_API_PATH,
        name: 'Assessment',
        user: impersonationTarget,
        queryParams: status ? { status: status } : undefined,
        emptyResponseConfig: {
          404: { assessments: [] },
        },
        ...commonParams,
      });
      return convertData(data);
    },
    ...retryConfig,
    ...cacheConfig,
  });
};

// TODO remove this once assignedUsers change makes it to gamma
function convertData(data: any): AssessmentPayload {
  const assessments = data.assessments.map((assessment: any) => ({
    ...assessment,
    assignedUsers: assessment.assignedUsers ?? assessment.users,
  }));
  return { assessments: assessments };
}
