import { BASE_URL, SnowUrlType } from '../constants/urlConstants';
import { RoleType } from '../data/UserMetadata';
import getTPSPortalUrl from '../utils/redirectionUtils';

const oauthCommon = Object.freeze({
  domain: 'federate-myassessmentservices-beta.auth.us-west-2.amazoncognito.com',
  scope: ['email', 'aws.cognito.signin.user.admin', 'openid', 'phone', 'profile', 'my-assessments-service/auth'],
  responseType: 'code',
  redirectSignIn: BASE_URL,
  redirectSignOut: getTPSPortalUrl({
    linkType: SnowUrlType.LOGOUT,
    userRole: RoleType.VENDOR,
    impersonationIntent: 'disabled',
  }),
});

const amplifyCommon = Object.freeze({
  authenticationFlowType: 'USER_SRP_AUTH',
  API: {
    endpoints: [
      {
        name: 'beta-ApiGateway',
        endpoint: 'https://beta.api.assessments.security.amazon.dev',
        service: 'gateway',
        region: 'us-west-2',
      },
    ],
  },
});

const amplifyBeta = Object.freeze({
  Auth: {
    mandatorySignId: true,
    region: 'us-west-2',
    userPoolId: 'us-west-2_SoWhoFoiv',
    userPoolWebClientId: '3gh1ahpc5vkikm6495gcpbc1c0',
  },
  oauth: {
    ...oauthCommon,
  },
  ...amplifyCommon,
});

const amplifyGamma = Object.freeze({
  Auth: {
    mandatorySignId: true,
    region: 'us-west-2',
    userPoolId: 'us-west-2_pewxEB7LP',
    userPoolWebClientId: '22516bgq6tm1gaob6c7mgms9p',
  },
  oauth: {
    ...oauthCommon,
  },
  ...amplifyCommon,
});

const amplifyProd = Object.freeze({
  Auth: {
    mandatorySignId: true,
    region: 'us-west-2',
    userPoolId: 'us-west-2_jZJRI9qMd',
    userPoolWebClientId: '5l01novhlun9k1ntsol1vm4o91',
  },
  oauth: {
    ...oauthCommon,
  },
  ...amplifyCommon,
});

export function getAmplifyConfiguration(stage: string): any {
  switch (stage) {
    case 'beta':
      return amplifyBeta;
    case 'gamma':
      return amplifyGamma;
    case 'prod':
    default:
      return amplifyProd;
  }
}

export default getAmplifyConfiguration;
