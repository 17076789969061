import { MessageBundle } from '@amzn/arb-tools';
import { getIssueOwnershipType, severityStrToEnum, stateStrToEnum } from './enumUtils';
import { FilterLabel } from '../components/FilterBar/FilterReducer';
import { FILTER_LABELS } from '../constants/constants';
import { Issue, Task } from '../data/RecordMetadata';
import { isIssueRecord } from './dataUtils';

const ISSUE_FILTERS: {
  label: FilterLabel;
  dataField: FILTER_LABELS.STATE | FILTER_LABELS.SEVERITY;
  conversionFunction: (param: string) => string;
}[] = [
  { label: FILTER_LABELS.ISSUE_STATE, dataField: FILTER_LABELS.STATE, conversionFunction: stateStrToEnum },
  { label: FILTER_LABELS.ISSUE_SEVERITY, dataField: FILTER_LABELS.SEVERITY, conversionFunction: severityStrToEnum },
];

export const ISSUE_FILTER_LABELS = ISSUE_FILTERS.map((issueFilter) => issueFilter.label);
export const areIssueFiltersApplied = (labelToFilters?: Map<FilterLabel, Array<string>>): boolean => {
  if (!labelToFilters) return false;
  for (const issueFilterLabel of ISSUE_FILTER_LABELS) {
    if (labelToFilters.has(issueFilterLabel)) {
      return true;
    }
  }

  return false;
};

const TASK_FILTERS: {
  label: FilterLabel;
  dataField: FILTER_LABELS.STATE | FILTER_LABELS.SEVERITY;
  conversionFunction: (param: string) => string;
}[] = [
  { label: FILTER_LABELS.TASK_STATE, dataField: FILTER_LABELS.STATE, conversionFunction: stateStrToEnum },
  { label: FILTER_LABELS.TASK_SEVERITY, dataField: FILTER_LABELS.SEVERITY, conversionFunction: severityStrToEnum },
];

export const TASK_FILTER_LABELS = TASK_FILTERS.map((taskFilter) => taskFilter.label);
export const areTaskFiltersApplied = (labelToFilters?: Map<FilterLabel, Array<string>>): boolean => {
  if (!labelToFilters) return false;
  for (const taskFilterLabel of TASK_FILTER_LABELS) {
    if (labelToFilters.has(taskFilterLabel)) {
      return true;
    }
  }

  return false;
};

export const areNonIssueTaskSearchFiltersApplied = (labelToFilters?: Map<FilterLabel, Array<string>>): boolean => {
  if (!labelToFilters) return false;
  for (const label of labelToFilters.keys()) {
    if (!ISSUE_FILTER_LABELS.includes(label) && !TASK_FILTER_LABELS.includes(label) && label !== FILTER_LABELS.SEARCH) {
      return true; // a non-issue or non-task or non-search filter applied
    }
  }

  return false;
};

export const isSearchApplied = (labelToFilters?: Map<FilterLabel, Array<string>>): boolean => {
  return labelToFilters?.has(FILTER_LABELS.SEARCH) ?? false;
};

export const isTptaSearchApplied = (labelToFilters?: Map<FilterLabel, Array<string>>): boolean => {
  return (
    (labelToFilters?.has(FILTER_LABELS.SEARCH) ?? false) &&
    labelToFilters!.get(FILTER_LABELS.SEARCH)!.filter((searchTerm) => searchTerm.indexOf('tpta') >= 0).length > 0
  );
};

type SearchType = 'title' | 'tpsRecordId' | 'severity' | 'state' | 'dueDate' | 'issueOwnership';
const SEARCH_FIELDS: SearchType[] = ['title', 'tpsRecordId', 'severity', 'state', 'dueDate', 'issueOwnership'];
const fieldToConverter: Map<string, (fieldString: string, bundle: MessageBundle) => string> = new Map([
  [
    FILTER_LABELS.STATE,
    (fieldString, bundle) =>
      bundle.formatMessage('record_state', {
        state: stateStrToEnum(fieldString),
      }),
  ],
  [
    FILTER_LABELS.SEVERITY,
    (fieldString, bundle) =>
      bundle!.formatMessage('severity_state', {
        severity: severityStrToEnum(fieldString),
      }),
  ],
]);

export function isIncludedInFilter(
  componentLabelsToValues: Partial<{ [K in FilterLabel]: string | string[] }>,
  labelToFilters: Map<FilterLabel, Array<string>>,
): boolean {
  for (const appliedFilterLabel of labelToFilters.keys()) {
    if (appliedFilterLabel === FILTER_LABELS.SEARCH) continue; // process search bar separately
    if (ISSUE_FILTER_LABELS.includes(appliedFilterLabel)) continue; // this is an issue filter
    if (TASK_FILTER_LABELS.includes(appliedFilterLabel)) continue; //this is an task filter
    if (!componentLabelsToValues[appliedFilterLabel]) return false; // filter out nullish fields
    if (!labelToFilters.get(appliedFilterLabel)!.includes(componentLabelsToValues[appliedFilterLabel] as string))
      return false; // value is not contained in the filters of that label
  }

  return true;
}

export function isSearchResult(
  componentLabelsToValues: Partial<{ [K in FilterLabel]: string | string[] }>,
  labelToFilters: Map<FilterLabel, Array<string>>,
): boolean {
  if (!labelToFilters.get(FILTER_LABELS.SEARCH)) return true; // no search terms to compare against

  for (const key of Object.keys(componentLabelsToValues)) {
    const terms: string[] =
      key === FILTER_LABELS.SEARCH
        ? (componentLabelsToValues[key as FilterLabel] as string[])
        : [componentLabelsToValues[key as FilterLabel] as string];
    for (let term of terms) {
      term = term.toLowerCase();
      for (const searchString of labelToFilters.get(FILTER_LABELS.SEARCH)!) {
        if (term.indexOf(searchString) >= 0) {
          return true; // one of the values passed matches the search terms
        }
      }
    }
  }

  return false; // no search terms have matched
}

export function isVisible(
  componentLabelsToValues: Partial<{ [K in FilterLabel]: string | string[] }>,
  labelToFilters: Map<FilterLabel, Array<string>>,
): boolean {
  if (!isIncludedInFilter(componentLabelsToValues, labelToFilters)) return false;
  return isSearchResult(componentLabelsToValues, labelToFilters);
}

export function getFilteredTpsIssueRecordIds(
  labelToFilters: Map<FilterLabel, Array<string>>,
  issues: Issue[],
  bundle: MessageBundle,
): Set<string> {
  const filteredIssues = new Set<string>(issues.map((issue) => issue.tpsRecordId));
  for (const issueFilterLabel of ISSUE_FILTERS) {
    if (!labelToFilters.has(issueFilterLabel.label)) continue; // filter is not applied
    for (const issue of issues) {
      let issueFieldString: string = issue[issueFilterLabel.dataField]!;
      if (fieldToConverter.has(issueFilterLabel.dataField))
        issueFieldString = fieldToConverter.get(issueFilterLabel.dataField)!(issueFieldString, bundle);
      if (!labelToFilters.get(issueFilterLabel.label)!.includes(issueFieldString))
        // filter is applied and missing from issue
        filteredIssues.delete(issue.tpsRecordId);
    }
  }

  return filteredIssues;
}

export function getFilteredTpsTaskRecordIds(
  labelToFilters: Map<FilterLabel, Array<string>>,
  tasks: Task[],
  bundle: MessageBundle,
): Set<string> {
  const filteredTasks = new Set<string>(tasks.map((task) => task.tpsRecordId));
  for (const taskFilterLabel of TASK_FILTERS) {
    if (!labelToFilters.has(taskFilterLabel.label)) continue; // filter is not applied
    for (const task of tasks) {
      let taskFieldString: string = task[taskFilterLabel.dataField];
      if (fieldToConverter.has(taskFilterLabel.dataField))
        taskFieldString = fieldToConverter.get(taskFilterLabel.dataField)!(taskFieldString, bundle);
      if (!labelToFilters.get(taskFilterLabel.label)!.includes(taskFieldString))
        // filter is applied and missing from task
        filteredTasks.delete(task.tpsRecordId);
    }
  }

  return filteredTasks;
}

function getTpsRecordIdSearchResults(
  labelToFilters: Map<FilterLabel, Array<string>>,
  records: Issue[] | Task[],
  bundle: MessageBundle,
): Set<string> {
  if (!labelToFilters.get(FILTER_LABELS.SEARCH)) return new Set(records.map((record) => record.tpsRecordId)); // no search terms to compare against
  if (isTptaSearchApplied(labelToFilters)) {
    let nonTptaFound = false;
    for (const filter of labelToFilters.get(FILTER_LABELS.SEARCH)!) {
      if (!filter.includes('tpta')) {
        nonTptaFound = true;
        break;
      }
    }
    if (!nonTptaFound) return new Set(records.map((record) => record.tpsRecordId)); // only searching by TPTA, don't filter out child records
  }
  const visibleRecords = new Set<string>();

  for (const record of records) {
    for (const searchField of SEARCH_FIELDS) {
      let recordFieldString: string =
        searchField !== 'dueDate' && searchField !== 'issueOwnership' ? record[searchField]! : '';

      if (searchField === 'dueDate' && !isIssueRecord(record)) {
        recordFieldString = record.dueDate
          ? bundle.formatMessage('task_due_date', {
              dueDate: new Date(0).setUTCSeconds(Number(record.dueDate)),
            })
          : bundle.getMessage('no_due_date_error');
      }
      if (searchField === 'issueOwnership' && isIssueRecord(record)) {
        recordFieldString = record.issueOwnership! ? bundle.getMessage(getIssueOwnershipType(record)) : '';
      }

      if (!recordFieldString) continue; //skip the testing for empty fields

      if (fieldToConverter.has(searchField))
        recordFieldString = fieldToConverter.get(searchField)!(recordFieldString, bundle);

      recordFieldString = recordFieldString.toLowerCase();

      for (const searchString of labelToFilters.get(FILTER_LABELS.SEARCH)!) {
        if (recordFieldString.indexOf(searchString) >= 0) {
          visibleRecords.add(record.tpsRecordId);
          break;
        }
      }
      // skip other search fields as the current search field matched
      if (visibleRecords.has(record.tpsRecordId)) break;
    }
  }
  return visibleRecords;
}

export function getVisibleIssueTpsRecordIds(
  labelToFilters: Map<FilterLabel, Array<string>>,
  issues: Issue[],
  bundle: MessageBundle,
): Set<string> {
  const filteredIssues = getFilteredTpsIssueRecordIds(labelToFilters, issues, bundle);
  return getTpsRecordIdSearchResults(
    labelToFilters,
    issues.filter((issue) => filteredIssues.has(issue.tpsRecordId)),
    bundle,
  );
}

export function getVisibleTaskTpsRecordIds(
  labelToFilters: Map<FilterLabel, Array<string>>,
  tasks: Task[],
  bundle: MessageBundle,
): Set<string> {
  const filteredTasks = getFilteredTpsTaskRecordIds(labelToFilters, tasks, bundle);
  return getTpsRecordIdSearchResults(
    labelToFilters,
    tasks.filter((task) => filteredTasks.has(task.tpsRecordId)),
    bundle,
  );
}
