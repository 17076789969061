import React, { useMemo } from 'react';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import MuiBox from '@mui/material/Box';
import MuiCard from '@mui/material/Card';
import MuiLink from '@mui/material/Link';
import MuiTypography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { ImpersonationIntentType, useImpersonation, useUserContext } from '../../context/UserProvider';
import { LoadState, TpsData, useDataContext } from '../../context/DataProvider';
import {
  allPageDataIsLoaded,
  getRankedNotifications,
  getOverallLoadState,
  isTakeActionItemActionable,
} from '../../utils/dataUtils';
import { UserMetadata } from '../../data/UserMetadata';
import { NotificationItem, TakeActionType } from '../../data/RecordMetadata';
import LoadingSpinner from '../LoadingComponents/LoadingSpinner';
import { styled } from '@mui/material/styles';
import { tierToEnum } from '../../utils/enumUtils';
import { RED, DARK_GRAY, HOVER_EFFECT, BREAKPOINT } from '../../constants/constants';
import './TakeActionList.css';

const TakeActionHeader = styled(MuiTypography)(({ theme }) => ({
  fontSize: '1rem',
  marginBottom: '20px',
  marginLeft: '3px',
  color: theme.palette.primary.primary,
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '16px',
  },
}));

const SmallFontType = styled(MuiTypography)(({ theme }) => ({
  color: DARK_GRAY,
  fontSize: '0.75rem',
  '&:hover': HOVER_EFFECT,
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '11px',
  },
}));

const LargeFontType = styled(MuiTypography)(({ theme }) => ({
  fontSize: '1rem',
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '13px',
  },
}));

function getTopXRecords(
  tpsData: TpsData,
  user: UserMetadata,
  impersonationIntent: ImpersonationIntentType,
  numRecords?: number,
): NotificationItem[] {
  const num = !numRecords ? 3 : numRecords;
  return getRankedNotifications(tpsData, user, impersonationIntent)
    .filter((notification) => isTakeActionItemActionable(notification.takeActionType))
    .slice(0, num);
}

function TakeActionList({ bundle }: WithBundleProps) {
  const user = useUserContext();
  const { impersonationIntent } = useImpersonation();
  const [state] = useDataContext();
  const memoizedRankedData = useMemo(
    () => getTopXRecords(state, user, impersonationIntent),
    [state, user, impersonationIntent],
  );

  return (
    <div className="take-action-list-container" style={memoizedRankedData.length === 0 ? { paddingTop: '50px' } : {}}>
      {memoizedRankedData.length > 0 && (
        <div className="take-action-list-background">
          <TakeActionHeader>{bundle.getMessage('take_action')}</TakeActionHeader>
          <div>
            {getOverallLoadState(state) === LoadState.LOADING && <LoadingSpinner />}
            {user.role &&
              allPageDataIsLoaded(state, user.role) &&
              memoizedRankedData.map((notification, index) => (
                // Using index because these don't change unless page is refreshed
                // and records can have more than 1 item.
                <MuiCard key={index} className="take-action-list-card">
                  <MuiLink
                    {...{
                      component: Link,
                      to: notification.link,
                    }}
                    underline="none"
                    color="inherit"
                    sx={{
                      '&:hover': {
                        '*': HOVER_EFFECT,
                      },
                    }}
                  >
                    <MuiBox className="take-action-list-card-content">
                      <LargeFontType
                        sx={{
                          color: RED,
                        }}
                      >
                        {bundle.formatMessage('take_action_text', {
                          // textType is what we perform the Switch statement on
                          // in the PUFF-J files
                          textType: notification.takeActionType,
                          expirationDate: notification.expiresOnDate ? notification.expiresOnDate : 0,
                          trpiNum: notification.containedRecordId,
                          questionnaireName: bundle.formatMessage('ddq_type', {
                            type: notification.questionnaireType,
                          }),
                        })}
                      </LargeFontType>
                      {notification.takeActionType !== TakeActionType.CONSULT_ACTIONABLE &&
                        notification.takeActionType !== TakeActionType.SURVEY_ACTIONABLE && (
                          <SmallFontType>
                            {bundle.formatMessage('vendor_id_tier', {
                              vendor:
                                notification.vendor && notification.vendor !== ''
                                  ? notification.vendor
                                  : bundle.getMessage('vendor_missing'),
                              tpsRecordId:
                                notification.tpsRecordId && notification.tpsRecordId !== ''
                                  ? notification.tpsRecordId
                                  : bundle.getMessage('record_id_missing'),
                              tier: bundle.formatMessage('tier', { tier: tierToEnum(notification.tier) }),
                              takeActionType: notification.takeActionType,
                            })}
                          </SmallFontType>
                        )}
                    </MuiBox>
                  </MuiLink>
                </MuiCard>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default withBundle('components.TakeActionList')(TakeActionList);
