import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { get } from './ApiGatewayInvoker';
import { ConsultPayload } from '../data/RecordMetadata';
import { retryConfig, cacheConfig, getCommonParams } from './ApiUtils';
import { RoleType } from '../data/UserMetadata';
import { useImpersonation, useUserContext } from '../context/UserProvider';

const CONSULTS_API_PATH = '/api/v1/consults';

export const useGetConsultsByUserQuery = (): UseQueryResult<ConsultPayload> => {
  const { role } = useUserContext();
  const { impersonationTarget } = useImpersonation();
  const commonParams = getCommonParams();

  // Only Requesters can use this query and they will always
  // be 1P users; aka Amazon employees.
  if (role === RoleType.VENDOR) {
    return useQuery<ConsultPayload>({
      queryKey: ['getConsultsKey'],
      queryFn: async () => {
        return { consults: [] };
      },
    });
  }

  return useQuery<ConsultPayload>({
    queryKey: ['getConsultsKey', impersonationTarget],
    queryFn: async () => {
      return await get<ConsultPayload>({
        apiPath: CONSULTS_API_PATH,
        name: 'Consult',
        user: impersonationTarget,
        emptyResponseConfig: {
          404: { consults: [] },
        },
        ...commonParams,
      });
    },
    ...retryConfig,
    ...cacheConfig,
  });
};
