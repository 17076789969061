import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { get } from './ApiGatewayInvoker';
import { UserDetails } from '../data/RecordMetadata';
import { retryConfig, cacheConfig } from './ApiUtils';
import { useMetricsAndLoggerContext } from '../logging/MetricsProvider';

const USER_API_PATH = '/api/v1/users';

export const useUserDetailsQuery = (authToken: string, impersonationTarget: string): UseQueryResult<UserDetails> => {
  const { metricsPublisher, logger } = useMetricsAndLoggerContext();

  return useQuery<UserDetails>({
    queryKey: ['getUserDetails', impersonationTarget],
    queryFn: async (): Promise<UserDetails> => {
      return await get<UserDetails>({
        apiPath: `${USER_API_PATH}`,
        name: 'UserDetail',
        user: impersonationTarget,
        emptyResponseConfig: {
          404: {} as UserDetails,
        },
        authToken,
        metricsPublisher,
        logger,
      });
    },
    ...retryConfig,
    ...cacheConfig,
  });
};
