import React, { useState, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import { MessageBundle } from '@amzn/arb-tools';
import MuiBox from '@mui/material/Box';
import MuiCard from '@mui/material/Card';
import MuiCardActions from '@mui/material/CardActions';
import MuiCardContent from '@mui/material/CardContent';
import MuiTypography from '@mui/material/Typography';
import MuiLink from '@mui/material/Link';
import MuiDivider from '@mui/material/Divider';
import MuiCollapse from '@mui/material/Collapse';
import MuiIconButton, { IconButtonProps } from '@mui/material/IconButton';
import MuiExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Assessment, {
  ActivityType,
  AssessmentOutcomeStatus,
  Questionnaire,
  QuestionnaireType,
  State,
  QuestionnaireRecordType,
  TPSRecordType,
} from '../../data/RecordMetadata';
import FavoritesStar from '../FavoritesStar/FavoritesStar';
import getTPSPortalUrl from '../../utils/redirectionUtils';
import { ImpersonationIntentType, useImpersonation, useUserContext } from '../../context/UserProvider';
import {
  BLACK,
  RED,
  PRIMARY_BLUE,
  SECONDARY_BLUE,
  DARK_GRAY,
  TERTIARY_BLUE,
  LINK_BLUE,
  HOVER_EFFECT,
  BREAKPOINT,
} from '../../constants/constants';
import {
  stateStrToEnum,
  tierToEnum,
  outcomeStrToEnum,
  outcomeLabelWithTextColor,
  questionnaireTypeToEnum,
  questionnaireType,
  getDdqName,
  questionnaireTypeToSnowUrlType,
} from '../../utils/enumUtils';
import { styled } from '@mui/material/styles';
import { RoleType, UserMetadata } from '../../data/UserMetadata';
import { SwimlaneType } from '../../data/RecordMetadata';
import {
  getAssessmentLabelColor,
  isEmail,
  getSecurityFollowupLabelColor,
  getActivityOrEmailType,
  isDdqActionable,
  isSecurityFollowupActionable,
  ActionableColor,
  getDdqLabelColor,
  getLatestDdq,
} from '../../utils/dataUtils';
import { isAwaitingRequesterState, hasActionableIssue, hasActionableTask } from '../../utils/tptaCardTextColorUtils';
import { SnowUrlType } from '../../constants/urlConstants';
import './ThirdPartyTieringAssessmentCard.css';

/****** Styled Components Section *****/

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props; // eslint-disable-line
  return <MuiIconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(360deg)' : 'rotate(180deg)',
  padding: '0',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const StatusIndicator = styled(MuiBox)(({ theme }) => ({
  position: 'absolute',
  top: '0',
  right: '0',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1,
  padding: '4px',
  writingMode: 'vertical-lr',
  textAlign: 'center',
  fontSize: '12px',
  fontWeight: 'bold',
  transform: 'rotate(180deg)',
  whiteSpace: 'nowrap',
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '10px',
    padding: '2px',
  },
}));

const SmallFontType = styled(MuiTypography)(({ theme }) => ({
  fontSize: '0.75rem',
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '10px',
  },
}));

const SmallFontLinkColor = styled(SmallFontType)({
  '&:hover': HOVER_EFFECT,
});

const LargeFontType = styled(MuiTypography)({
  fontSize: '1rem',
});

const LargeFontLinkColor = styled(LargeFontType)(({ theme }) => ({
  '&:hover': {
    color: LINK_BLUE,
    background: 'none',
    cursor: 'pointer',
  },
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '13px',
  },
}));

const VendorTextLink = styled(MuiTypography)(({ theme }) => ({
  color: SECONDARY_BLUE,
  '&:hover': {
    color: LINK_BLUE,
    background: 'none',
    cursor: 'pointer',
  },
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '10px',
  },
}));

const LabelContainer = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '0.5rem',
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    marginTop: '0.2rem',
  },
}));

const AccordionArrowContainer = styled(MuiCardActions)({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '0',
});

const SecurityQuestionnaireLine = styled(MuiBox)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: '5px',
});

const CompleteQuestionnaireLine = styled(MuiBox)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '0.75rem',
});

const IssuesAssessmentOutCome = styled(MuiTypography)(({ theme }) => ({
  fontSize: '12px',
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '8px',
  },
}));

const RecentActivities = styled(MuiBox)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '0.75rem',
  fontFamily: 'Amazon Ember',
  marginTop: '0.3rem',
  marginBottom: '0.3rem',
  color: DARK_GRAY,
});

const StateBubble = styled(MuiTypography)(({ theme }) => ({
  fontSize: '0.65rem',
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '7px',
  },
}));

const ViewButtonContainer = styled(MuiBox)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '10px',
});

type ViewButtonProps = {
  actionable: boolean;
};

const ViewButton = styled(MuiBox)<ViewButtonProps>(({ theme, actionable }) => ({
  backgroundColor: actionable ? theme.palette.error.main : theme.palette.primary.dark,
  color: theme.palette.common.white,
  fontSize: '14px',
  marginTop: '5px',
  padding: '5px',
  cursor: 'pointer',
}));

const ProgressQuestionnaireLine = CompleteQuestionnaireLine;

interface ViewRecordButtonProps {
  assessment: Assessment;
  bundle: MessageBundle;
  user: UserMetadata;
  type: string;
}

const ViewRecordButton = (props: ViewRecordButtonProps): ReactElement => {
  return (
    <ViewButtonContainer className="view-button-container">
      <MuiLink
        {...{ component: Link, to: `/${props.type}s?search=${props.assessment.tpsRecordId}` }}
        underline="none"
        color="inherit"
        sx={{ cursor: 'pointer' }}
      >
        <ViewButton
          actionable={
            props.type === TPSRecordType.ISSUE
              ? hasActionableIssue(props.assessment, props.user)
              : hasActionableTask(props.assessment, props.user)
          }
        >
          {props.type === TPSRecordType.ISSUE
            ? props.bundle.formatMessage('issue_card_button', { numIssues: props.assessment.issueCount })
            : props.bundle.formatMessage('task_card_button', { numTasks: props.assessment.taskCount })}
        </ViewButton>
      </MuiLink>
    </ViewButtonContainer>
  );
};

interface AccortionTextProps {
  assessment: Assessment;
  user: UserMetadata;
  bundle: MessageBundle;
  type: SwimlaneType;
  impersonationIntent: ImpersonationIntentType;
}

function AccordionText(props: AccortionTextProps): ReactElement {
  const getIssueButton = () => (
    <ViewRecordButton
      assessment={props.assessment}
      bundle={props.bundle}
      user={props.user}
      type={TPSRecordType.ISSUE}
    />
  );

  switch (props.type) {
    case SwimlaneType.INTAKE:
      return (
        <MuiBox className="fold-text">
          {props.assessment.redirectUrlParams ? (
            <MuiLink
              {...{
                component: Link,
                to: getTPSPortalUrl({
                  id: props.assessment.redirectUrlParams,
                  linkType: SnowUrlType.INTAKE,
                  userRole: props.user.role,
                  impersonationIntent: props.impersonationIntent,
                }),
              }}
              underline="none"
              sx={{ color: TERTIARY_BLUE }}
            >
              <SmallFontLinkColor>{props.bundle.getMessage('intake_questionnaire_text')}</SmallFontLinkColor>
            </MuiLink>
          ) : (
            <SmallFontType>{props.bundle.getMessage('intake_questionnaire_text')}</SmallFontType>
          )}
          <SmallFontType>
            {props.assessment.startDate
              ? props.bundle.formatMessage('accordion_text_intake', {
                  // 21 days after creation start / creation date
                  // 1814400 = 60sec * 60min * 24hrs * 21days
                  expirationDate: new Date(0).setUTCSeconds(props.assessment.startDate + 1814400),
                  red: (child: string) => (
                    <span key="1" style={{ color: RED }}>
                      {child}
                    </span>
                  ),
                })
              : props.bundle.getMessage('missing_expiration_date')}
          </SmallFontType>
        </MuiBox>
      );

    // Moved to account for questionnaires in the Issues lane
    case SwimlaneType.DDQ:
      return !props.assessment.issueCount ? <></> : getIssueButton();

    case SwimlaneType.ISSUE:
      return getIssueButton();
  }
}

interface CardLabelProps extends WithBundleProps {
  assessment: Assessment;
  user: UserMetadata;
  type: SwimlaneType;
  questionnaires?: Questionnaire[];
}
function CardLabel({ bundle, ...props }: CardLabelProps): ReactElement {
  let actionableColor: ActionableColor | undefined = undefined;
  let state: State | undefined = undefined;
  // https://issues.amazon.com/issues/TPSE-2115
  if (
    props.type === SwimlaneType.ISSUE &&
    props.user.role === RoleType.VENDOR &&
    props.questionnaires?.length &&
    stateStrToEnum(props.assessment.state) !== State.PAUSED
  ) {
    const nonCancelledDdqs = props.questionnaires!.filter(
      (questionnaire) =>
        questionnaireTypeToEnum(questionnaire.type) === QuestionnaireType.DDQ &&
        stateStrToEnum(questionnaire.state) !== State.CANCELLED,
    );

    const latestNonCancelledDdq = getLatestDdq(nonCancelledDdqs);

    if (latestNonCancelledDdq) {
      actionableColor = getDdqLabelColor(props.assessment, latestNonCancelledDdq, props.user);
      state = stateStrToEnum(latestNonCancelledDdq.state);
    }
  }

  if (!actionableColor) {
    actionableColor = getAssessmentLabelColor(props.assessment, props.user);
    state = stateStrToEnum(props.assessment.state);
  }

  return state === State.NONE ? (
    <></>
  ) : (
    <MuiBox
      sx={{
        backgroundColor: actionableColor.backgroundColor,
        color: actionableColor.color,
        width: '7rem',
        height: '1.2rem',
        '@media screen and (max-width: 1376px)': {
          width: '5rem',
          height: '1rem',
        },
      }}
      className="card-label"
    >
      <StateBubble>
        {bundle.formatMessage('state', {
          state: state,
        })}
      </StateBubble>
    </MuiBox>
  );
}

interface QuestionnaireLineProps extends WithBundleProps {
  assessment: Assessment;
  questionnaire: Questionnaire;
  user: UserMetadata;
  impersonationIntent: ImpersonationIntentType;
}
function QuestionnaireLine({
  bundle,
  assessment,
  questionnaire,
  user,
  impersonationIntent,
}: QuestionnaireLineProps): ReactElement {
  switch (questionnaireTypeToEnum(questionnaire.type)) {
    case QuestionnaireType.DDQ:
      return (
        <ProgressQuestionnaireLine
          className="fold-text"
          sx={{
            paddingBottom: '0.3rem',
            color: isDdqActionable(assessment, questionnaire, user) ? RED : TERTIARY_BLUE,
          }}
          key={questionnaire.tpsRecordSystemId}
        >
          <MuiLink
            {...{
              component: Link,
              to: getTPSPortalUrl({
                id: questionnaire.tpsRecordSystemId,
                linkType: SnowUrlType.DDQ,
                userRole: user.role,
                impersonationIntent,
                notesId: questionnaire.typeId,
              }),
            }}
            underline="none"
            sx={{
              color: isDdqActionable(assessment, questionnaire, user) ? RED : TERTIARY_BLUE,
              '&:hover': HOVER_EFFECT,
            }}
          >
            <SmallFontType>
              {questionnaireType(questionnaire.type) !== QuestionnaireRecordType.DISPLAY_NONE ? (
                bundle.formatMessage('ddq_type', {
                  type: questionnaireType(questionnaire.type),
                  ddqType: questionnaire.type,
                })
              ) : (
                <></>
              )}
            </SmallFontType>
          </MuiLink>
          <SmallFontType>
            {bundle.formatMessage('ddq_percent_complete', {
              percent: questionnaire.progressPercentage ?? 0,
            })}
          </SmallFontType>
        </ProgressQuestionnaireLine>
      );

    case QuestionnaireType.SECURITY_FOLLOWUP:
      return (
        <SecurityQuestionnaireLine key={questionnaire.id}>
          <MuiLink
            {...{
              component: Link,
              to: getTPSPortalUrl({
                id: questionnaire.tpsRecordSystemId,
                linkType: SnowUrlType.SECURITY_FOLLOWUP,
                userRole: user.role,
                impersonationIntent,
                notesId: questionnaire.typeId,
              }),
            }}
            underline="none"
            sx={{
              color: isSecurityFollowupActionable(questionnaire, user) ? RED : TERTIARY_BLUE,
              '&:hover': HOVER_EFFECT,
            }}
          >
            <SmallFontLinkColor>{bundle.getMessage('security_questionnaire')}</SmallFontLinkColor>
          </MuiLink>
          <MuiBox
            sx={{ ...getSecurityFollowupLabelColor(questionnaire, user), width: '7rem', height: '1.2rem' }}
            className="card-label"
          >
            <StateBubble>
              {bundle.formatMessage('state', {
                state: stateStrToEnum(questionnaire.state),
              })}
            </StateBubble>
          </MuiBox>
        </SecurityQuestionnaireLine>
      );

    default:
      return <></>;
  }
}

function determineTimeDiff(timestamp: number) {
  // Time is in UTC seconds so we divide by 1000
  const timeSince = Date.now() / 1000 - timestamp;
  let minutes = Math.floor(timeSince / 60);
  minutes = minutes === 0 ? 1 : minutes; // So we never display 0m ago.
  const hours = Math.floor((timeSince / (60 * 60)) % 24);
  const days = Math.floor(timeSince / (60 * 60 * 24));

  // Return the proper parameters for the PUFF-J function
  if (minutes < 60) return { timeUnit: 'min', timeDiff: minutes };
  if (days == 0) return { timeUnit: 'hours', timeDiff: hours };

  return { timeUnit: 'day', timeDiff: new Date(0).setUTCSeconds(timestamp) };
}

const openQuestionnaireFilter = (questionnaire: Questionnaire) =>
  stateStrToEnum(questionnaire.state) !== State.COMPLETE &&
  stateStrToEnum(questionnaire.state) !== State.CANCELLED &&
  stateStrToEnum(questionnaire.state) !== State.NONE;

/****** Component ******/
interface ThirdPartyTieringAssessmentCardProps extends WithBundleProps {
  assessment: Assessment;
  type: SwimlaneType;
  activitiesAreLoaded: boolean;
}

function ThirdPartyTieringAssessmentCard({ bundle, ...props }: ThirdPartyTieringAssessmentCardProps) {
  const [expanded, setExpanded] = useState(false);
  const user = useUserContext();
  const { impersonationIntent } = useImpersonation();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const assessmentLink = {
    id:
      isAwaitingRequesterState(props.assessment, user) && props.type === SwimlaneType.INTAKE
        ? props.assessment.redirectUrlParams
        : props.assessment.tpsRecordSystemId,
    linkType:
      isAwaitingRequesterState(props.assessment, user) && props.type === SwimlaneType.INTAKE
        ? SnowUrlType.INTAKE
        : SnowUrlType.ASSESSMENT,
    impersonationIntent,
    userRole: user.role,
  };

  return (
    <>
      <MuiCard variant="elevation" className={`tpta-card`}>
        <MuiCardContent style={{ paddingRight: '30px' }} className="swimlane-card-content">
          <MuiBox className={'tpta-card-view'} sx={{ fontSize: '0.75rem' }}>
            <MuiLink
              {...{
                component: Link,
                to: getTPSPortalUrl(assessmentLink),
              }}
              underline="none"
              className="view-link"
              sx={{
                color: isAwaitingRequesterState(props.assessment, user) ? RED : SECONDARY_BLUE,
              }}
            >
              <SmallFontLinkColor>{bundle.getMessage('view_button')}</SmallFontLinkColor>
            </MuiLink>
          </MuiBox>
          <MuiBox className="card-links-container" sx={{ paddingTop: '1rem' }}>
            <MuiBox className="text-icon">
              <FavoritesStar assessment={props.assessment} style={{ display: 'flex', alignContent: 'flex-start' }} />
              <MuiLink
                {...{
                  component: Link,
                  to: getTPSPortalUrl(assessmentLink),
                }}
                underline="none"
                sx={{
                  color: isAwaitingRequesterState(props.assessment, user) ? RED : PRIMARY_BLUE,
                }}
              >
                <LargeFontLinkColor variant="h4" className="project-name-link" sx={{ paddingLeft: '0.3rem' }}>
                  {props.assessment.vendor}
                </LargeFontLinkColor>
              </MuiLink>
            </MuiBox>
            <MuiBox className="vendor-tpta-label-container">
              <LabelContainer className="vendor-tpta-container">
                <MuiBox className="vendor-name-link">
                  <MuiLink
                    {...{
                      component: Link,
                      to: getTPSPortalUrl(assessmentLink),
                    }}
                    underline="none"
                  >
                    <VendorTextLink className="questionnaire-name" sx={{ fontSize: '0.9rem' }}>
                      {bundle.formatMessage('vendor_tier', {
                        tptaNum: props.assessment.tpsRecordId,
                        tier: tierToEnum(props.assessment.tier),
                      })}
                    </VendorTextLink>
                  </MuiLink>
                </MuiBox>
                <CardLabel
                  assessment={props.assessment}
                  questionnaires={props.assessment.questionnaires}
                  type={props.type}
                  user={user}
                  bundle={bundle}
                />
              </LabelContainer>
            </MuiBox>
          </MuiBox>
          <MuiBox>
            {user.role !== RoleType.VENDOR && (
              <LabelContainer className="label-containers">
                <SmallFontType sx={{ color: BLACK, paddingBottom: '0.2rem', fontSize: '0.7rem', marginTop: '-0.3rem' }}>
                  {props.assessment.name}
                </SmallFontType>
              </LabelContainer>
            )}
          </MuiBox>

          <AccordionText
            assessment={props.assessment}
            user={user}
            type={props.type}
            bundle={bundle}
            key={props.assessment.tpsRecordSystemId}
            impersonationIntent={impersonationIntent}
          />

          {/* If there are open DDQs or Security follow ups */}
          {props.assessment.questionnaires!.filter(openQuestionnaireFilter).map((questionnaire) => (
            <QuestionnaireLine
              assessment={props.assessment}
              questionnaire={questionnaire}
              user={user}
              bundle={bundle}
              key={questionnaire.tpsRecordSystemId}
              impersonationIntent={impersonationIntent}
            />
          ))}
          {user.role === RoleType.REQUESTER && props.assessment.taskCount! > 0 ? (
            <ViewRecordButton assessment={props.assessment} bundle={bundle} user={user} type={TPSRecordType.TASK} />
          ) : null}
          <AccordionArrowContainer disableSpacing>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              className="tpta-card-expand-more-icon"
              aria-expanded={expanded}
              aria-label="show more"
            >
              <MuiExpandMoreIcon />
            </ExpandMore>
          </AccordionArrowContainer>
          <MuiCollapse in={expanded} timeout="auto" unmountOnExit className="tpta-card-activities-collapse-container">
            <MuiDivider variant="fullWidth" style={{ margin: '2px 0 8px' }} />
            {props.assessment.questionnaires &&
              props.assessment.questionnaires.length > 0 &&
              props.assessment.questionnaires
                .filter(
                  (record) =>
                    stateStrToEnum(record.state) === State.COMPLETE || stateStrToEnum(record.state) === State.CANCELLED,
                )
                .map((record) => {
                  return (
                    <MuiBox key={record.id}>
                      <CompleteQuestionnaireLine key={record.id}>
                        <MuiLink
                          {...{
                            component: Link,
                            to: getTPSPortalUrl({
                              id: record.tpsRecordSystemId,
                              linkType: questionnaireTypeToSnowUrlType(questionnaireTypeToEnum(record.type)),
                              userRole: user.role,
                              impersonationIntent,
                              notesId: record.typeId,
                            }),
                          }}
                          underline="none"
                          sx={{ color: TERTIARY_BLUE }}
                        >
                          <SmallFontLinkColor>
                            {questionnaireTypeToEnum(record.type) === QuestionnaireType.DDQ ? (
                              questionnaireType(record.type) !== QuestionnaireRecordType.DISPLAY_NONE ? (
                                bundle.formatMessage('ddq_type', {
                                  type: questionnaireType(record.type),
                                  ddqType: record.type,
                                })
                              ) : (
                                <></>
                              )
                            ) : (
                              bundle.getMessage('security_questionnaire')
                            )}
                          </SmallFontLinkColor>
                        </MuiLink>
                        <SmallFontType sx={{ color: TERTIARY_BLUE }}>
                          {bundle.formatMessage('complete_or_cancelled_state', {
                            state: stateStrToEnum(record.state),
                          })}
                        </SmallFontType>
                      </CompleteQuestionnaireLine>
                    </MuiBox>
                  );
                })}
            {/* tasks represents all tasks while taskCount represents actionable tasks
             * The below displays the tasks button if a requester has ONLY non-actionable tasks
             */}
            {user.role === RoleType.REQUESTER &&
            props.assessment.tasks!.length > 0 &&
            props.assessment.taskCount! === 0 ? (
              <ViewRecordButton assessment={props.assessment} bundle={bundle} user={user} type={TPSRecordType.TASK} />
            ) : null}
            <RecentActivities>
              <SmallFontType>
                {props.assessment.activities
                  ? bundle.formatMessage('card_activities', { numActivities: props.assessment.activities.length })
                  : bundle.formatMessage('card_activities', { numActivities: 0 })}
              </SmallFontType>
              {props.assessment.activities?.length ? (
                <MuiLink
                  {...{
                    component: Link,
                    to: getTPSPortalUrl(assessmentLink),
                  }}
                  underline="none"
                  sx={{ color: DARK_GRAY }}
                >
                  <SmallFontLinkColor>{bundle.getMessage('view_all_button')}</SmallFontLinkColor>
                </MuiLink>
              ) : (
                <></>
              )}
            </RecentActivities>
            {props.activitiesAreLoaded &&
              props.assessment.activities &&
              props.assessment.activities.length != 0 &&
              props.assessment.activities
                .filter((activity) =>
                  isEmail(activity)
                    ? Date.now() / 1000 - activity.created > 0
                    : Date.now() / 1000 - activity.timestamp > 0,
                )
                .filter((activity) => getActivityOrEmailType(activity) !== ActivityType.NONE)
                .sort((a, b) => {
                  const aTimestamp = isEmail(a) ? a.created : a.timestamp;
                  const bTimestamp = isEmail(b) ? b.created : b.timestamp;
                  return bTimestamp - aTimestamp;
                })
                .slice(0, 8)
                .map((activity: any, i) => {
                  if (
                    activity.type === ActivityType.DDQ_INHERITED_BY_TPTA ||
                    activity.type === ActivityType.TPTA_INHERITED_DDQ
                  ) {
                    if (!props.assessment.inheritedDdq) {
                      return <></>;
                    }
                  }
                  //keeping any as activity is of Activity or Email they both don't have common fields
                  return (
                    // Blank string for if it's email is because email only has 1 case
                    // but Panther always needs something or else it will break.
                    // Temporary key
                    <MuiBox key={i} className="recent-activities-container">
                      <SmallFontType className="recent-activities">
                        {bundle.formatMessage('activity_item_text', {
                          textType: getActivityOrEmailType(activity),
                          tptaNumber: !isEmail(activity) ? props.assessment.tpsRecordId : '',
                          tpsRecordId: activity.tpsRecordId ? activity.tpsRecordId : '',
                          previousValue: !isEmail(activity) ? activity.previousValue! : '',
                          currentValue: !isEmail(activity) ? activity.currentValue! : '',
                          performedBy: !isEmail(activity) ? activity.personName! : '',
                          questionnaireName:
                            !isEmail(activity) && getDdqName(activity.tpsRecordId, props.assessment, bundle),
                          inheritedTPTAId: !isEmail(activity) ? props.assessment.inheritedDdqTpsRecordId! : '',
                          // Reverse logic here because these are needed for email
                          emailUsers: isEmail(activity) ? activity.recipients! : '',
                          ccUsers: isEmail(activity) ? activity.copied! : '',
                        })}
                      </SmallFontType>
                      <SmallFontType style={{ width: '75px', paddingLeft: '10px', textAlign: 'end' }}>
                        {bundle.formatMessage(
                          'activity_timestamp',
                          determineTimeDiff(isEmail(activity) ? activity.created : activity.timestamp),
                        )}
                      </SmallFontType>
                    </MuiBox>
                  );
                })}
          </MuiCollapse>
        </MuiCardContent>
        {outcomeStrToEnum(props.assessment.assessmentOutcome) !== AssessmentOutcomeStatus.NONE &&
        outcomeStrToEnum(props.assessment.assessmentOutcome) !== AssessmentOutcomeStatus.NOT_APPLICABLE &&
        props.type === SwimlaneType.ISSUE ? (
          <StatusIndicator
            className="assessment-outcome-indicator"
            sx={outcomeLabelWithTextColor(outcomeStrToEnum(props.assessment.assessmentOutcome))}
          >
            <IssuesAssessmentOutCome>
              {bundle.formatMessage('outcome_status', {
                outcome: outcomeStrToEnum(props.assessment.assessmentOutcome),
              })}
            </IssuesAssessmentOutCome>
          </StatusIndicator>
        ) : null}
      </MuiCard>
    </>
  );
}

export default withBundle('components.ThirdPartyTieringAssessmentCard')(ThirdPartyTieringAssessmentCard);
