/**
 * Constants used for declaring the default localization.
 */
import { LocalizationContextBuilder } from '@amzn/arb-tools';

export const DEFAULT_LOCALE = 'en-US';
export const DEFAULT_LC_BUILDER = new LocalizationContextBuilder().withDefaultLocale(DEFAULT_LOCALE);

/**
 * Using MUI you will sometimes need to override the styles directly in the
 * code and not through CSS. This is because a lot of MUI styles override our
 * CSS file's styling.
 */

// Base colors
export const BLUE_BLACK = '#0e212d';
export const DARK_GRAY = '#666';
export const GRAY = '#c4c4c4';
export const LIGHT_GRAY = '#f4f4f4';
export const OFF_BLACK = '#2c3042';
export const WHITE = '#fff';
export const BLACK = '#000000';

// Primary
export const PRIMARY_BLUE = '#132f52';
export const SECONDARY_BLUE = '#335669';
export const TERTIARY_BLUE = '#6e90a3';

// Secondary
export const ASSESSOR_ORANGE = '#e49b57';
export const AMAZON_ORANGE = '#f79b34';
export const DARK_ORANGE = '#bc3e00';
export const FADED_RED = '#ef9494';
export const LINK_BLUE = '#126de0';
export const MINT_GREEN = '#1f8476';
export const RED = '#960404';
export const REQUESTER_GREEN = '#71d599';
export const VENDOR_PURPLE = '#a2a9ed';
export const YELLOW = '#e6be2c';

//Side bar width, height
export const SIDEBAR_WIDTH_PX = 264;
export const SIDEBAR_WIDTH_PX_SM = 174;

export const ALERT_BANNNER_HEIGHT_PX = 70;
export const ALERT_BANNNER_HEIGHT_SM_PX = 93;
export const ALERT_BANNER_PADDING_TOP_BOTTOM = 6;

export const NAV_HEIGHT_PX = 66;
export const NAV_HEIGHT_SM_PX = 66;

export const BREADCRUMBS_HEIGHT_PX = 24;
export const BREADCRUMBS_HEIGHT_SM_PX = 24;
export const BREADCRUMBS_PADDING_PX = 10;

export const TOP_NAV_HEIGHT =
  ALERT_BANNNER_HEIGHT_PX +
  NAV_HEIGHT_PX +
  BREADCRUMBS_HEIGHT_PX +
  BREADCRUMBS_PADDING_PX * 2 +
  ALERT_BANNER_PADDING_TOP_BOTTOM * 2;

export const TOP_NAV_HEIGHT_SM =
  ALERT_BANNNER_HEIGHT_SM_PX +
  NAV_HEIGHT_SM_PX +
  BREADCRUMBS_HEIGHT_SM_PX +
  BREADCRUMBS_PADDING_PX * 2 +
  ALERT_BANNER_PADDING_TOP_BOTTOM * 2;

export const TOP_NAV_HEIGHT_NO_BANNER = NAV_HEIGHT_PX + BREADCRUMBS_HEIGHT_PX + BREADCRUMBS_PADDING_PX * 2;

export const TOP_NAV_HEIGHT_NO_BANNER_SM = NAV_HEIGHT_SM_PX + BREADCRUMBS_HEIGHT_SM_PX + BREADCRUMBS_PADDING_PX * 2;

//Font Family for the font style
export const AMAZON_EMBER = 'Amazon Ember';

//Filter bar textTransform for muibuttons
export const TEXT_TRANS = 'none';

export const HOVER_EFFECT = {
  color: LINK_BLUE,
  background: 'none',
  cursor: 'pointer',
};

export const DEEP_LINK = 'deepLink';

export enum BREAKPOINTS {
  MAP_MINIMUM_ZOOM = 'MAPMinimumZoomResolution',
  MAP_MINIMUM = 'MAPMinimumResolution',
  MAP_MEDIUM = 'MAPMediumResolution',
  MAP_MAXIMUM = 'MAPMaximumResolution',
}

/**
 * Per our current metrics (as of 10/13/23), our user base consists of
 * the following screen sizes:
 *
 * - LARGE  : 23.22% of users have 1920x1080
 * - MEDIUM : 10.76% of users have 1536x864
 * - SMALL  : 16.28% of users have 1366x768
 *
 * Since this takes up more than 50% of our users, these will be our
 * focus resolutions.
 *
 * MUI's theme.breakpoints.down function is less than exclusive, so
 * making the minimum screen width 1 higher than we want.
 * https://tiny.amazon.com/ljouy1ev/muimatecustbreathe
 */
export const BREAKPOINT = {
  MAPMinimumZoomResolution: 1024,
  MAPMinimumResolution: 1377,
  MAPMediumResolution: 1536,
  MAPMaximumResolution: 1920,
};

export const TPS_USER_AUTHENTICATION_SIGNAL = 'TPSMap_userIsAuthenticated';
export const LOGGING_OUT = 'TPSMap_loggingOut';
export const THIRD_PARTY_FLAG = 'TPSMap_is3p';
export const THIRD_PARTY_TARGET = 'TPSMap_is3pTarget';
export const FALSE = 'false';
export const TRUE = 'true';

export enum PAGE_TITLE {
  LANDING_PAGE = 'track_my_assessment',
  ISSUES_PAGE = 'my_issues',
  TASKS_PAGE = 'my_tasks',
  CONSULTS_PAGE = 'my_consults',
  SURVEY_PAGE = 'my_surveys',
  COMPLETE_ASSESSMENTS_PAGE = 'complete_assessments',
}

export enum FILTER_LABELS {
  VENDOR = 'vendor',
  TIER = 'tier',
  STATE = 'state',
  SEVERITY = 'severity',
  ISSUE_STATE = 'issue_state',
  ISSUE_SEVERITY = 'issue_severity',
  TASK_STATE = 'task_state',
  TASK_SEVERITY = 'task_severity',
  OUTCOME = 'outcome',
  SEARCH = 'search',
}

export const GET_OPERATION_ERROR = 'GET Operation Error';
export const PUT_OPERATION_ERROR = 'PUT Operation Error';
export const POST_OPERATION_ERROR = 'POST Operation Error';
export const API_FAILURE = 'API call failed';
