import MuiAvatar from '@mui/material/Avatar';
import MuiStack from '@mui/material/Stack';
import MuiBox from '@mui/material/Box';
import MuiTypography from '@mui/material/Typography';
import { REQUESTER_GREEN, ASSESSOR_ORANGE, VENDOR_PURPLE } from '../../constants/constants';
import { useUserContext } from '../../context/UserProvider';
import { RoleType, UserMetadata } from '../../data/UserMetadata';
import { styled } from '@mui/material/styles';
import './TopBarNav.css';

const ProfileName = styled(MuiTypography)(({ theme }) => ({
  fontSize: '13px',
  color: theme.palette.primary.contrastText,
  marginLeft: '-8px',
}));

//This function is to show the color for each type of the role.
function getColor(role: RoleType) {
  switch (role) {
    case RoleType.REQUESTER:
      return REQUESTER_GREEN;
    case RoleType.ASSESSOR:
      return ASSESSOR_ORANGE;
    case RoleType.VENDOR:
      return VENDOR_PURPLE;
    default:
      return ASSESSOR_ORANGE;
  }
}

function stringAvatar(userName: string, role: RoleType) {
  // TODO add to Panther file
  const name = !userName ? 'No Name' : userName;
  const avatar = {
    sx: {
      marginTop: 0,
      width: '43px',
      height: '43px',
      fontSize: '21px',
      // TODO After getting user details we can handle this better
      bgcolor: getColor(role),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };

  return avatar;
}

function ProfileAvatar() {
  const user: UserMetadata = useUserContext();

  return (
    <MuiStack className="user-profile-container" direction="row" spacing={2} sx={{ marginRight: '80px' }}>
      <MuiAvatar {...stringAvatar(user.name, user.role)} />
      <MuiBox>
        <ProfileName>{user.name}</ProfileName>
      </MuiBox>
    </MuiStack>
  );
}

export default ProfileAvatar;
