import { useImpersonation, useUserContext } from '../context/UserProvider';
import { useMetricsAndLoggerContext } from '../logging/MetricsProvider';

export const retryConfig = {
  retry: 3,
  retryDelay: (attempt: number) => {
    return 2 ** attempt * 500; // .5, 1, and 2 seconds between retries
  },
};

export const cacheConfig = {
  staleTime: 60 * 60 * 1000, // data is considered stale after 1 hour
};

export const getCommonParams = () => {
  const { authToken } = useUserContext();
  const { metricsPublisher, logger } = useMetricsAndLoggerContext();

  return {
    authToken,
    metricsPublisher,
    logger,
  };
};
