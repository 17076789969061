import React from 'react';
import { Auth } from 'aws-amplify';
import MuiCircularProgress from '@mui/material/CircularProgress';
import MuiTypography from '@mui/material/Typography';
import { DEEP_LINK, FALSE, TPS_USER_AUTHENTICATION_SIGNAL, TRUE } from '../../constants/constants';
import { BASE_URL } from '../../constants/urlConstants';

/* 
  NOTE
  ================================================================================= 
  Using the location state (set in PrivateRouter Component) so we can redirect 
  back to the original page we came from before authentication.

  We pass it as customState to Cognito and once we get the response and listen for 
  the customOAuthState event we can then redirect.
  
  ** See the AuthenticatedUserProvider component for the receiving end of the call. **
  =================================================================================
*/

const EXCLUDED_DEEP_LINKS_URL_SET = new Set([`${BASE_URL}`, `${BASE_URL}/`]); // These are expected urls we encounter on initial user login, so created an exclude set so this should not impact record specific deeplinks

const FederatedSignIn = (): React.ReactElement => {
  Auth.currentAuthenticatedUser()
    .then((_user) => {
      if (process.env.NODE_ENV == 'development') console.log('_user', _user);
    })
    .catch(() => {
      //storing deeplink information before redirecting to federate to consume after authentication
      //adding condition here so deepLink value is not overridden incase of any redirection url encoutered during login & logout
      if (!EXCLUDED_DEEP_LINKS_URL_SET.has(window.location.href)) {
        window.localStorage.setItem(DEEP_LINK, `${window.location.href}`);
      }

      localStorage.setItem(TPS_USER_AUTHENTICATION_SIGNAL, FALSE);
      Auth.federatedSignIn({
        customProvider: 'AmznFederated',
      })
        .then(() => {
          localStorage.setItem(TPS_USER_AUTHENTICATION_SIGNAL, TRUE);
        })
        .catch((err) => {
          localStorage.removeItem(TPS_USER_AUTHENTICATION_SIGNAL);
          console.log(err);
        });
    });

  return (
    <React.Fragment>
      <MuiTypography>Sign In in Progress....</MuiTypography>
      <MuiCircularProgress />
    </React.Fragment>
  );
};

export default FederatedSignIn;
