import React, { MouseEventHandler, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import MuiAvatar from '@mui/material/Avatar';
import MuiMenu from '@mui/material/Menu';
import MuiBox from '@mui/material/Box';
import { styled } from '@mui/material';
import { MessageBundle } from '@amzn/arb-tools';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import { ClipboardSvg, UpdateSvg, ClipboardNotificationSvg } from './TodoListSvg';
import { GRAY, RED, HOVER_EFFECT, LINK_BLUE } from '../../constants/constants';
import Assessment, {
  Activity,
  ActivityType,
  Consult,
  Email,
  Survey,
  TPSRecordType,
  TakeActionType,
} from '../../data/RecordMetadata';
import { ImpersonationIntentType, useImpersonation, useUserContext } from '../../context/UserProvider';
import {
  activitiesDataIsLoaded,
  allPageDataIsLoaded,
  getRankedNotifications,
  getOverallLoadState,
  getActivityType,
  getActivityOrEmailType,
  isTakeActionItemActionable,
} from '../../utils/dataUtils';
import { NotificationItem } from '../../data/RecordMetadata';
import { LoadState, TpsData, useDataContext } from '../../context/DataProvider';
import { RoleType } from '../../data/UserMetadata';
import LoadingSpinner from '../LoadingComponents/LoadingSpinner';
import { tierToEnum, activityTypeToEnum, getDdqName } from '../../utils/enumUtils';
import { getActivityLink } from '../../utils/redirectionUtils';
import './TodoList.css';

/**
 * These types are for sorting and memoizing the updates
 * section.
 */
interface UpdateBase {
  containingTpsRecordId: string;
  tpsRecordType: string;
  tpsRecordId: string;
  tpsRecordSystemId: string;
  link: string;
  vendor: string;
  tier: string;
  inheritedTPTAId?: string;
}
interface EmailItem extends UpdateBase {
  activity?: never;
  email: Email;
}
interface ActivityItem extends UpdateBase {
  activity: Activity;
  ddqName: string;
  email?: never;
}

type UpdateItem = EmailItem | ActivityItem;

const CardHeaderStyled = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '70%',
  paddingLeft: '20px',
  paddingBottom: '30px',
});

const CardHeaderButton = styled('button')({
  border: 'none',
  backgroundColor: 'white',
  padding: 0,
  margin: 0,
});

const EmptyTodo = styled('p')({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
});

const TodoItemLink = styled(Link)({
  textDecoration: 'none',
});

const UpdateItemLink = TodoItemLink;

const Avatar = styled(MuiAvatar)({
  height: '24px',
  width: '24px',
  fontSize: '10px',
});

const LargeFont = styled('p')({
  margin: '0 4px',
  fontSize: '13px',
});

const SmallFont = styled('p')({
  margin: '0 4px',
  fontSize: '9px',
});

const TodoItemFont = styled('p')({
  fontSize: '14px',
});

const VendorIdTierFront = styled('p')({
  fontSize: '12px',
  '&:hover': HOVER_EFFECT,
});

const FullNameFont = styled(LargeFont)({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxWidth: '100%',
});

const NameContainer = styled(MuiBox)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
});

const UpdatePayload = styled('p')({
  margin: '0 4px',
  paddingTop: '5px',
  fontSize: '11px',
});

const SmallCircle = styled('div')({
  height: '8px',
  width: '8px',
  backgroundColor: GRAY,
  borderRadius: '50%',
  display: 'inline-block',
  marginRight: '15px',
  marginTop: '3px',
  marginLeft: '-4px',
});

const shouldNotBeLinks: Set<ActivityType> = new Set([
  ActivityType.DDQ_INHERITED_BY_TPTA,
  ActivityType.TPTA_INHERITED_DDQ,
  ActivityType.EMAIL_SENT,
  ActivityType.NONE,
]);

const isLink = (update: UpdateItem): boolean => {
  if (update.email) return false;
  //adding an edge where some ddq have missing Assessment association
  if (update.tpsRecordType === TPSRecordType.DDQ && !update.containingTpsRecordId) return false;

  return !shouldNotBeLinks.has(update.activity.type);
};

interface TodoTabProps {
  bundle: MessageBundle;
  notifications: NotificationItem[];
  handleClose: MouseEventHandler<HTMLAnchorElement>;
}
function TodoTab({ bundle, notifications, handleClose }: TodoTabProps) {
  return (
    <React.Fragment>
      {notifications.length === 0 ? (
        <EmptyTodo>{bundle.getMessage('no_action_items')}</EmptyTodo>
      ) : (
        notifications.map((notification: NotificationItem, index: number) => {
          const bgColor = (index + 1) % 2 == 0 ? 'even' : 'odd';
          return (
            // Using index because these don't change unless page is refreshed
            // and records can have more than 1 item.
            <div key={index} className={`todo-list-item-container ${bgColor}`}>
              <TodoItemLink
                to={notification.link}
                onClick={handleClose}
                sx={{
                  color: isTakeActionItemActionable(notification.takeActionType) ? RED : 'inherit',
                  '&:hover': {
                    '*': HOVER_EFFECT,
                  },
                }}
              >
                <div className="todo-list-item">
                  <div style={{ width: '30px' }}>{index + 1}.</div>
                  <div className="todo-list-content">
                    <TodoItemFont>
                      {bundle.formatMessage('take_action_text', {
                        expirationDate: notification.expiresOnDate ? notification.expiresOnDate : 0,
                        textType: notification.takeActionType,
                        trpiNum: notification.containedRecordId,
                        questionnaireName: bundle.formatMessage('ddq_type', {
                          type: notification.questionnaireType,
                        }),
                      })}
                    </TodoItemFont>
                    {notification.takeActionType !== TakeActionType.CONSULT_ACTIONABLE &&
                      notification.takeActionType !== TakeActionType.SURVEY_ACTIONABLE && (
                        <VendorIdTierFront className="todo-item-metadata">
                          {bundle.formatMessage('vendor_id_tier', {
                            vendor:
                              notification.vendor && notification.vendor !== ''
                                ? notification.vendor
                                : bundle.getMessage('vendor_missing'),
                            tpsRecordId:
                              notification.tpsRecordId && notification.tpsRecordId !== ''
                                ? notification.tpsRecordId
                                : bundle.getMessage('record_id_missing'),
                            tier: bundle.formatMessage('tier', { tier: tierToEnum(notification.tier) }),
                            takeActionType: notification.takeActionType,
                          })}
                        </VendorIdTierFront>
                      )}
                  </div>
                </div>
              </TodoItemLink>
            </div>
          );
        })
      )}
    </React.Fragment>
  );
}

interface UpdatesTabProps extends WithBundleProps {
  updateItems: UpdateItem[];
  handleClose: MouseEventHandler<HTMLAnchorElement>;
}
function UpdatesTab({ bundle, updateItems, handleClose }: UpdatesTabProps) {
  return (
    <div className="inner-updates-content-container">
      {updateItems.length === 0 ? (
        <EmptyTodo>{bundle.getMessage('no_update_items')}</EmptyTodo>
      ) : (
        updateItems.map((update: UpdateItem, index: number) => {
          const getActivityAvatarName = (name: string): string => {
            const splitName = name.split(' ').filter((str) => str[0] !== '[');
            if (splitName.length === 0) return bundle.getMessage('no_name_avatar');
            else if (splitName.length === 1) return splitName[0][0];
            else return `${splitName[0][0]}${splitName[1][0]}`;
          };
          const getAvatarName = (): string =>
            update.email
              ? bundle.getMessage('email_name')
              : getActivityAvatarName(update.activity.personName).toUpperCase();
          const avatarName =
            update.activity && update.activity.personType === 'system'
              ? bundle.getMessage('system_avatar_name')
              : getAvatarName();

          const getFullName = () =>
            !update.email
              ? !update.activity.personName
                ? bundle.getMessage('no_name')
                : update.activity.personName
              : update.email.recipients;
          const fullName =
            update.activity && update.activity.personType === 'system'
              ? bundle.getMessage('system_name')
              : getFullName();

          const bgColor = (index + 1) % 2 == 0 ? 'odd' : 'even';

          const getDisplayRecordId = () => {
            if (update.tpsRecordType === TPSRecordType.DDQ) {
              return update.containingTpsRecordId !== ''
                ? update.containingTpsRecordId
                : bundle.getMessage('record_id_missing');
            }
            return update.tpsRecordId && update.tpsRecordId !== ''
              ? update.tpsRecordId
              : bundle.getMessage('record_id_missing');
          };

          // To make the look of swapping links in and out neater
          const Update = () => {
            return (
              <React.Fragment>
                <div className="update-item-container">
                  <div>
                    <SmallCircle />
                  </div>
                  <div className="update-inner-content-container">
                    <div className="update-top-line-container">
                      <NameContainer>
                        <Avatar>{avatarName}</Avatar>
                        <FullNameFont>{fullName}</FullNameFont>
                      </NameContainer>
                      <SmallFont>
                        {bundle.formatMessage('updates_date_time', {
                          dateOpened: !update.email
                            ? new Date(0).setUTCSeconds(update.activity.timestamp)
                            : new Date(0).setUTCSeconds(update.email.created),
                        })}
                      </SmallFont>
                    </div>
                    <UpdatePayload className="recent-activities">
                      {bundle.formatMessage('activity_item_text', {
                        textType: !update.email ? getActivityType(update.activity) : ActivityType.EMAIL_SENT,
                        tptaNumber: !update.email ? update.containingTpsRecordId : '',
                        tpsRecordId: !update.email ? update.activity.tpsRecordId : update.tpsRecordId,
                        previousValue: !update.email ? update.activity.previousValue! : '',
                        currentValue: !update.email ? update.activity.currentValue! : '',
                        performedBy: !update.email ? update.activity.personName! : '',
                        questionnaireName:
                          !update.email && update.ddqName ? update.ddqName : bundle.getMessage('missing_ddq_name'),
                        inheritedTPTAId: !update.email ? update.inheritedTPTAId : '',
                        // Reverse logic here because these are needed for email
                        emailUsers: update.email ? update.email.recipients! : '',
                        ccUsers: update.email ? update.email.copied! : '',
                      })}
                    </UpdatePayload>
                    <SmallFont>
                      {bundle.formatMessage('vendor_id_tier_updates', {
                        recordType: update.tpsRecordType,
                        vendor:
                          update.vendor && update.vendor !== '' ? update.vendor : bundle.getMessage('vendor_missing'),
                        tpsRecordId: getDisplayRecordId(),
                        tier: bundle.formatMessage('tier', { tier: tierToEnum(update.tier) }),
                      })}
                    </SmallFont>
                  </div>
                </div>
              </React.Fragment>
            );
          };

          return (
            <div key={index} className={`take-action-update-container ${isLink(update) && 'link'} ${bgColor}`}>
              {isLink(update) ? (
                <UpdateItemLink to={update.link} onClick={handleClose} sx={{ color: 'unset' }}>
                  <Update />
                </UpdateItemLink>
              ) : (
                <MuiBox style={{ color: 'unset' }}>
                  <Update />
                </MuiBox>
              )}
            </div>
          );
        })
      )}
    </div>
  );
}

function getSortedUpdates(
  dataState: TpsData,
  role: RoleType,
  impersonationIntent: ImpersonationIntentType,
  bundle: MessageBundle,
): UpdateItem[] {
  const updates: UpdateItem[] = [];

  // For getting the Vendor and Tier
  const consultMap: Map<string, Consult> = new Map(dataState.consults.map((consult) => [consult.tpsRecordId, consult]));
  const surveyMap: Map<string, Survey> = new Map(dataState.surveys.map((survey) => [survey.tpsRecordId, survey]));
  const assessmentMap: Map<string, Assessment> = new Map();
  dataState.assessments.forEach((assessment) => {
    assessmentMap.set(assessment.tpsRecordId, assessment);
    if (assessment.questionnaires && assessment.questionnaires.length > 0) {
      assessment.questionnaires.forEach((record) => assessmentMap.set(record.id, assessment));
    }
    if (assessment.issues && assessment.issues.length > 0) {
      assessment.issues.forEach((record) => assessmentMap.set(record.tpsRecordId, assessment));
    }
    if (assessment.tasks && assessment.tasks.length > 0) {
      assessment.tasks.forEach((record) => assessmentMap.set(record.tpsRecordId, assessment));
    }
  });

  // Gets the Vendor / Name depending on the record
  const getVendor = (id: string): string => {
    if (assessmentMap.has(id)) return assessmentMap.get(id)!.vendor;
    else if (consultMap.has(id)) return consultMap.get(id)!.name;
    else if (surveyMap.has(id)) return surveyMap.get(id)!.name;
    return '';
  };

  // Needed for redirection utils
  const getTypeId = (id: string): string => {
    if (!assessmentMap.has(id)) return '';
    if (assessmentMap.get(id)!.questionnaires && assessmentMap.get(id)!.questionnaires!.length === 0) return '';

    const questionnaire = assessmentMap.get(id)!.questionnaires!.find((record) => record.id === id);
    if (!questionnaire) return '';
    return questionnaire.typeId;
  };

  dataState.activities.forEach((record) => {
    record.activities
      .filter((activity) => getActivityOrEmailType(activity) !== ActivityType.NONE)
      .forEach((activity) => {
        updates.push({
          containingTpsRecordId: !assessmentMap.has(record.tpsRecordId)
            ? ''
            : assessmentMap.get(record.tpsRecordId)!.tpsRecordId,
          tpsRecordType: activity.tpsRecordType!,
          tpsRecordId: activity.tpsRecordId!,
          tpsRecordSystemId: activity.tpsRecordSystemId!,
          link: getActivityLink(activity, role, impersonationIntent, getTypeId(record.tpsRecordId)),
          vendor: getVendor(record.tpsRecordId),
          tier: !assessmentMap.has(record.tpsRecordId) ? '' : tierToEnum(assessmentMap.get(record.tpsRecordId)!.tier),
          inheritedTPTAId:
            activityTypeToEnum(activity.type) === ActivityType.TPTA_INHERITED_DDQ
              ? assessmentMap.get(record.tpsRecordId)?.inheritedDdqTpsRecordId
              : '',
          ddqName: !assessmentMap.has(record.tpsRecordId)
            ? ''
            : getDdqName(activity.tpsRecordId!, assessmentMap.get(record.tpsRecordId)!, bundle),
          activity: activity,
        });
      });

    record.emailsSent.forEach((email) =>
      updates.push({
        containingTpsRecordId: !assessmentMap.has(record.tpsRecordId)
          ? ''
          : assessmentMap.get(record.tpsRecordId)!.tpsRecordId,
        tpsRecordType: email.tpsRecordType!,
        tpsRecordId: email.tpsRecordId!,
        tpsRecordSystemId: email.tpsRecordSystemId!,
        link: '',
        vendor: getVendor(record.tpsRecordId),
        tier: !assessmentMap.has(record.tpsRecordId) ? '' : tierToEnum(assessmentMap.get(record.tpsRecordId)!.tier),
        email: email,
      }),
    );
  });

  // Sort everything as one big mass
  return updates
    .filter((a) => {
      const timestamp = !a.email ? a.activity.timestamp : a.email.created;
      // Precomputed 90 days in UTC seconds as per BRD updates Tab needs to see only 3 months before records
      //Current Activities API is returing all past activity records
      // 60sec * 60 min * 24hrs * 90 days = 7776000
      return Date.now() / 1000 - timestamp - 7776000 < 0;
    })
    .sort((a, b) => {
      const aTimestamp = !a.email ? a.activity!.timestamp : a.email!.created;
      const bTimestamp = !b.email ? b.activity!.timestamp : b.email!.created;

      return bTimestamp - aTimestamp;
    });
}

function TodoList({ bundle }: WithBundleProps) {
  const user = useUserContext();
  const { impersonationIntent } = useImpersonation();
  const [dataState] = useDataContext();
  const [notifications, setNotifications] = useState<NotificationItem[]>([]);
  const [updates, setUpdates] = useState<UpdateItem[]>([]);
  const [anchorEl, setAnchorElement] = React.useState<null | HTMLElement>(null);
  const [isUpdates, setIsUpdates] = useState(false);

  const memoizedRankedData = useMemo(
    () => getRankedNotifications(dataState, user, impersonationIntent),
    [dataState, user, impersonationIntent],
  );
  const memoizedUpdates = useMemo(
    () => getSortedUpdates(dataState, user.role, impersonationIntent, bundle),
    [dataState.activities, dataState.inProgressAssessments, dataState.consults, dataState.surveys],
  );

  const actionableRankedCount = useMemo(
    () => memoizedRankedData.filter((rankedData) => isTakeActionItemActionable(rankedData.takeActionType)).length,
    [memoizedRankedData],
  );

  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  useEffect(() => {
    if (!user) return;
    if (!allPageDataIsLoaded(dataState, user.role)) return;
    setNotifications(memoizedRankedData);
  }, [dataState, user]);

  useEffect(() => {
    if (!user) return;
    if (!activitiesDataIsLoaded(dataState)) return;
    if (!allPageDataIsLoaded(dataState, user.role)) return;
    setUpdates(memoizedUpdates);
  }, [dataState, dataState.activities, dataState.assessments, user]);

  return (
    <div className="take-action-container">
      <div
        role="button"
        aria-controls={open ? 'take-action-menu-content' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpen}
        className="take-action-button"
        id="take-action-icon-button"
      >
        {notifications.length > 0 ? (
          <ClipboardNotificationSvg notificationCount={actionableRankedCount} />
        ) : (
          <ClipboardSvg />
        )}
      </div>
      <MuiMenu
        id="take-action-menu-content"
        aria-labelledby="take-action-icon-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className="take-action-card">
          <div role="button" onClick={handleClose} className="take-action-inner-button">
            {notifications.length > 0 ? (
              <ClipboardNotificationSvg notificationCount={actionableRankedCount} />
            ) : (
              <ClipboardSvg />
            )}
          </div>
          <div className="take-action-card-content-container">
            <CardHeaderStyled className="take-action-card-header">
              <CardHeaderButton
                className="take-action-header-button"
                onClick={() => setIsUpdates(false)}
                sx={
                  !isUpdates
                    ? {
                        color: LINK_BLUE,
                        fill: LINK_BLUE,
                      }
                    : {
                        color: GRAY,
                        fill: GRAY,
                      }
                }
              >
                {notifications.length > 0 ? (
                  <ClipboardNotificationSvg notificationCount={actionableRankedCount} />
                ) : (
                  <ClipboardSvg />
                )}
                {bundle.getMessage('action_header_button')}
              </CardHeaderButton>
              <CardHeaderButton
                className="updates-header-button"
                onClick={() => setIsUpdates(true)}
                sx={
                  isUpdates
                    ? {
                        color: LINK_BLUE,
                        fill: LINK_BLUE,
                      }
                    : {
                        color: GRAY,
                        fill: GRAY,
                      }
                }
              >
                <UpdateSvg />
                {bundle.getMessage('updates_header_button')}
              </CardHeaderButton>
            </CardHeaderStyled>
            {!isUpdates ? (
              <div className="todo-list-container">
                {getOverallLoadState(dataState) === LoadState.LOADING && <LoadingSpinner />}
                {getOverallLoadState(dataState) === LoadState.SUCCESS && (
                  <TodoTab bundle={bundle} notifications={notifications} handleClose={handleClose} />
                )}
              </div>
            ) : (
              <div className="updates-content-container">
                {getOverallLoadState(dataState) === LoadState.LOADING && <LoadingSpinner />}
                {getOverallLoadState(dataState) === LoadState.SUCCESS && (
                  <UpdatesTab bundle={bundle} updateItems={updates} handleClose={handleClose} />
                )}
              </div>
            )}
          </div>
        </div>
      </MuiMenu>
    </div>
  );
}

export default withBundle('components.TodoList')(TodoList);
