import React from 'react';
import { Link } from 'react-router-dom';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import MuiBox from '@mui/material/Box';
import MuiCard from '@mui/material/Card';
import MuiCardContent from '@mui/material/CardContent';
import MuiTypography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import MuiLink from '@mui/material/Link';
import { BREAKPOINT } from '../../constants/constants';
import { NEW_ASSESSMENT_SYS_ID, LEARN_MORE, SnowUrlType } from '../../constants/urlConstants';
import getTPSPortalUrl from '../../utils/redirectionUtils';
import { useImpersonation, useUserContext } from '../../context/UserProvider';

const IntakeText = styled(MuiTypography)(({ theme }) => ({
  color: theme.palette.primary.primary,
  '&:hover': {
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '11px',
  },
}));

function NewIntakeCard({ bundle }: WithBundleProps) {
  const { role: userRole } = useUserContext();
  const { impersonationIntent } = useImpersonation();

  return (
    <React.Fragment>
      <MuiCard variant="elevation">
        <MuiCardContent style={{ paddingBottom: '5px' }}>
          <MuiBox className="new-intake-card-header-container">
            <MuiLink
              {...{
                component: Link,
                to: getTPSPortalUrl({
                  id: NEW_ASSESSMENT_SYS_ID,
                  linkType: SnowUrlType.NEW_ASSESSMENT,
                  userRole,
                  impersonationIntent,
                }),
              }}
              underline="none"
            >
              <IntakeText>{bundle.getMessage('card_header')}</IntakeText>
            </MuiLink>
            <MuiLink {...{ component: Link, to: LEARN_MORE }} underline="none" target="_blank">
              <IntakeText>{bundle.getMessage('learn_more')}</IntakeText>
            </MuiLink>
          </MuiBox>
        </MuiCardContent>
      </MuiCard>
    </React.Fragment>
  );
}

export default withBundle('components.NewIntakeCard')(NewIntakeCard);
