import { CONSULTS_PAGE, SIDEBAR_LINKS, TASKS_PAGE } from '../../constants/linkConstants';
import { RoleType } from '../../data/UserMetadata';

export interface SideBarItem {
  label: string;
  href: string;
  numActionableItems: number;
  isFocus: boolean;
}

const NON_3P_SIDEBAR_LINKS = new Set([CONSULTS_PAGE.label, TASKS_PAGE.label]);

/* --------------- Helper Components --------------- */
export function getSidebarInitValues(href: string, role: RoleType): SideBarItem[] {
  const sidebarLinks =
    role === RoleType.ASSESSOR || role === RoleType.REQUESTER
      ? SIDEBAR_LINKS
      : SIDEBAR_LINKS.filter((link) => !NON_3P_SIDEBAR_LINKS.has(link.label));
  return sidebarLinks.map((link) => {
    return {
      label: link.label,
      href: link.href,
      isFocus: href === link.href,
      numActionableItems: 0,
    };
  });
}

type SidebarActions =
  | { type: 'changeFocus'; payload: { href: string } }
  | { type: 'updateActionableItems'; payload: { label: string; data: number } }
  | { type: 'updateRole'; payload: { href: string; role: RoleType } };

export function sidebarReducer(state: SideBarItem[], action: SidebarActions) {
  switch (action.type) {
    case 'changeFocus':
      return state.map((item) => {
        item.isFocus = false;
        if (item.href === action.payload.href) item.isFocus = true;
        return item;
      });
    case 'updateActionableItems':
      return state.map((item) => {
        if (item.label === action.payload.label) item.numActionableItems = action.payload.data;
        return item;
      });
    case 'updateRole':
      return getSidebarInitValues(action.payload.href, action.payload.role);
  }
}
