import React from 'react';
import { Outlet } from 'react-router';
import AlertBanner from './AlertBanner/AlertBanner';
import Breadcrumbs from './Breadcrumbs/Breadcrumbs';
import TakeActionList from './TakeActionList/TakeActionList';
import TopBarNav from './TopBarNav/TopBarNav';
import SideBar from './SideBar/SideBar';
import TodoList from './TodoList/TodoList';
import { DataProvider } from '../context/DataProvider';
import { RoleType } from '../data/UserMetadata';
import { useUserContext } from '../context/UserProvider';
import { ThemeProvider, styled } from '@mui/material/styles';
import theme from '../styling/theme';
import {
  BREAKPOINT,
  SIDEBAR_WIDTH_PX,
  SIDEBAR_WIDTH_PX_SM,
  TOP_NAV_HEIGHT,
  TOP_NAV_HEIGHT_NO_BANNER,
  TOP_NAV_HEIGHT_NO_BANNER_SM,
  TOP_NAV_HEIGHT_SM,
} from '../constants/constants';

const StyledMainWithBanner = styled('main')(({ theme }) => ({
  height: `calc(100vh - ${TOP_NAV_HEIGHT}px)`,
  marginTop: TOP_NAV_HEIGHT,
  width: `calc(100vw - ${SIDEBAR_WIDTH_PX}px)`,
  marginLeft: SIDEBAR_WIDTH_PX,
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    height: `calc(100vh - ${TOP_NAV_HEIGHT_SM}px)`,
    marginTop: TOP_NAV_HEIGHT_SM,
    width: `calc(100vw - ${SIDEBAR_WIDTH_PX_SM}px)`,
    marginLeft: SIDEBAR_WIDTH_PX_SM,
  },
}));

const StyledMainWithoutBanner = styled('main')(({ theme }) => ({
  height: `calc(100vh - ${TOP_NAV_HEIGHT_NO_BANNER}px)`,
  marginTop: TOP_NAV_HEIGHT_NO_BANNER,
  width: `calc(100vw - ${SIDEBAR_WIDTH_PX}px)`,
  marginLeft: SIDEBAR_WIDTH_PX,
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    height: `calc(100vh - ${TOP_NAV_HEIGHT_NO_BANNER_SM}px)`,
    marginTop: TOP_NAV_HEIGHT_NO_BANNER_SM,
    width: `calc(100vw - ${SIDEBAR_WIDTH_PX_SM}px)`,
    marginLeft: SIDEBAR_WIDTH_PX_SM,
  },
}));

const StyledHeaderWithBanner = styled('header')(({ theme }) => ({
  height: TOP_NAV_HEIGHT,
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    height: TOP_NAV_HEIGHT_SM,
  },
}));

const StyledHeaderWithoutBanner = styled('header')(({ theme }) => ({
  height: TOP_NAV_HEIGHT_NO_BANNER,
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    height: TOP_NAV_HEIGHT_NO_BANNER_SM,
  },
}));

function App() {
  const { role } = useUserContext();

  const Main = role !== RoleType.VENDOR ? StyledMainWithBanner : StyledMainWithoutBanner;
  const Header = role !== RoleType.VENDOR ? StyledHeaderWithBanner : StyledHeaderWithoutBanner;

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <DataProvider>
          <React.Fragment>
            <Header>
              {role !== RoleType.VENDOR && <AlertBanner />}
              <TopBarNav />
              <Breadcrumbs />
            </Header>
            <aside>
              <SideBar />
            </aside>
            <Main>
              <div className="main-content-container">
                <TakeActionList />
                <TodoList />
                <Outlet />
              </div>
            </Main>
          </React.Fragment>
        </DataProvider>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
