import React, { CSSProperties, useState } from 'react';
import { styled } from '@mui/material/styles';
import { StarSvg } from './StarSvg';
import { DataActions, useDataContext } from '../../context/DataProvider';
import Assessment from '../../../src/data/RecordMetadata';
import './FavoritesStar.css';
import { useCreateFavoritesForUserMutation, useDeleteFavoritesForUserMutation } from '../../apis/FavoritesAPI';

const StarButton = styled('button')({
  padding: 0,
  border: 'none',
  background: 'none',
  '&:hover': {
    background: 'none',
    cursor: 'pointer',
  },
});

interface Props {
  assessment: Assessment;
  style?: CSSProperties;
}

function setLocalFavorited(isFavorited: boolean, tptaNumber: string, dispatch: (dataAction: DataActions) => void) {
  if (isFavorited) {
    dispatch({ type: 'addFavorite', payload: tptaNumber });
  } else {
    dispatch({ type: 'removeFavorite', payload: tptaNumber });
  }
}

function handleFavorite(
  createFavoriteMutation: { isLoading: boolean; mutate: () => void },
  deleteFavoriteMutation: { isLoading: boolean; mutate: () => void },
  localFavorited: boolean,
  remoteFavorited: boolean,
) {
  if (localFavorited !== remoteFavorited) {
    if (localFavorited) {
      if (!createFavoriteMutation.isLoading) {
        createFavoriteMutation.mutate();
      }
    } else if (!deleteFavoriteMutation.isLoading) {
      deleteFavoriteMutation.mutate();
    }
  }
}

export default function FavoritesStar(props: Props) {
  const dispatch = useDataContext()[1];
  const className = props.assessment.isFavorited ? 'favorite-icon favorited' : 'favorite-icon';
  const [remoteFavorited, setRemoteFavorited] = useState(props.assessment.isFavorited || false);
  const updateFavoritesParams = {
    tptaNumber: props.assessment.tpsRecordId,
    setLocal: (favorite: boolean) => setLocalFavorited(favorite, props.assessment.tpsRecordId, dispatch),
    setRemote: (favorite: boolean) => setRemoteFavorited(favorite),
  };
  const createFavoritePromise = useCreateFavoritesForUserMutation(updateFavoritesParams);
  const deleteFavoritePromise = useDeleteFavoritesForUserMutation(updateFavoritesParams);
  Promise.all([createFavoritePromise, deleteFavoritePromise]).then(
    ([createFavoriteMutation, deleteFavoriteMutation]) => {
      props.assessment.isFavorited !== undefined &&
        handleFavorite(createFavoriteMutation, deleteFavoriteMutation, props.assessment.isFavorited, remoteFavorited);
    },
  );

  const handleClick = () => {
    setLocalFavorited(!props.assessment.isFavorited, props.assessment.tpsRecordId, dispatch);
  };

  return (
    <StarButton className={className} onClick={handleClick} style={props.style}>
      <StarSvg />
    </StarButton>
  );
}
