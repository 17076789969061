import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { get } from './ApiGatewayInvoker';
import Assessment, { AssessmentPayload } from '../data/RecordMetadata';
import { retryConfig, cacheConfig, getCommonParams } from './ApiUtils';
import { useImpersonation } from '../context/UserProvider';

const ISSUES_API_PATH = '/api/v1/issues';

export const useGetIssuesByUserQuery = (): UseQueryResult<AssessmentPayload> => {
  const { impersonationTarget } = useImpersonation();
  const commonParams = getCommonParams();

  return useQuery<AssessmentPayload>({
    queryKey: ['getIssuesKey', impersonationTarget],
    queryFn: async () => {
      const data = await get<{ assessmentIssues: Assessment[] }>({
        apiPath: `${ISSUES_API_PATH}`,
        name: 'Issue',
        user: impersonationTarget,
        emptyResponseConfig: {
          404: { assessmentIssues: [] },
        },
        ...commonParams,
      });
      return { assessments: [...data.assessmentIssues] };
    },
    ...retryConfig,
    ...cacheConfig,
  });
};
