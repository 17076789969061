import React from 'react';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import MuiLink from '@mui/material/Link';
import MuiAlert from '@mui/material/Alert';
import MuiAlertTitle from '@mui/material/AlertTitle';
import { styled } from '@mui/material/styles';
import {
  BREAKPOINT,
  ALERT_BANNNER_HEIGHT_PX,
  ALERT_BANNNER_HEIGHT_SM_PX,
  ALERT_BANNER_PADDING_TOP_BOTTOM,
} from '../../constants/constants';

const StyledAlert = styled(MuiAlert)(({ theme }) => ({
  fontSize: '16px',
  height: ALERT_BANNNER_HEIGHT_PX,
  paddingTop: ALERT_BANNER_PADDING_TOP_BOTTOM,
  paddingBottom: ALERT_BANNER_PADDING_TOP_BOTTOM,
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '14px',
    height: ALERT_BANNNER_HEIGHT_SM_PX,
  },
}));

const StyledAlertTitle = styled(MuiAlertTitle)(({ theme }) => ({
  fontSize: '18px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '16px',
  },
}));

function AlertBanner({ bundle }: WithBundleProps) {
  const bannerId = 'map-alert-banner';
  const wikiLink = 'https://w.amazon.com/bin/view/ThirdPartySecurity/New-MAP-Features/';

  return (
    <StyledAlert severity="error" icon={false} id={bannerId}>
      <StyledAlertTitle>{bundle.getMessage('attention_users')}</StyledAlertTitle>
      {bundle.formatMessage('map_launch_wiki', {
        link: (children: string) => (
          <MuiLink href={wikiLink} underline="none" key={'map_launch_wiki'} target="_blank">
            {children}
          </MuiLink>
        ),
      })}
    </StyledAlert>
  );
}

export default withBundle('components.AlertBanner')(AlertBanner);
