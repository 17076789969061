import React, { SyntheticEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import MuiLink from '@mui/material/Link';
import MuiBox from '@mui/material/Box';
import MuiToolbar from '@mui/material/Toolbar';
import MuiIconButton from '@mui/material/IconButton';
import MuiTypography from '@mui/material/Typography';
import MuiMenu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import { NAV_LINKS, PROFILE_SETTINGS, UserVisibility, SnowUrlType } from '../../constants/urlConstants';
import { CHANGE_PASSWORD, LOGOUT, LinkType, MANAGE_TEAM, MY_PROFILE } from '../../constants/linkConstants';
import { ImpersonationIntentType, useImpersonation, useUserContext } from '../../context/UserProvider';
import { useUserDetailsQuery } from '../../apis/UserDetailsAPI';
import logo from '../../images/logo.png';
import ProfileAvatar from './ProfileAvatar';
import { RoleType, UserMetadata } from '../../data/UserMetadata';
import { getProfileItemUrl } from '../../utils/ProfileItemUtils';
import getTPSPortalUrl from '../../utils/redirectionUtils';
import ChangePassword from '../ChangePassword/ChangePassword';
import { styled, useTheme } from '@mui/material/styles';
import './TopBarNav.css';
import { BREAKPOINT, NAV_HEIGHT_PX, NAV_HEIGHT_SM_PX } from '../../constants/constants';

const NavBarContainer = styled('div')(({ theme }) => ({
  height: NAV_HEIGHT_PX,
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    height: NAV_HEIGHT_SM_PX,
  },
}));

function isProfileSettingApplicable(
  setting: UserVisibility,
  user: UserMetadata,
  impersonationIntent: ImpersonationIntentType,
  tpsRecordSystemId?: string,
): boolean {
  if (setting.role && setting.role !== user.role) return false;
  if (setting.link.label === CHANGE_PASSWORD.label && impersonationIntent === 'disabled') return true;
  if (setting.link.label === MY_PROFILE.label && !!tpsRecordSystemId) return true;
  return setting.link.label === MANAGE_TEAM.label && user.primaryContact;
}

function TopBarNav(props: WithBundleProps) {
  const user = useUserContext();
  const userRole = user.role;
  const { impersonationIntent, impersonationTarget, setImpersonationIntent } = useImpersonation();
  const userDetailsQuery = useUserDetailsQuery(user.authToken, impersonationTarget);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const theme = useTheme();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const ProfileOption = ({
    label: optionalLabel,
    link,
    onClick,
  }: {
    label?: string;
    link?: LinkType;
    onClick?: (e: SyntheticEvent) => void;
  }) => {
    const label = optionalLabel ?? link!.label!;
    return (
      <MuiLink
        {...{
          component: Link,
          // isProfileSettingApplicable only renders MY_PROFILE when tpsRecordSystemId is present
          to: link
            ? getProfileItemUrl({
                tpsRecordSystemId: userDetailsQuery.data?.tpsRecordSystemId,
                link,
                userRole,
                impersonationIntent,
              })
            : '/',
        }}
        style={{ color: 'inherit' }}
        underline="none"
        key={label}
      >
        <MuiMenuItem
          sx={{
            height: '45px',
            paddingLeft: '62px',
            fontSize: '16px',
            borderTop: `0.5px solid ${theme.palette.info.main}`,
            backgroundColor: theme.palette.primary.primary,
            color: theme.palette.text.disabled,
            borderRadius: 0,
            '&:hover': {
              color: theme.palette.primary.dark,
            },
          }}
          key={label}
          className="topbar-dropdown-menu-item"
          onClick={(e) => {
            onClick && onClick(e);
            handleCloseUserMenu();
          }}
        >
          {props.bundle.getMessage(label)}
          {label === CHANGE_PASSWORD.label && <ChangePassword open={openModal} openModal={setOpenModal} />}
        </MuiMenuItem>
      </MuiLink>
    );
  };

  const DualUserOption = () => {
    if (user.hasDualRole) {
      return (
        <ProfileOption
          label="switch_to_vendor"
          onClick={(e: SyntheticEvent) => {
            e.preventDefault();
            setImpersonationIntent('dualRole');
          }}
        />
      );
    }
    if (impersonationIntent === 'dualRole') {
      return (
        <ProfileOption
          label="switch_to_requester"
          onClick={(e: SyntheticEvent) => {
            e.preventDefault();
            setImpersonationIntent('disabled');
          }}
        />
      );
    }

    return <div></div>;
  };

  return (
    <NavBarContainer className="top-navigation-bar-background">
      <MuiToolbar disableGutters>
        {/* Logo and Title */}
        <MuiLink
          {...{
            component: Link,
            to:
              userRole === RoleType.VENDOR
                ? 'https://beta-pdx.assessments.security.amazon.dev'
                : getTPSPortalUrl({ linkType: SnowUrlType.HOME, userRole, impersonationIntent }),
          }}
          underline="none"
          color="inherit"
        >
          <img className="logo" src={logo} />
        </MuiLink>
        <MuiTypography
          className="header-icon-text-container"
          noWrap
          sx={{
            letterSpacing: 'normal',
            color: theme.palette.text.disabled,
            textDecoration: 'none',
            borderLeft: 1,
          }}
        >
          <span className="header-icon-text">{props.bundle.getMessage('header')}</span>
        </MuiTypography>

        {/* Topbar navigation */}
        <MuiBox
          sx={{
            flexGrow: 2,
            display: 'flex',
            justifyContent: 'right',
            '@media screen and (max-width: 1366px)': {
              position: 'relative',
              top: '-4px',
            },
          }}
        >
          {NAV_LINKS.filter((navLink) => !navLink.role || navLink.role === userRole).map((navLink, index) => (
            <MuiTypography key={index}>
              <MuiLink
                {...{
                  component: Link,
                  to: navLink.link.href,
                }}
                underline="none"
                color="inherit"
                target="_blank"
                sx={{
                  color: theme.palette.info.main,
                  textDecoration: 'none',
                  marginRight: '10px',
                  '&:hover': {
                    color: theme.palette.primary.dark,
                  },
                  '@media screen and (max-width: 1376px)': {
                    fontSize: '12px',
                  },
                }}
              >
                {props.bundle.getMessage(navLink.link.label)}
              </MuiLink>
            </MuiTypography>
          ))}
        </MuiBox>

        <MuiBox sx={{ flexGrow: 0 }}>
          <MuiIconButton
            id="button-app-bar"
            aria-controls={anchorElUser ? 'menu-app-bar' : undefined}
            aria-haspopup="true"
            aria-expanded={anchorElUser ? 'true' : undefined}
            onClick={handleOpenUserMenu}
            sx={{ p: 0 }}
            className="user-menu-dropdown"
          >
            <ProfileAvatar />
          </MuiIconButton>
          <MuiMenu
            MenuListProps={{
              disablePadding: true,
            }}
            PaperProps={{ style: { width: 243, marginLeft: '8px', borderRadius: 0, marginTop: '7px' } }}
            id="menu-app-bar"
            aria-labelledby="button-app-bar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {PROFILE_SETTINGS.filter((setting) =>
              isProfileSettingApplicable(setting, user, impersonationIntent, userDetailsQuery.data?.tpsRecordSystemId),
            ).map((setting) => {
              if (setting.link.label === CHANGE_PASSWORD.label)
                return <ProfileOption link={setting.link} onClick={() => setOpenModal(true)} />;
              return <ProfileOption link={setting.link} />;
            })}
            <DualUserOption />
            {impersonationIntent === 'disabled' && <ProfileOption link={LOGOUT} />}
          </MuiMenu>
        </MuiBox>
      </MuiToolbar>
    </NavBarContainer>
  );
}

export default withBundle('components.TopBarNav')(TopBarNav);
