var endpointMap = {
	en: {
		"components.AlertBanner": "translation-en.json",
		"components.Breadcrumbs": "translation-en.json",
		"components.ChangePassword": "translation-en.json",
		"components.ErrorRetry": "translation-en.json",
		"components.FilterBar": "translation-en.json",
		"components.NewIntakeCard": "translation-en.json",
		"components.PortalDescriptionAccordion": "translation-en.json",
		"components.SideBar": "translation-en.json",
		"components.TabNav": "translation-en.json",
		"components.TakeActionList": "translation-en.json",
		"components.ThirdPartyTieringAssessmentCard": "translation-en.json",
		"components.TodoList": "translation-en.json",
		"components.TopBarNav": "translation-en.json",
		"pages.CompleteAssessmentsPage": "translation-en.json",
		"pages.ConsultsPage": "translation-en.json",
		"pages.IssuesPage": "translation-en.json",
		"pages.LandingPage": "translation-en.json",
		"pages.LogoutPage": "translation-en.json",
		"pages.SurveysPage": "translation-en.json",
		"pages.TasksPage": "translation-en.json"
	},
	"en-US": {
		"components.AlertBanner": "translation-en.json",
		"components.Breadcrumbs": "translation-en.json",
		"components.ChangePassword": "translation-en.json",
		"components.ErrorRetry": "translation-en.json",
		"components.FilterBar": "translation-en.json",
		"components.NewIntakeCard": "translation-en.json",
		"components.PortalDescriptionAccordion": "translation-en.json",
		"components.SideBar": "translation-en.json",
		"components.TabNav": "translation-en.json",
		"components.TakeActionList": "translation-en.json",
		"components.ThirdPartyTieringAssessmentCard": "translation-en.json",
		"components.TodoList": "translation-en.json",
		"components.TopBarNav": "translation-en.json",
		"pages.CompleteAssessmentsPage": "translation-en.json",
		"pages.ConsultsPage": "translation-en.json",
		"pages.IssuesPage": "translation-en.json",
		"pages.LandingPage": "translation-en.json",
		"pages.LogoutPage": "translation-en.json",
		"pages.SurveysPage": "translation-en.json",
		"pages.TasksPage": "translation-en.json"
	},
	und: {
		"components.AlertBanner": "translation.json",
		"components.Breadcrumbs": "translation.json",
		"components.ChangePassword": "translation.json",
		"components.ErrorRetry": "translation.json",
		"components.FilterBar": "translation.json",
		"components.NewIntakeCard": "translation.json",
		"components.PortalDescriptionAccordion": "translation.json",
		"components.SideBar": "translation.json",
		"components.TabNav": "translation.json",
		"components.TakeActionList": "translation.json",
		"components.ThirdPartyTieringAssessmentCard": "translation.json",
		"components.TodoList": "translation.json",
		"components.TopBarNav": "translation.json",
		"components.VendorDetails": "translation.json",
		"pages.CompleteAssessmentsPage": "translation.json",
		"pages.ConsultsPage": "translation.json",
		"pages.IssuesPage": "translation.json",
		"pages.LandingPage": "translation.json",
		"pages.LogoutPage": "translation.json",
		"pages.SurveysPage": "translation.json",
		"pages.TasksPage": "translation.json"
	}
};
var arbManifest = {
	endpointMap: endpointMap
};

export { arbManifest as default, endpointMap };
