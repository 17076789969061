export enum RoleType {
  REQUESTER = 'requester',
  ASSESSOR = 'assessor',
  VENDOR = 'vendor',
  NONE = 'none',
}

export type UserMetadata = {
  name: string;
  email: string;
  authToken: string;
  role: RoleType;
  primaryContact: boolean;
  hasDualRole: boolean;
  dualRoleTarget: string;
  canImpersonate: boolean;
  hasImpersonated: boolean;
  impersonatedUser: string;
};
