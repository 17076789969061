import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { get } from './ApiGatewayInvoker';
import { SurveysPayload } from '../data/RecordMetadata';
import { retryConfig, cacheConfig, getCommonParams } from './ApiUtils';
import { useImpersonation } from '../context/UserProvider';

const SURVEYS_API_PATH = '/api/v1/surveys';

export const useGetSurveysByUserQuery = (): UseQueryResult<SurveysPayload> => {
  const { impersonationTarget } = useImpersonation();
  const commonParams = getCommonParams();

  return useQuery<SurveysPayload>({
    queryKey: ['getSurveysKey', impersonationTarget],
    queryFn: async () => {
      return await get<SurveysPayload>({
        apiPath: `${SURVEYS_API_PATH}`,
        name: 'Survey',
        user: impersonationTarget,
        emptyResponseConfig: {
          404: { surveys: [] },
        },
        ...commonParams,
      });
    },
    ...retryConfig,
    ...cacheConfig,
  });
};
