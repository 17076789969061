import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import MuiLink from '@mui/material/Link';
import MuiCircularProgress from '@mui/material/CircularProgress';
import MuiTypography from '@mui/material/Typography';
import MuiBox from '@mui/material/Box';
import { styled } from '@mui/material';
import { Assessment } from '../../data/RecordMetadata';
import FilterBar from '../../components/FilterBar/FilterBar';
import filterReducer from '../../components/FilterBar/FilterReducer';
import getTPSPortalUrl from '../../utils/redirectionUtils';
import { useGetAssessmentsByUserQuery } from '../../apis/AssessmentsAPI';
import { useImpersonation, useUserContext } from '../../context/UserProvider';
import ErrorRetry from '../../components/LoadingComponents/ErrorRetry';
import { outcomeStrToEnum, tierStrToNum } from '../../utils/enumUtils';
import { isVisible } from '../../utils/filterUtils';
import { compareDates } from '../../utils/dataUtils';
import { BREAKPOINT, FILTER_LABELS, PAGE_TITLE } from '../../constants/constants';
import { SnowUrlType } from '../../constants/urlConstants';
import './CompleteAssessments.css';

const StyledLink = styled(MuiLink)({
  width: '100%',
  textDecoration: 'none',
  color: 'black',
});

const Header = styled(MuiTypography)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  textAlign: 'left',
  color: theme.palette.text.disabled,
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '13px',
  },
}));

const CellText = styled(MuiTypography)(({ theme }) => ({
  fontSize: '13px',
  backgroundColor: 'inherit',
  textAlign: 'left',
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '11px',
  },
}));

const ClickableHeader = styled('div')`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

enum SortDirection {
  ASCENDING = 'ascending',
  DESCENDING = 'descending',
}

function sortAssessments(assessments: Assessment[], field: keyof Assessment, direction: SortDirection): Assessment[] {
  const sortedAssessments = [...assessments];
  sortedAssessments.sort((a, b) => {
    if (field === 'startDate' || field === 'endDate') {
      return direction === SortDirection.DESCENDING
        ? compareDates(b[field], a[field])
        : compareDates(a[field], b[field]);
    }

    if (field === 'tier') {
      const tierA = a.tier || Number.MAX_SAFE_INTEGER;
      const tierB = b.tier || Number.MAX_SAFE_INTEGER;
      return direction === SortDirection.DESCENDING ? tierB - tierA : tierA - tierB;
    }

    const valueA = (a[field] as string)?.toLowerCase() || '';
    const valueB = (b[field] as string)?.toLowerCase() || '';
    if (valueA < valueB) {
      return direction === SortDirection.ASCENDING ? -1 : 1;
    }
    if (valueA > valueB) {
      return direction === SortDirection.ASCENDING ? 1 : -1;
    }
    return 0;
  });
  return sortedAssessments;
}

interface SortArrowProps {
  direction: 'ascending' | 'descending' | null;
}

const SortArrow: React.FC<SortArrowProps> = ({ direction }) => {
  if (!direction) {
    return null;
  }
  return <span>{direction === SortDirection.DESCENDING ? ' ↓' : ' ↑'}</span>;
};

/**
 * Creating the table for the archive page with clickable links to the
 * archives themselves.
 *
 * Note: Had to use divs and aria roles because the classes <table> element
 * doesn't support making an entire row clickable.
 * @returns JSX
 */

function CompleteAssessments({ bundle }: WithBundleProps) {
  const [state, dispatch] = useReducer(filterReducer, { filtersLoading: true });
  const assessmentsQuery = useGetAssessmentsByUserQuery('COMPLETED');
  const { role: userRole } = useUserContext();
  const { impersonationIntent } = useImpersonation();
  const [sortField, setSortField] = useState<string>('endDate');
  const [sortDirection, setSortDirection] = useState(SortDirection.DESCENDING);

  useEffect(() => {
    if (assessmentsQuery.isSuccess) {
      dispatch({
        type: 'initialSetup',
        payload: {
          labels: [FILTER_LABELS.VENDOR, FILTER_LABELS.TIER, FILTER_LABELS.OUTCOME],
          bundle,
          componentData: assessmentsQuery.data!.assessments,
        },
      });
    } else {
      if (!state.filtersLoading) dispatch({ type: 'reset' });
    }
  }, [state.filtersLoading, assessmentsQuery.data]);

  const renderSortIndicator = (field: string) => {
    if (field === sortField) {
      return <SortArrow direction={sortDirection} />;
    }
    return null;
  };

  const handleSort = (field: string) => {
    if (field === sortField) {
      setSortDirection(sortDirection === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING);
    } else {
      setSortField(field);
      setSortDirection(SortDirection.ASCENDING);
    }
  };

  const sortedAssessments = useMemo(() => {
    let sorted = assessmentsQuery.isSuccess && assessmentsQuery.data ? assessmentsQuery.data.assessments : [];
    if (sortField && sortDirection) {
      sorted = sortAssessments(sorted, sortField as keyof Assessment, sortDirection);
    }
    return sorted;
  }, [assessmentsQuery.isSuccess, assessmentsQuery.data, sortField, sortDirection]);

  return (
    <MuiBox className="complete-assessment-page-container">
      {assessmentsQuery.isLoading && <MuiCircularProgress />}
      {assessmentsQuery.isError && <ErrorRetry query={assessmentsQuery} />}
      {assessmentsQuery.isSuccess && (
        <React.Fragment>
          {state.filtersLoading ? (
            <MuiCircularProgress />
          ) : (
            <FilterBar
              filterFields={state.filterFields!}
              filterLabels={state.labels!}
              dispatch={dispatch}
              title={PAGE_TITLE.COMPLETE_ASSESSMENTS_PAGE}
            />
          )}
          <MuiBox role="table" className="complete-assessment-page-table" style={{ marginTop: '30px' }}>
            <MuiBox role="row" className="complete-assessment-page-table-row complete-assessment-page-table-row-header">
              <ClickableHeader role="columnheader" onClick={() => handleSort('tpsRecordId')}>
                <Header>
                  {bundle.getMessage('tpta')}
                  {renderSortIndicator('tpsRecordId')}
                </Header>
              </ClickableHeader>
              <ClickableHeader role="columnheader" onClick={() => handleSort('vendor')}>
                <Header>
                  {bundle.getMessage('vendor_name')}
                  {renderSortIndicator('vendor')}
                </Header>
              </ClickableHeader>
              <ClickableHeader role="columnheader" onClick={() => handleSort('name')}>
                <Header>
                  {bundle.getMessage('project_name')}
                  {renderSortIndicator('name')}
                </Header>
              </ClickableHeader>
              <ClickableHeader role="columnheader" onClick={() => handleSort('tier')}>
                <Header>
                  {bundle.getMessage('tier')}
                  {renderSortIndicator('tier')}
                </Header>
              </ClickableHeader>
              <ClickableHeader role="columnheader" onClick={() => handleSort('startDate')}>
                <Header>
                  {bundle.getMessage('date_opened')}
                  {renderSortIndicator('startDate')}
                </Header>
              </ClickableHeader>
              <ClickableHeader role="columnheader" onClick={() => handleSort('endDate')}>
                <Header>
                  {bundle.getMessage('date_closed')}
                  {renderSortIndicator('endDate')}
                </Header>
              </ClickableHeader>
              <ClickableHeader role="columnheader" onClick={() => handleSort('assessmentOutcome')}>
                <Header>
                  {bundle.getMessage('outcome')}
                  {renderSortIndicator('assessmentOutcome')}
                </Header>
              </ClickableHeader>
            </MuiBox>
            {sortedAssessments.map((completeAssessment: Assessment) => {
              // TODO: Add in anything that can be hardcoded to the PUFF-J file (tier, severity, etc.)
              let visibility: boolean | Set<string> = true;
              if (!state.filtersLoading && state.filterFields!.labelToFilters.size > 0) {
                visibility = isVisible(
                  {
                    vendor: completeAssessment.vendor,
                    tier: bundle.formatMessage('tier_number', { tier: tierStrToNum(completeAssessment.tier) }),
                    outcome: bundle.formatMessage('outcome_status', {
                      outcome: outcomeStrToEnum(completeAssessment.assessmentOutcome),
                    }),
                    search: [
                      completeAssessment.name,
                      completeAssessment.tpsRecordId,
                      completeAssessment.startDate
                        ? bundle.formatMessage('date_cell', {
                            date: new Date(0).setUTCSeconds(Number(completeAssessment.startDate)),
                          })
                        : bundle.getMessage('start_date_missing'),
                      completeAssessment.endDate
                        ? bundle.formatMessage('date_cell', {
                            date: new Date(0).setUTCSeconds(Number(completeAssessment.endDate)),
                          })
                        : bundle.getMessage('end_date_missing'),
                    ],
                  },
                  state.filterFields!.labelToFilters,
                );
              }
              return visibility ? (
                <React.Fragment key={completeAssessment.tpsRecordId}>
                  <StyledLink
                    {...{
                      component: Link,
                      to: getTPSPortalUrl({
                        id: completeAssessment.tpsRecordSystemId,
                        linkType: SnowUrlType.ASSESSMENT,
                        userRole,
                        impersonationIntent,
                      }),
                    }}
                    key={completeAssessment.tpsRecordId}
                    role="row"
                    className="complete-assessment-page-table-row"
                  >
                    <MuiBox role="cell">
                      <CellText>{completeAssessment.tpsRecordId}</CellText>
                    </MuiBox>
                    <MuiBox role="cell">
                      <CellText>{completeAssessment.vendor}</CellText>
                    </MuiBox>
                    <MuiBox role="cell">
                      <CellText>{completeAssessment.name}</CellText>
                    </MuiBox>
                    <MuiBox role="cell">
                      <CellText>
                        {bundle.formatMessage('tier_number', { tier: tierStrToNum(completeAssessment.tier) })}
                      </CellText>
                    </MuiBox>
                    <MuiBox role="cell">
                      <CellText>
                        {completeAssessment.startDate
                          ? bundle.formatMessage('date_cell', {
                              date: new Date(0).setUTCSeconds(Number(completeAssessment.startDate)),
                            })
                          : bundle.getMessage('start_date_missing')}
                      </CellText>
                    </MuiBox>
                    <MuiBox role="cell">
                      <CellText>
                        {completeAssessment.endDate
                          ? bundle.formatMessage('date_cell', {
                              date: new Date(0).setUTCSeconds(Number(completeAssessment.endDate)),
                            })
                          : bundle.getMessage('end_date_missing')}
                      </CellText>
                    </MuiBox>
                    <MuiBox role="cell">
                      <CellText>
                        {bundle.formatMessage('outcome_status', {
                          outcome: outcomeStrToEnum(completeAssessment.assessmentOutcome),
                        })}
                      </CellText>
                    </MuiBox>
                  </StyledLink>
                </React.Fragment>
              ) : null;
            })}
          </MuiBox>
        </React.Fragment>
      )}
    </MuiBox>
  );
}

export default withBundle('pages.CompleteAssessmentsPage')(CompleteAssessments);
