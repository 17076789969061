import React, { CSSProperties } from 'react';
import './Button.css';

type ButtonType = 'dark' | 'light';

interface ButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  type?: ButtonType;
  style?: CSSProperties;
  children: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({ children, onClick, type = 'dark', style }) => {
  return (
    <button style={style} onClick={onClick} className={`${type}-button`}>
      {children}
    </button>
  );
};

export default Button;
