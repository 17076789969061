import { ReactElement, ReactNode, createContext, useContext, useMemo } from 'react';
import KatalLogger from '@amzn/katal-logger';
import MetricsPublisher from './MetricsPublisher';
import { getLogger } from './logger';
import { UserAuthData } from '../context/MetricsAndUserProvider';

type MetricsAndLoggerProviderType = { metricsPublisher: MetricsPublisher; logger: KatalLogger };
const MetricsPublisherAndLoggerContext = createContext<MetricsAndLoggerProviderType>(
  {} as MetricsAndLoggerProviderType, // this initial value won't be used
);
const useMetricsAndLoggerContext = () => useContext(MetricsPublisherAndLoggerContext);

function MetricsProvider({
  userAuthData,
  children,
}: {
  userAuthData: UserAuthData;
  children: ReactNode;
}): ReactElement {
  const { authToken } = userAuthData;

  const metricsPublisherAndLogger = useMemo<MetricsAndLoggerProviderType>(
    () => ({
      metricsPublisher: new MetricsPublisher(authToken),
      logger: getLogger(authToken),
    }),
    [authToken],
  );

  return (
    <MetricsPublisherAndLoggerContext.Provider value={metricsPublisherAndLogger}>
      {children}
    </MetricsPublisherAndLoggerContext.Provider>
  );
}

export { MetricsProvider, useMetricsAndLoggerContext };
