import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import PantherI18nWrapper from './components/PantherI18nWrapper';
import AppWrapper from './components/AppWrapper';
import PortalRouter from './components/Router/PortalRouter';
import { Amplify } from 'aws-amplify';
import { MetricsAndUserProvider } from './context/MetricsAndUserProvider';
import getAmplifyConfiguration from './utils/AmplifyConfigUtils';
import './i18n';
import './logging/logger';
import 'src/styles.css';
import { MetricsProvider } from './logging/MetricsProvider';

const queryClient = new QueryClient();

//amplify underlying uses Axios to make the API requests for Cognito endpoints to collect data
//TODO we can use AWS AppSync to get the configuraion at run time
//https://docs.amplify.aws/lib/restapi/getting-started/q/platform/js/#aws-regional-endpoints
Amplify.configure(getAmplifyConfiguration('beta'));

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <QueryClientProvider client={queryClient}>
    <PantherI18nWrapper>
      <MetricsAndUserProvider>
        <AppWrapper>
          <PortalRouter />
        </AppWrapper>
      </MetricsAndUserProvider>
    </PantherI18nWrapper>
  </QueryClientProvider>,
);
