import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import { styled } from '@mui/material/styles';
import MuiBox from '@mui/material/Box';
import MuiCollapse from '@mui/material/Collapse';
import MuiCircularProgress from '@mui/material/CircularProgress';
import MuiLink from '@mui/material/Link';
import MuiTypography from '@mui/material/Typography';
import AccordionButton from '../../components/ReusableComponents/AccordionButton/AccordionButton';
import Assessment, { Survey, SurveyCategory, State } from '../../data/RecordMetadata';
import { SECONDARY_BLUE, RED, HOVER_EFFECT, BREAKPOINT, PAGE_TITLE } from '../../constants/constants';
import ErrorRetry from '../../components/LoadingComponents/ErrorRetry';
import { useGetSurveysByUserQuery } from '../../apis/SurveysAPI';
import { useGetAssessmentsByUserQuery } from '../../apis/AssessmentsAPI';
import { MessageBundle } from '@amzn/arb-tools';
import getTPSPortalUrl from '../../utils/redirectionUtils';
import { useImpersonation, useUserContext } from '../../context/UserProvider';
import { isSearchApplied, isVisible } from '../../utils/filterUtils';
import filterReducer, { FilterFields, FilterLabel } from '../../components/FilterBar/FilterReducer';
import FilterBar from '../../components/FilterBar/FilterBar';
import { SnowUrlType } from '../../constants/urlConstants';
import { isClosedCompleteNa, isSurveyActionable } from '../../utils/dataUtils';
import './SurveysPage.css';

const StyledLink = styled(MuiLink)({
  width: '100%',
  textDecoration: 'none',
  color: 'black',
});

const SurveyTypeDescription = styled(MuiTypography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '18px',
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '15px',
  },
}));

const TableHeaderText = styled(MuiTypography)(({ theme }) => ({
  padding: '6px',
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '13px',
    padding: '4px',
  },
}));

const CellText = styled(MuiTypography)(({ theme }) => ({
  fontSize: '13px',
  padding: '6px',
  [theme.breakpoints.down(BREAKPOINT.MAPMinimumResolution)]: {
    fontSize: '11px',
    padding: '4px',
  },
}));

const CollapseContainer = styled(MuiCollapse)({
  paddingTop: '20px',
});

interface AssessmentSurvey extends Survey {
  vendor?: string;
  tier?: number;
}

interface SurveyTableProps extends WithBundleProps {
  surveys: Survey[] | AssessmentSurvey[];
  category: SurveyCategory;
  filterFields?: FilterFields;
  isAssessmentsLoaded?: boolean;
}

function getAssessmentInfo(isAssessmentsLoaded: boolean | undefined, survey: Survey, bundle: MessageBundle) {
  if (isAssessmentsLoaded === undefined) {
    return <></>;
  }
  if (!isAssessmentsLoaded) {
    return (
      <>
        <span className="survey-table-record-cell">
          {survey.tpsRecordId} - <MuiCircularProgress className="survey-table-cell-progress" />
        </span>
      </>
    );
  }
  const assessmentSurvey = survey as AssessmentSurvey;
  return bundle.formatMessage('survey_name_info', {
    vendor: assessmentSurvey.vendor,
    number: survey.tpsRecordId,
    tier: assessmentSurvey.tier,
  });
}

function SurveyTable({ bundle, ...props }: SurveyTableProps): React.ReactElement {
  const { role: userRole } = useUserContext();
  const { impersonationIntent } = useImpersonation();
  const [expanded, setExpanded] = React.useState(false);
  const handleExpand = () => setExpanded(!expanded);

  const filteredSurveys: Survey[] = useMemo(
    () =>
      props.surveys.filter((survey: Survey) => {
        const labelToFilters = props.filterFields?.labelToFilters;
        if ((labelToFilters?.size ?? 0) === 0) return true;
        const filters: Partial<{ [K in FilterLabel]: string | string[] }> = {
          search: [
            survey.tpsRecordId,
            survey.name,
            bundle.formatMessage('date_cell', { date: new Date(0).setUTCSeconds(Number(survey.dueDate)) }),
            `${survey.progressPercentage}`,
          ],
        };
        if (props.isAssessmentsLoaded && 'vendor' in survey) {
          const assessmentSurvey = survey as AssessmentSurvey;
          if (assessmentSurvey.vendor) filters.vendor = assessmentSurvey.vendor;
          if (assessmentSurvey.tier)
            filters.tier = bundle.formatMessage('tier_number', { tier: assessmentSurvey.tier });
        }
        return isVisible(filters, labelToFilters!);
      }),
    [props.filterFields, props.surveys],
  );

  useEffect(() => {
    if (isSearchApplied(props.filterFields?.labelToFilters) && filteredSurveys.length > 0) setExpanded(true);
    else setExpanded(false);
  }, [filteredSurveys]);

  return (
    <React.Fragment>
      <MuiBox className={`${props.category.toLowerCase()}-table-container`}>
        <AccordionButton expanded={expanded} onClick={handleExpand} hasarrow>
          <SurveyTypeDescription>
            {props.category === SurveyCategory.SURVEY_ASSESSMENT
              ? bundle.getMessage('assessment_surveys')
              : bundle.getMessage('general_surveys')}
          </SurveyTypeDescription>
        </AccordionButton>
        <CollapseContainer in={expanded} timeout="auto" unmountOnExit>
          <MuiBox role="table" className="surveys-table-container">
            <MuiBox role="rowheader" className="survey-table-row survey-header-row">
              <MuiBox role="columnheader">
                <TableHeaderText>{bundle.getMessage('record_header')}</TableHeaderText>
              </MuiBox>
              <MuiBox role="columnheader">
                <TableHeaderText>{bundle.getMessage('due_date_header')}</TableHeaderText>
              </MuiBox>
              <MuiBox role="columnheader">
                <TableHeaderText>{bundle.getMessage('percentage_completed')}</TableHeaderText>
              </MuiBox>
            </MuiBox>
            {filteredSurveys.map((survey: Survey) => (
              <StyledLink
                key={survey.tpsRecordSystemId}
                className="survey-link"
                {...{
                  component: Link,
                  to: getTPSPortalUrl({
                    id: survey.tpsRecordSystemId,
                    linkType: SnowUrlType.SURVEY,
                    userRole,
                    impersonationIntent,
                  }),
                }}
              >
                <MuiBox role="row" className="survey-table-row">
                  <MuiBox role="cell">
                    <MuiBox sx={{ padding: '6px' }}>
                      <MuiTypography
                        sx={{
                          color: isSurveyActionable(survey) ? RED : SECONDARY_BLUE,
                          '&:hover': HOVER_EFFECT,
                          '@media screen and (max-width: 1376px)': {
                            fontSize: '11px',
                          },
                        }}
                      >
                        {survey.name ? survey.name : bundle.getMessage('no_survey_name')}
                      </MuiTypography>
                      <MuiTypography
                        sx={{
                          '@media screen and (max-width: 1376px)': {
                            fontSize: '11px',
                          },
                        }}
                      >
                        {getAssessmentInfo(props.isAssessmentsLoaded, survey, bundle)}
                      </MuiTypography>
                    </MuiBox>
                  </MuiBox>
                  <MuiBox role="cell">
                    <CellText>
                      {bundle.formatMessage('date_cell', {
                        date: new Date(0).setUTCSeconds(Number(survey.dueDate)),
                      })}
                    </CellText>
                  </MuiBox>
                  <MuiBox role="cell">
                    <CellText>{survey.progressPercentage}</CellText>
                  </MuiBox>
                </MuiBox>
              </StyledLink>
            ))}
          </MuiBox>
        </CollapseContainer>
      </MuiBox>
    </React.Fragment>
  );
}

function SurveysPage({ bundle }: WithBundleProps): React.ReactElement {
  const [state, dispatch] = React.useReducer(filterReducer, { filtersLoading: true });
  const { impersonationTarget } = useImpersonation();
  const surveysQuery = useGetSurveysByUserQuery();
  const assessmentsQuery = useGetAssessmentsByUserQuery();
  const assessmentSurveys = useRef<AssessmentSurvey[] | null>(null);
  const generalSurveys = useRef<Survey[] | null>(null);
  const [surveyUser, setSurveyUser] = useState<string>('thisInitValueMustDifferFromImpersonationTarget');

  //useEffect for initial state to contain filtermap, label sets at component level
  useEffect(() => {
    if (!surveysQuery.isSuccess) {
      if (!state.filtersLoading) {
        dispatch({ type: 'reset' });
      }
      return;
    }
    if (surveysQuery.isSuccess && surveyUser !== impersonationTarget) {
      const openSurveys = surveysQuery.data?.surveys.filter((survey) => !isClosedCompleteNa(survey.state));

      assessmentSurveys.current =
        openSurveys?.filter((survey) => survey.category.toLowerCase() === SurveyCategory.SURVEY_ASSESSMENT) ?? [];
      generalSurveys.current =
        openSurveys?.filter((survey) => survey.category.toLowerCase() === SurveyCategory.SURVEY_GENERAL) ?? [];

      dispatch({
        type: 'initialSetup',
        payload: {
          bundle,
          componentData: assessmentSurveys.current!.concat(generalSurveys.current!),
        },
      });
      setSurveyUser(impersonationTarget);
    }
    if (assessmentsQuery.isSuccess && surveysQuery.isSuccess) {
      const tptaNumberToAssessment = new Map<string, Assessment>();
      assessmentsQuery.data.assessments.forEach((assessment) => {
        tptaNumberToAssessment.set(assessment.tpsRecordId, assessment);
      });

      assessmentSurveys.current!.forEach((survey) => {
        survey.vendor = tptaNumberToAssessment.get(survey.tpsRecordId)?.vendor;
        survey.tier = tptaNumberToAssessment.get(survey.tpsRecordId)?.tier;
      });

      dispatch({
        type: 'initialSetup',
        payload: {
          bundle,
          componentData: assessmentSurveys.current!.concat(generalSurveys.current!),
        },
      });
    }
  }, [
    assessmentsQuery.data,
    assessmentsQuery.isSuccess,
    surveysQuery.data,
    surveysQuery.isSuccess,
    impersonationTarget,
    state.filtersLoading,
  ]);

  if (surveysQuery.isError) return <ErrorRetry query={surveysQuery} />;
  if (assessmentsQuery.isError) return <ErrorRetry query={assessmentsQuery} />;
  if (surveysQuery.isLoading) return <MuiCircularProgress />;

  return (
    <div className="surveys-page-container">
      {surveyUser === impersonationTarget && (
        <React.Fragment>
          {state.filtersLoading ? (
            <MuiCircularProgress />
          ) : (
            <FilterBar
              filterFields={state.filterFields!}
              filterLabels={state.labels!}
              dispatch={dispatch}
              title={PAGE_TITLE.SURVEY_PAGE}
            />
          )}
          <SurveyTable
            surveys={assessmentSurveys.current!}
            bundle={bundle}
            category={SurveyCategory.SURVEY_ASSESSMENT}
            filterFields={state.filterFields}
            isAssessmentsLoaded={assessmentsQuery.isSuccess}
          />
          <SurveyTable
            surveys={generalSurveys.current!}
            bundle={bundle}
            category={SurveyCategory.SURVEY_GENERAL}
            filterFields={state.filterFields}
          />
        </React.Fragment>
      )}
    </div>
  );
}

export default withBundle('pages.SurveysPage')(SurveysPage);
